import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFte from '@app/store/reducers/fte.reducer';
import { Fte } from '@pageProjects/models/fte';
import * as fromStore from '@app/store/selectors/index';
import { selectSortedRoles } from '@app/store/selectors';

export const selectFteState = createFeatureSelector<fromFte.FteState>('ftes');

export const selectAllRawFtes = createSelector(selectFteState, fromFte.selectAll);

export const selectAllFtes = createSelector(selectAllRawFtes, selectSortedRoles, (ftes, roles) =>
  ftes.filter((fte) => roles.findIndex((r) => r.id === fte.role) > -1)
);

export const selectUsedRoles = createSelector(selectAllFtes, (ftes) => {
  const usedRoles: { [key: string]: string[] } = {};
  ftes
    .filter((fte) => fte.fte !== null)
    .filter((fte) => fte.fte > 0)
    .map((fte) => ({ role: fte.role, level: fte.level }))
    .forEach((fte) => {
      if (!usedRoles.hasOwnProperty(fte.role)) {
        usedRoles[fte.role] = [];
      }

      if (usedRoles[fte.role].find((level) => level === fte.level) === undefined) {
        usedRoles[fte.role].push(fte.level);
      }
    });
  return usedRoles;
});

export const selectFtesForPhase = (phaseId: string) =>
  createSelector(selectAllFtes, (ftes) => ftes.filter((fte) => fte.phase === phaseId));

export const selectFtesByPhase = createSelector(selectAllFtes, (ftes) => {
  const map: Map<string, Fte[]> = new Map<string, Fte[]>();
  ftes.forEach((fte) => {
    if (!map.has(fte.phase)) {
      map.set(fte.phase, [fte]);
    } else {
      map.get(fte.phase).push(fte);
    }
  });
  return map;
});

export const selectFteSumForPhase = (phaseId: string) =>
  createSelector(
    selectFtesForPhase(phaseId),
    fromStore.selectConfigForPhase(phaseId),
    (ftes, phaseConfigs) =>
      ftes
        .filter((fte) => phaseConfigs[fte.role].isUniform === (fte.area === null))
        .map((fte) => fte.fte)
        .reduce((a, b) => a + b, 0)
  );

export const selectFteSumForArea = (phaseId: string, area: string = null) =>
  createSelector(
    selectFtesForPhase(phaseId),
    fromStore.selectConfigForPhase(phaseId),
    (ftes, phaseConfigs) =>
      ftes
        .filter((fte) => fte.area === area)
        .filter((fte) => phaseConfigs[fte.role].isUniform === (area === null))
        .map((fte) => fte.fte)
        .reduce((a, b) => a + b, 0)
  );

export const selectFteSumForLevel = (phaseId: string, role: string, level: string) =>
  createSelector(
    selectFtesForPhase(phaseId),
    fromStore.selectIsUniformForRole(phaseId, role),
    (ftes, isUniform) =>
      ftes
        .filter((fte) => fte.role === role && fte.level === level)
        .filter((fte) => (fte.area === null) === isUniform)
        .map((fte) => fte.fte)
        .reduce((a, b) => a + b, 0)
  );

export const selectFte = (phaseId: string, role: string, level: string, area: string = null) =>
  createSelector(selectFtesForPhase(phaseId), fromStore.selectProject, (ftes, project) => {
    const fte: Fte = ftes.find((f) => f.role === role && f.level === level && f.area === area);

    if (fte === undefined) {
      const empty: Fte = {
        project: project.id,
        phase: phaseId,
        role,
        level,
        area,
        fte: null
      };
      return empty;
    }

    return fte;
  });

export const selectFtesForCopy = (fromPhaseId: string, toPhaseId: string) =>
  createSelector(
    selectFtesForPhase(fromPhaseId),
    selectFtesForPhase(toPhaseId),
    (fromFtes, toFtes) => ({
      fromFtes,
      toFtes: toFtes.map((fte) => ({ ...fte, phase: fromPhaseId }))
    })
  );
