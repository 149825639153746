<nb-layout>
  <nb-layout-header #layoutHeader class="sticky">
    <div class="header back-button-bg">
      <button nbButton ghost size="giant" (click)="back()" *ngIf="isRootRoute$ | async">
        <nb-icon icon="arrow-back-outline"></nb-icon>
      </button>
    </div>
    <fp-sidebar-container></fp-sidebar-container>
    <nb-sidebar-footer *ngIf="user$ | async as user">
      <nb-user shape="rectangle" [name]="user.name" [title]="role$ | async"></nb-user>
      <button nbButton ghost data-ref="menu" aria-label="Menu" (click)="logout()">
        <nb-icon icon="log-out-outline"></nb-icon>
      </button>
    </nb-sidebar-footer>
  </nb-layout-header>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
