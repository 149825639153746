import { Estimate } from '@pageProjects/models/estimate';
import { ClipboardActions, ClipboardActionTypes } from '@app/store/actions/clipboard.actions';

export enum ClipboardContentType {
  SINGLE_VALUE,
  TECHNICAL_AREA,
  TASK
}

export interface ClipboardState {
  clipboardContentType: ClipboardContentType;
  content: number | Estimate | Estimate[];
}

export const clipboardInitialState: ClipboardState = {
  clipboardContentType: null,
  content: null
};

export const clipboardReducer = (
  clipboardState: ClipboardState = clipboardInitialState,
  actions: ClipboardActions
): ClipboardState => {
  switch (actions.type) {
    case ClipboardActionTypes.TASK_COPIED:
      return {
        clipboardContentType: ClipboardContentType.TASK,
        content: actions.value
      };
    case ClipboardActionTypes.TECHNICAL_AREA_COPIED:
      return {
        clipboardContentType: ClipboardContentType.TECHNICAL_AREA,
        content: actions.value
      };
    case ClipboardActionTypes.SINGLE_VALUE_COPIED:
      return {
        clipboardContentType: ClipboardContentType.SINGLE_VALUE,
        content: actions.value
      };
    default:
      return clipboardState;
  }
};
