import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userActions from '@app/store/actions/user.actions';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from '@core/services/user.service';
import { handleDocumentChanges } from '@shared/operators';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserService) {}

  created$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.UserActionTypes.CREATED),
      map((action: userActions.Created) => action),
      switchMap((data) => this.userService.add(data.payload)),
      map(() => new userActions.CreatedSuccess())
    )
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.UserActionTypes.LOADED),
      switchMap(() => this.userService.getAll().pipe(handleDocumentChanges('[USER API]')))
    )
  );
}
