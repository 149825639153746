import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import * as riskActions from '@app/store/actions/risk.actions';
import { of } from 'rxjs';
import { RiskService } from '@core/services/risk.service';
import { Store } from '@ngrx/store';
import { ProjectModuleState } from '@app/store/reducers';
import * as fromStore from '@app/store/selectors';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class RiskEffects {
  constructor(
    private actions$: Actions,
    private riskService: RiskService,
    private store: Store<ProjectModuleState>
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(riskActions.RiskActionTypes.LOAD),
      map((action: riskActions.Load) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([, project]) =>
        this.riskService.getAll(project.id).pipe(handleDocumentChanges('[RISK] API'))
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(riskActions.RiskActionTypes.SAVE),
      map((action: riskActions.Save) => action),
      withLatestFrom(
        this.store.select(fromStore.selectProject),
        this.store.select(fromStore.selectNextRiskOrder)
      ),
      switchMap(([data, project, order]) =>
        this.riskService.save({ ...data.payload, project: project.id, order }).pipe(
          map(() => new riskActions.SaveSuccess()),
          catchError(() => of(new riskActions.SaveFail()))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(riskActions.RiskActionTypes.UPDATE),
      map((action: riskActions.Update) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([data, project]) =>
        this.riskService.update({ ...data.payload, project: project.id }).pipe(
          map(() => new riskActions.UpdateSuccess()),
          catchError(() => of(new riskActions.UpdateFail()))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(riskActions.RiskActionTypes.DELETE),
      map((action: riskActions.Delete) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([data, project]) =>
        this.riskService.delete(project.id, data.id).pipe(
          map(() => new riskActions.DeleteSuccess()),
          catchError(() => of(new riskActions.DeleteFail()))
        )
      )
    )
  );
}
