import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Project } from '@pageProjects/models/project';
import { ProjectActions, ProjectActionTypes } from '@app/store/actions/project.actions';
import { ProjectUtils as PU } from '@pageProjects/tools/utils';

export const projectAdapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export interface ProjectState extends EntityState<Project> {
  currentProjectId: string | null;
  areProjectsLoaded: boolean;
}

export const projectInitialState: ProjectState = projectAdapter.getInitialState({
  currentProjectId: null,
  areProjectsLoaded: false
});

export const projectsReducer = (
  projectState: ProjectState = projectInitialState,
  action: ProjectActions
): ProjectState => {
  // Enable this to see better Redux logging
  switch (action.type) {
    case ProjectActionTypes.ADDED: {
      const projects = action.payload.map((project) => PU.convertDates<Project>(project));
      return projectAdapter.addMany(projects, projectState);
    }
    case ProjectActionTypes.MODIFIED:
      return projectAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: PU.convertDates<Project>(a)
        })),
        projectState
      );
    case ProjectActionTypes.CLEAR:
      return {
        ...projectAdapter.removeAll(projectState),
        areProjectsLoaded: false
      };
    case ProjectActionTypes.REMOVED:
      return projectAdapter.removeMany(
        action.payload.map((a) => a.id),
        projectState
      );
    case ProjectActionTypes.SET_PROJECT:
      return {
        ...projectState,
        currentProjectId: action.projectId
      };
    case ProjectActionTypes.CLEAR_CURRENT_PROJECT:
      return {
        ...projectState,
        currentProjectId: null
      };
    case ProjectActionTypes.SET_LOAD_ALL_STATE:
      return action.state === projectState.areProjectsLoaded
        ? projectState
        : {
            ...projectState,
            areProjectsLoaded: action.state
          };
    default:
      return projectState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = projectAdapter.getSelectors();
