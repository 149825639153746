import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LeaderChecklist } from '@pageProjects/models/leader-checklist';
import {
  LeaderChecklistActions,
  LeaderChecklistActionTypes
} from '@app/store/actions/leader-checklist.actions';

export const leaderChecklistAdapter: EntityAdapter<LeaderChecklist> =
  createEntityAdapter<LeaderChecklist>();

export interface LeaderChecklistState extends EntityState<LeaderChecklist> {}

export const leaderChecklistInitialState: LeaderChecklistState =
  leaderChecklistAdapter.getInitialState({});

export const leaderChecklistReducer = (
  state: LeaderChecklistState = leaderChecklistInitialState,
  action: LeaderChecklistActions
): LeaderChecklistState => {
  switch (action.type) {
    case LeaderChecklistActionTypes.ADDED:
      return leaderChecklistAdapter.addMany(action.payload, state);
    case LeaderChecklistActionTypes.MODIFIED:
      return leaderChecklistAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        state
      );
    case LeaderChecklistActionTypes.CLEAR:
      return leaderChecklistAdapter.removeAll(state);
    default:
      return state;
  }
};

export const { selectAll } = leaderChecklistAdapter.getSelectors();
