import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserInterface from '@app/store/reducers/user-interface.reducer';
import { selectAllTasks } from '@app/store';
import { SelectedTask } from '@pageProjects/models/selected-task';

export const selectUserInterfaceState =
  createFeatureSelector<fromUserInterface.UserInterfaceState>('userInterface');

export const selectMenuItems = createSelector(
  selectUserInterfaceState,
  (uiState) => uiState.menuItems
);

export const selectSelectedTasks = createSelector(
  selectUserInterfaceState,
  selectAllTasks,
  (uiState, tasks) => {
    const selectedTasks = uiState.selectedTasks.map((selectedTask) => {
      const newTask: SelectedTask = {
        task: selectedTask.task,
        phase: selectedTask.phase,
        variants: tasks.find((task) => task.id === selectedTask.task)?.variants
      };

      return newTask;
    });
    return selectedTasks;
  }
);

export const selectSelectedTasksInBacklog = createSelector(
  selectUserInterfaceState,
  selectAllTasks,
  (uiState, tasks) =>
    uiState.selectedTasksInBacklog.map(
      (selectedTask) =>
        ({
          task: selectedTask.task,
          phase: selectedTask.phase,
          variants: tasks.find((task) => task.id === selectedTask.task)?.variants
        } as SelectedTask)
    )
);

export const selectSelectedPhases = createSelector(
  selectUserInterfaceState,
  (uiState) => uiState.selectedPhases
);

export const selectSelectedTasksByPhaseId = (phaseId: string) =>
  createSelector(selectSelectedTasks, (tasks) => tasks.filter((task) => task.phase === phaseId));
