import { Action } from '@ngrx/store';
import { ClipboardContentType } from '@app/store/reducers/clipboard.reducer';
import { Estimate } from '@pageProjects/models/estimate';

export enum ClipboardActionTypes {
  SINGLE_VALUE_COPIED = '[CLIPBOARD] SINGLE VALUE COPIED',
  TECHNICAL_AREA_COPIED = '[CLIPBOARD] TECHNICAL AREA COPIED',
  TASK_COPIED = '[CLIPBOARD] TASK COPIED',
  SENT_TO_CLIPBOARD = '[CLIPBOARD] SENT TO CLIPBOARD'
}

export class SingleValueCopied implements Action {
  readonly type = ClipboardActionTypes.SINGLE_VALUE_COPIED;

  constructor(public value: number) {}
}

export class TechnicalAreaCopied implements Action {
  readonly type = ClipboardActionTypes.TECHNICAL_AREA_COPIED;

  constructor(public value: Estimate) {}
}

export class TaskCopied implements Action {
  readonly type = ClipboardActionTypes.TASK_COPIED;

  constructor(public value: Estimate[]) {}
}

export class SentToClipboard implements Action {
  readonly type = ClipboardActionTypes.SENT_TO_CLIPBOARD;

  constructor(
    public contentType: ClipboardContentType,
    public value: number | Estimate | Estimate[]
  ) {}
}

export type ClipboardActions =
  | SingleValueCopied
  | TechnicalAreaCopied
  | TaskCopied
  | SentToClipboard;
