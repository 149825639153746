export type LeaderChecklist = {
  id?: string;
  project?: string;
  estimationProcessValidity: LeaderChecklistValue<LeaderChecklistAnswer>;
  estimationEvaluation: LeaderChecklistValue<LeaderChecklistAnswer>;
  estimationElementsAnalysisMeetingOverhead: LeaderChecklistValue<LeaderChecklistAnswer>;
  estimationElementsAnalysisMeetingOverheadComment: LeaderChecklistValue<string>;
  estimationElementsAnalysisSprintLength: LeaderChecklistValue<LeaderChecklistAnswer>;
  estimationElementsAnalysisSprintLengthComment: LeaderChecklistValue<string>;
  estimationElementsAnalysisRoles: LeaderChecklistValue<LeaderChecklistAnswer>;
  teamCompositionAnalysis: LeaderChecklistValue<LeaderChecklistAnswer>;
  teamCompositionAnalysisCommentQA: LeaderChecklistValue<string>;
  teamCompositionAnalysisCommentLeader: LeaderChecklistValue<string>;
  teamCompositionAnalysisReason: LeaderChecklistValue<string>;
  risksAnalysed: LeaderChecklistValue<LeaderChecklistAnswer>;
  risksCooperation: LeaderChecklistValue<LeaderChecklistAnswer>;
  risksDescription: LeaderChecklistValue<string>;
  risksUnclearRequirements: LeaderChecklistValue<LeaderChecklistAnswer>;
  risksUnclearRequirementsAffection: LeaderChecklistValue<string>;
  risksNonFunctionalRequirements: LeaderChecklistValue<LeaderChecklistAnswer>;
  technicalRisksCooperation: LeaderChecklistValue<LeaderChecklistAnswer>;
  technicalRisksDescription: LeaderChecklistValue<string>;
  fillingPerson: LeaderChecklistValue<string>;
  roleResponsibleForTests: LeaderChecklistValue<string>;
  teamLeadershipRole: LeaderChecklistValue<string>;
  projectPlanPrepare: LeaderChecklistValue<LeaderChecklistAnswer>;
  projectPlanCheckpoints: LeaderChecklistValue<LeaderChecklistAnswer>;
  projectPlanCheckpointsComment: LeaderChecklistValue<string>;
  projectPlanMilestones: LeaderChecklistValue<LeaderChecklistAnswer>;
  projectPlanMilestonesComment: LeaderChecklistValue<string>;
  projectPlanFinalAcceptance: LeaderChecklistValue<LeaderChecklistAnswer>;
  projectPlanFinalAcceptanceComment: LeaderChecklistValue<string>;
  projectPlanConfirmed: LeaderChecklistValue<LeaderChecklistAnswer>;
  projectPlanConfirmedAdopt: LeaderChecklistValue<string>;
  projectPlanConfirmedApproach: LeaderChecklistValue<string>;
  costOptimizationPurchase: LeaderChecklistValue<LeaderChecklistAnswer>;
  costOptimizationPurchaseComment: LeaderChecklistValue<string>;
  costOptimizationResearch: LeaderChecklistValue<LeaderChecklistAnswer>;
  costOptimizationResearchComment: LeaderChecklistValue<string>;
  additionalCostInProject: LeaderChecklistValue<LeaderChecklistAnswer>;
  additionalCostInProjectComment: LeaderChecklistValue<string>;
  tableRiskChecked: LeaderChecklistValue<LeaderChecklistAnswer>;
};

export type LeaderChecklistValue<T> = {
  type: LeaderChecklistType;
  value: T;
};

export enum LeaderChecklistType {
  INPUT = 0,
  RADIO,
  SELECT
}

export enum LeaderChecklistField {
  EstimationProcessValidity = 'estimationProcessValidity',
  EstimationEvaluation = 'estimationEvaluation',
  EstimationElementsAnalysisMeetingOverhead = 'estimationElementsAnalysisMeetingOverhead',
  EstimationElementsAnalysisMeetingOverheadComment = 'estimationElementsAnalysisMeetingOverheadComment',
  EstimationElementsAnalysisSprintLength = 'estimationElementsAnalysisSprintLength',
  EstimationElementsAnalysisSprintLengthComment = 'estimationElementsAnalysisSprintLengthComment',
  EstimationElementsAnalysisRoles = 'estimationElementsAnalysisRoles',
  TeamCompositionAnalysis = 'teamCompositionAnalysis',
  TeamCompositionAnalysisCommentQA = 'teamCompositionAnalysisCommentQA',
  TeamCompositionAnalysisCommentLeader = 'teamCompositionAnalysisCommentLeader',
  TeamCompositionAnalysisReason = 'teamCompositionAnalysisReason',
  RisksAnalysed = 'risksAnalysed',
  RisksCooperation = 'risksCooperation',
  RisksDescription = 'risksDescription',
  RisksUnclearRequirements = 'risksUnclearRequirements',
  RisksUnclearRequirementsAffection = 'risksUnclearRequirementsAffection',
  RisksNonFunctionalRequirements = 'risksNonFunctionalRequirements',
  TechnicalRisksCooperation = 'technicalRisksCooperation',
  TechnicalRisksDescription = 'technicalRisksDescription',
  ProjectPlanPrepare = 'projectPlanPrepare',
  ProjectPlanCheckpoints = 'projectPlanCheckpoints',
  ProjectPlanCheckpointsComment = 'projectPlanCheckpointsComment',
  ProjectPlanMilestones = 'projectPlanMilestones',
  ProjectPlanMilestonesComment = 'projectPlanMilestonesComment',
  ProjectPlanFinalAcceptance = 'projectPlanFinalAcceptance',
  ProjectPlanFinalAcceptanceComment = 'projectPlanFinalAcceptanceComment',
  ProjectPlanConfirmed = 'projectPlanConfirmed',
  ProjectPlanConfirmedAdopt = 'projectPlanConfirmedAdopt',
  ProjectPlanConfirmedApproach = 'projectPlanConfirmedApproach',
  CostOptimizationPurchase = 'costOptimizationPurchase',
  CostOptimizationPurchaseComment = 'costOptimizationPurchaseComment',
  CostOptimizationResearch = 'costOptimizationResearch',
  CostOptimizationResearchComment = 'costOptimizationResearchComment',
  AdditionalCostInProject = 'additionalCostInProject',
  AdditionalCostInProjectComment = 'additionalCostInProjectComment',
  TableRiskChecked = 'tableRiskChecked'
}

export enum LeaderChecklistAnswer {
  YES = 'YES',
  NO = 'NO',
  NOT_RELEVANT = 'NOT RELEVANT',
  CONSERVATIVE = 'CONSERVATIVE',
  SAFE = 'SAFE',
  BALANCED = 'BALANCED',
  RISKY = 'RISKY',
  DANGEROUS = 'DANGEROUS',
  UNDEFINED = ''
}
