import { Level } from '@core/models/level';

export enum LevelSplit {
  None = 'None',
  Standard = 'Standard',
  Premium = 'Premium',
  Other = 'Other',
  NotDefined = ''
}

const LEVEL_SPLIT_NONE = [{ id: 'general', name: 'General' }];

const LEVEL_SPLIT_STANDARD = [
  { id: 'senior', name: 'Senior' },
  { id: 'medium', name: 'Medium' },
  { id: 'junior', name: 'Junior' }
];

export class RoleUtils {
  static getLevels(levelSplitValue: LevelSplit, levelSplit: boolean): Level[] {
    switch (levelSplitValue) {
      case LevelSplit.None:
        return LEVEL_SPLIT_NONE;
      case LevelSplit.Standard:
        return LEVEL_SPLIT_STANDARD;
      case LevelSplit.Premium:
        return [
          { id: 'architect', name: 'Architect' },
          { id: 'engineer', name: 'Engineer' }
        ];
      case LevelSplit.Other:
        return [
          { id: 'lead', name: 'Lead' },
          { id: 'regular', name: 'Regular' }
        ];
      default:
        if (levelSplit) {
          return LEVEL_SPLIT_STANDARD;
        } else {
          return LEVEL_SPLIT_NONE;
        }
    }
  }

  static setInitialLevelSplitValue(levelSplit: boolean, levelSplitValue: LevelSplit): LevelSplit {
    if (levelSplit) {
      return levelSplitValue || LevelSplit.Standard;
    }

    return LevelSplit.None;
  }
}
