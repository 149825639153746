import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as taskCommentActions from '@app/store/actions/task-comment.actions';
import { TaskCommentService } from '@core/services/task-comment.service';
import { TaskComment } from '@pageProjects/models/task-comment';
import { selectProject, selectTaskCommentsByTaskId, selectTaskFiles } from '@app/store';
import { Store } from '@ngrx/store';
import { ProjectModuleState } from '@app/store/reducers';
import * as taskActions from '@app/store/actions/task.actions';
import * as taskFilesActions from '@app/store/actions/task-files.actions';
import { CopyCommentsSuccess } from '@app/store/actions/task-comment.actions';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class TaskCommentEffects {
  constructor(
    private actions$: Actions,
    private taskCommentService: TaskCommentService,
    private store: Store<ProjectModuleState>
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskCommentActions.TaskCommentActionTypes.LOAD),
      map((action: taskCommentActions.Load) => action),
      withLatestFrom(this.store.select(selectProject)),
      switchMap(([, project]) =>
        this.taskCommentService.getAll(project.id).pipe(handleDocumentChanges('[TASK COMMENT] API'))
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskCommentActions.TaskCommentActionTypes.SAVE),
      map((action: taskCommentActions.Save) => action),
      withLatestFrom(this.store.select(selectProject)),
      switchMap(([action, project]) =>
        this.taskCommentService.save({ ...action.payload, project: project.id })
      ),
      map(() => new taskCommentActions.SaveSuccess()),
      catchError(() => of(new taskCommentActions.SaveFail()))
    )
  );

  copyTaskComments = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.COPY_CREATED),
      map((action: taskActions.CopyCreated) => action),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(selectTaskCommentsByTaskId(action.fromId)))
        )
      ),
      switchMap(([action, taskComments]) =>
        this.taskCommentService.copyComments(action.taskId, taskComments)
      ),
      map(() => new taskCommentActions.CopyCommentsSuccess()),
      catchError(() => of(new taskCommentActions.CopyCommentsFail()))
    )
  );
}
