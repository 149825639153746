import { Action } from '@ngrx/store';
import { FileToUpload, TaskFile, UnhandledFile } from '@pageProjects/models/task-file';

export enum TaskFilesActionTypes {
  LOAD = '[TASK FILES] LOAD TASK FILES',
  ADDED = '[TASK FILES] ADDED',
  UPLOAD = '[TASK FILES] UPLOAD',
  UPLOAD_SUCCESS = '[TASK FILES] UPLOAD SUCCESS',
  UPLOAD_FAIL = '[TASK FILES] UPLOAD FAIL',
  DELETE = '[TASK FILES] DELETE',
  DELETE_SUCCESS = '[TASK FILES] DELETE SUCCESS',
  DELETE_FAIL = '[TASK FILES] DELETE FAIL',
  REMOVED = '[TASK FILES] REMOVED',
  CLEAR = '[TASK FILES] CLEAR',
  API_ERROR = '[TASK FILES] ERROR',
  CLEAR_UNHANDLED_FILES = '[TASK FILES] CLEAR UNHANDLED FILES',
  COPY_FILES_SUCCESS = '[TASK FILES] COPY FILES SUCCESS',
  COPY_FILES_FAIL = '[TASK FILES] COPY FILES FAIL'
}

export class Load implements Action {
  readonly type = TaskFilesActionTypes.LOAD;

  constructor() {}
}

export class Added implements Action {
  readonly type = TaskFilesActionTypes.ADDED;

  constructor(public payload: TaskFile[]) {}
}

export class Upload implements Action {
  readonly type = TaskFilesActionTypes.UPLOAD;

  constructor(public taskId: string, public files: FileToUpload[]) {}
}

export class UploadSuccess implements Action {
  readonly type = TaskFilesActionTypes.UPLOAD_SUCCESS;

  constructor(public unhandledFiles: UnhandledFile[]) {}
}

export class UploadFail implements Action {
  readonly type = TaskFilesActionTypes.UPLOAD_FAIL;
}

export class Delete implements Action {
  readonly type = TaskFilesActionTypes.DELETE;

  constructor(public id: string, public downloadUrl: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = TaskFilesActionTypes.DELETE_SUCCESS;
}

export class DeleteFail implements Action {
  readonly type = TaskFilesActionTypes.DELETE_FAIL;
}

export class Removed implements Action {
  readonly type = TaskFilesActionTypes.REMOVED;

  constructor(public payload: TaskFile[]) {}
}

export class Clear implements Action {
  readonly type = TaskFilesActionTypes.CLEAR;
}

export class ApiError implements Action {
  readonly type = TaskFilesActionTypes.API_ERROR;
}

export class ClearUnhandledFiles implements Action {
  readonly type = TaskFilesActionTypes.CLEAR_UNHANDLED_FILES;
}

export class CopyTaskFilesSuccess implements Action {
  readonly type = TaskFilesActionTypes.COPY_FILES_SUCCESS;
}
export class CopyTaskFilesFail implements Action {
  readonly type = TaskFilesActionTypes.COPY_FILES_FAIL;
}

export type TaskFilesActions =
  | Load
  | Added
  | Upload
  | UploadSuccess
  | UploadFail
  | Removed
  | Clear
  | Delete
  | DeleteSuccess
  | DeleteFail
  | ClearUnhandledFiles
  | CopyTaskFilesSuccess
  | CopyTaskFilesFail;
