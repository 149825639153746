import { Action } from '@ngrx/store';
import { PayloadAction } from '@core/models/payload-action';
import { TaskComment } from '@pageProjects/models/task-comment';

export enum TaskCommentActionTypes {
  LOAD = '[TASK COMMENT] LOAD',
  ADDED = '[TASK COMMENT] API ADDED',
  SAVE = '[TASK COMMENT] SAVE',
  SAVE_SUCCESS = '[TASK COMMENT] SAVE SUCCESS',
  SAVE_FAIL = '[TASK COMMENT] SAVE FAIL',
  CLEAR = '[TASK COMMENT] CLEAR',
  COPY_COMMENTS_SUCCESS = '[TASK COMMENT] COPY COMMENTS SUCCESS',
  COPY_COMMENTS_FAIL = '[TASK COMMENT] COPY COMMENTS FAIL'
}

export class Load implements Action {
  readonly type = TaskCommentActionTypes.LOAD;
}

export class Added implements Action {
  readonly type = TaskCommentActionTypes.ADDED;

  constructor(public payload: TaskComment[]) {}
}

export class Save implements PayloadAction {
  readonly type = TaskCommentActionTypes.SAVE;
  payload: Partial<TaskComment>;

  constructor(payload: Partial<TaskComment>) {
    this.payload = payload;
  }
}

export class SaveSuccess implements Action {
  readonly type = TaskCommentActionTypes.SAVE_SUCCESS;
}

export class SaveFail implements Action {
  readonly type = TaskCommentActionTypes.SAVE_FAIL;
}

export class Clear implements Action {
  readonly type = TaskCommentActionTypes.CLEAR;
}

export class CopyCommentsSuccess implements Action {
  readonly type = TaskCommentActionTypes.COPY_COMMENTS_SUCCESS;
}
export class CopyCommentsFail implements Action {
  readonly type = TaskCommentActionTypes.COPY_COMMENTS_FAIL;
}

export type TaskCommentActions = Load | Added | Save | SaveSuccess | SaveFail | Clear;
