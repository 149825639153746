import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Collection } from '@shared/models/collection';
import { AdminUser } from '@pageProjects/models/admin-user';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(): AngularFirestoreCollection<AdminUser> {
    return this.afs.collection(Collection.ADMIN_USERS);
  }

  getAll(): Observable<DocumentChangeAction<AdminUser>[]> {
    return this.getCollection().snapshotChanges();
  }
}
