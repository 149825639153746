import { Action } from '@ngrx/store';
import { Project } from '@pageProjects/models/project';
import { PayloadAction } from '@core/models/payload-action';

export enum ProjectActionTypes {
  LOAD_ALL = '[PROJECT] LOAD ALL PROJECTS',
  ADDED = '[PROJECT API] ADDED',
  MODIFIED = '[PROJECT API] MODIFIED',
  REMOVED = '[PROJECT API] REMOVED',
  API_ERROR = '[PROJECT API] ERROR',
  CREATED = '[PROJECT] CREATED',
  CREATED_SUCCESS = '[PROJECT] CREATED SUCCESS',
  CREATED_FAIL = '[PROJECT] CREATED FAIL',
  DELETED = '[PROJECT] DELETED',
  DELETED_SUCCESS_REMOVE_PROJECT_DATA = '[PROJECT] DELETED SUCCESS REMOVE PROJECT DATA',
  DELETED_FAIL = '[PROJECT] DELETED FAIL',
  REMOVE_PROJECT_DATA_SUCCESS = '[PROJECT] REMOVE PROJECT DATA SUCCESS',
  REMOVE_PROJECT_DATA_FAIL = '[PROJECT] REMOVE PROJECT DATA FAIL',
  UPDATED = '[PROJECT] UPDATED',
  UPDATED_SUCCESS = '[PROJECT] UPDATED SUCCESS',
  UPDATED_FAIL = '[PROJECT] UPDATED FAIL',
  CLEAR = '[PROJECT] CLEAR',
  SET_PROJECT = '[PROJECT] SET_PROJECT',
  CLEAR_CURRENT_PROJECT = '[PROJECT] CLEAR_CURRENT_PROJECT',
  CREATED_AND_ACTIVATED = '[PROJECT] CREATED_AND_ACTIVATED',
  CREATED_AND_ACTIVATED_SUCCESS = '[PROJECT] CREATED_AND_ACTIVATED_SUCCESS',
  USER_ADDED = '[PROJECT] USER ADDED',
  USER_ADDED_SEND_MAIL = '[PROJECT] USER ADDED SEND MAIL',
  USER_ADDED_SEND_MAIL_SUCCESS = '[PROJECT] USER ADDED SEND MAIL SUCCESS',
  USER_ADDED_SEND_MAIL_FAIL = '[PROJECT] USER ADDED SEND MAIL FAIL',
  USER_REMOVED = '[PROJECT] USER REMOVED',
  COPY = '[PROJECT] COPY PROJECT',
  COPY_SUCCESS = '[PROJECT] COPY PROJECT SUCCESS',
  COPY_FAIL = '[PROJECT] COPY PROJECT FAIL',
  CLEAR_ALL = '[PROJECT] CLEAR ALL',
  SET_LOAD_ALL_STATE = '[PROJECT] SET LOAD ALL STATE'
}

export class LoadAll implements Action {
  readonly type = ProjectActionTypes.LOAD_ALL;
}

export class Added implements Action {
  readonly type = ProjectActionTypes.ADDED;

  constructor(public payload: Project[]) {}
}

export class Modified implements Action {
  readonly type = ProjectActionTypes.MODIFIED;

  constructor(public payload: Project[]) {}
}

export class Removed implements Action {
  readonly type = ProjectActionTypes.REMOVED;

  constructor(public payload: Project[]) {}
}

export class ApiError implements Action {
  readonly type = ProjectActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = ProjectActionTypes.CREATED;

  constructor(public payload: Partial<Project>, public technicalAreas?: string[]) {}
}

export class CreatedAndActivated implements Action {
  readonly type = ProjectActionTypes.CREATED_AND_ACTIVATED;

  constructor(public payload: Partial<Project>, public technicalAreas?: string[]) {}
}

export class CreatedSuccess implements Action {
  readonly type = ProjectActionTypes.CREATED_SUCCESS;

  constructor(public payload: Partial<Project>, public technicalAreas?: string[]) {}
}

export class CreatedAndActivatedSuccess implements Action {
  readonly type = ProjectActionTypes.CREATED_AND_ACTIVATED_SUCCESS;

  constructor(public redirect?: boolean, public id?: string) {}
}

export class CreatedFail implements Action {
  readonly type = ProjectActionTypes.CREATED_FAIL;

  constructor(public error: any) {}
}

export class Deleted implements Action {
  readonly type = ProjectActionTypes.DELETED;

  constructor(public projectId: string) {}
}

export class DeletedSuccessRemoveProjectData implements Action {
  readonly type = ProjectActionTypes.DELETED_SUCCESS_REMOVE_PROJECT_DATA;

  constructor(public projectId: string) {}
}

export class DeletedFail implements Action {
  readonly type = ProjectActionTypes.DELETED_FAIL;
}

export class RemoveProjectDataSuccess implements Action {
  readonly type = ProjectActionTypes.REMOVE_PROJECT_DATA_SUCCESS;
}

export class RemoveProjectDataFail implements Action {
  readonly type = ProjectActionTypes.REMOVE_PROJECT_DATA_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = ProjectActionTypes.UPDATED;

  constructor(public payload: Partial<Project>) {}
}

export class UserAdded implements Action {
  readonly type = ProjectActionTypes.USER_ADDED;

  constructor(public projectId: string, public email: string, public role: string) {}
}

export class UserAddedSendMail implements Action {
  readonly type = ProjectActionTypes.USER_ADDED_SEND_MAIL;

  constructor(public projectId: string, public email: string, public role: string) {}
}

export class UserAddedSendMailSuccess implements Action {
  readonly type = ProjectActionTypes.USER_ADDED_SEND_MAIL_SUCCESS;
}

export class UserAddedSendMailFail implements Action {
  readonly type = ProjectActionTypes.USER_ADDED_SEND_MAIL_FAIL;
}

export class UserRemoved implements Action {
  readonly type = ProjectActionTypes.USER_REMOVED;

  constructor(public projectId: string, public email: string, public role: string) {}
}

export class UpdatedSuccess implements Action {
  readonly type = ProjectActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = ProjectActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = ProjectActionTypes.CLEAR;
}

export class SetProject implements Action {
  readonly type = ProjectActionTypes.SET_PROJECT;

  constructor(public projectId: string) {}
}

export class ClearCurrentProject implements Action {
  readonly type = ProjectActionTypes.CLEAR_CURRENT_PROJECT;
}

export class Copy implements Action {
  readonly type = ProjectActionTypes.COPY;

  constructor(public projectId: string) {}
}

export class CopySuccess implements Action {
  readonly type = ProjectActionTypes.COPY_SUCCESS;
}

export class CopyFail implements Action {
  readonly type = ProjectActionTypes.COPY_FAIL;
}

export class ClearAll implements Action {
  readonly type = ProjectActionTypes.CLEAR_ALL;
}

export class SetLoadAllState implements Action {
  readonly type = ProjectActionTypes.SET_LOAD_ALL_STATE;

  constructor(public state: boolean) {}
}

export type ProjectActions =
  | LoadAll
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Deleted
  | DeletedSuccessRemoveProjectData
  | DeletedFail
  | RemoveProjectDataSuccess
  | RemoveProjectDataFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear
  | SetProject
  | ClearCurrentProject
  | CreatedAndActivated
  | CreatedAndActivatedSuccess
  | UserAdded
  | UserRemoved
  | Copy
  | CopySuccess
  | CopyFail
  | UserAddedSendMail
  | UserAddedSendMailSuccess
  | UserAddedSendMailFail
  | ClearAll
  | SetLoadAllState;
