import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Log } from '@pageProjects/models/log';
import { LogActions, LogActionTypes } from '@app/store/actions/log.actions';
import { ProjectUtils } from '@pageProjects/tools/utils';

export const logAdapter: EntityAdapter<Log> = createEntityAdapter<Log>();

export interface LogState extends EntityState<Log> {}

export const logInitialState: LogState = logAdapter.getInitialState([]);

export const logReducer = (logState: LogState = logInitialState, actions: LogActions): LogState => {
  switch (actions.type) {
    case LogActionTypes.ADDED: {
      const logs = actions.payload.map((l) => ProjectUtils.convertDates<Log>(l));
      return logAdapter.addMany(logs, logState);
    }
    case LogActionTypes.CLEAR:
      return logAdapter.removeAll(logState);
    default:
      return logState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = logAdapter.getSelectors();
