export const commonEnvironment = {
  strategyName: 'fp-ad',
  firebase: {
    measurementId: 'G-HKSX40S9HB',
    apiKey: 'AIzaSyAXwtwb_uGO9UjBwTGo8h_E6YqZPWKyzLk',
    authDomain: 'fpert-stage.firebaseapp.com',
    projectId: 'fpert-stage',
    storageBucket: 'fpert-stage.appspot.com',
    messagingSenderId: '877138564499',
    appId: '1:877138564499:web:ca3583bfef6e72082b4466'
  },
  azureTenantId: 'd70890a4-3ec5-4da3-b812-61e6b3ff4ae7',
  debounceTime: 300,
  redirectUrl: 'redirect_url',
  loginPath: '/login',
  projectsPath: '/projects'
};

export const environment = {
  production: false,
  useFirebaseEmulators: false,
  useAuthEmulator: false,
  stubCurrencies: false,
  ...commonEnvironment
};
