import { NbMenuItem } from '@nebular/theme';
import { SelectedPhase } from '@pageProjects/models/selected-phase';
import { SelectedTask } from '@pageProjects/models/selected-task';
import {
  UserInterfaceActions,
  UserInterfaceActionTypes
} from '@app/store/actions/user-interface.actions';

export interface UserInterfaceState {
  menuItems: NbMenuItem[] | null;
  selectedTasks: SelectedTask[];
  selectedPhases: SelectedPhase[];
  selectedTasksInBacklog: SelectedTask[];
}

export const userInterfaceInitialState: UserInterfaceState = {
  menuItems: null,
  selectedTasks: [],
  selectedPhases: [],
  selectedTasksInBacklog: []
};

export const userInterfaceReducer = (
  userInterfaceState: UserInterfaceState = userInterfaceInitialState,
  action: UserInterfaceActions
): UserInterfaceState => {
  switch (action.type) {
    case UserInterfaceActionTypes.SELECT_TASK:
      return {
        ...userInterfaceState,
        selectedTasks: [...userInterfaceState.selectedTasks, action.data]
      };
    case UserInterfaceActionTypes.UNSELECT_TASK:
      return {
        ...userInterfaceState,
        selectedTasks: userInterfaceState.selectedTasks.filter(
          (item) => item.task !== action.data.task
        )
      };
    case UserInterfaceActionTypes.PHASE_TASKS_SELECTED:
      const selectedTasks = getUniqueSelectedTasks(userInterfaceState, action.selectedTasks);
      return {
        ...userInterfaceState,
        selectedTasks: [...selectedTasks]
      };
    case UserInterfaceActionTypes.UNSELECT_PHASE_TASKS:
      return {
        ...userInterfaceState,
        selectedTasks: userInterfaceState.selectedTasks.filter(
          (item) => item.phase !== action.phase
        )
      };
    case UserInterfaceActionTypes.SET_PHASES_DATA:
      const phases = getUpdatedPhases(userInterfaceState, action.data);
      return {
        ...userInterfaceState,
        selectedPhases: [...phases]
      };
    case UserInterfaceActionTypes.REMOVE_PHASES_DATA:
      return {
        ...userInterfaceState,
        selectedTasks: userInterfaceState.selectedTasks.filter(
          (item) => item.phase !== action.phase
        ),
        selectedPhases: [
          ...userInterfaceState.selectedPhases.filter((sPhase) => sPhase.phase !== action.phase)
        ]
      };
    case UserInterfaceActionTypes.UNSELECT_ALL:
      return {
        ...userInterfaceState,
        selectedTasks: [],
        selectedPhases: [],
        selectedTasksInBacklog: []
      };
    case UserInterfaceActionTypes.SELECT_TASK_IN_BACKLOG:
      return {
        ...userInterfaceState,
        selectedTasksInBacklog: [...userInterfaceState.selectedTasksInBacklog, action.data]
      };
    case UserInterfaceActionTypes.UNSELECT_TASK_IN_BACKLOG:
      return {
        ...userInterfaceState,
        selectedTasksInBacklog: userInterfaceState.selectedTasksInBacklog.filter(
          (item) => item.task !== action.data.task
        )
      };
    case UserInterfaceActionTypes.UNSELECT_ALL_TASKS_IN_BACKLOG:
      return {
        ...userInterfaceState,
        selectedTasksInBacklog: []
      };
    default:
      return userInterfaceState;
  }
};

const getUpdatedPhases = (
  userInterfaceState: UserInterfaceState,
  selectedPhase: SelectedPhase
): SelectedPhase[] => {
  let newSelectedPhases = [...userInterfaceState.selectedPhases];
  if (!userInterfaceState.selectedPhases.some((p) => p.phase === selectedPhase.phase)) {
    newSelectedPhases.push(selectedPhase);
  } else {
    newSelectedPhases = newSelectedPhases.map((p) => {
      if (p.phase === selectedPhase.phase) {
        p = selectedPhase;
      }
      return p;
    });
  }

  return newSelectedPhases;
};

const getUniqueSelectedTasks = (
  userInterfaceState: UserInterfaceState,
  selectedTasks: SelectedTask[]
): SelectedTask[] => {
  const newSelectedTasks = [...userInterfaceState.selectedTasks];
  selectedTasks.forEach((sTasks) => {
    if (!userInterfaceState.selectedTasks.some((t) => t.task === sTasks.task)) {
      newSelectedTasks.push(sTasks);
    }
  });
  return newSelectedTasks;
};
