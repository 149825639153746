import { Injectable } from '@angular/core';
import { Estimate } from '@pageProjects/models/estimate';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference
} from '@angular/fire/compat/firestore';
import { Collection } from '@shared/models/collection';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstimateHistoryService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(projectId: string): AngularFirestoreCollection<Estimate> {
    const queryFn = (ref) => ref.where('project', '==', projectId);
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Estimate>(Collection.ESTIMATE_HISTORY, queryFn);
  }

  getAll(projectId: string): Observable<DocumentChangeAction<Estimate>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  add(history: Estimate): Observable<DocumentReference<Estimate>> {
    return from(this.getCollection(history.project).add(history));
  }
}
