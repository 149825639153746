import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference
} from '@angular/fire/compat/firestore';
import { Log } from '@pageProjects/models/log';
import { Collection } from '@shared/models/collection';
import { from, Observable, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Project } from '@pageProjects/models/project';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private afs: AngularFirestore) {}

  log(log: Log): Observable<DocumentReference<Log>> {
    return from(this.getCollection(log.project).add(log));
  }

  getAll(projects: Project[]): Observable<DocumentChangeAction<Log>[]> {
    return of(projects).pipe(
      switchMap((data) => {
        const logsFetch: Observable<DocumentChangeAction<Log>[]>[] = [];
        data.forEach((project) => {
          logsFetch.push(this.getNewestLog(project.id).stateChanges());
        });
        return zip(...logsFetch);
      }),
      switchMap((a) => {
        const e: DocumentChangeAction<Log>[] = [];
        return of(e.concat(...a));
      })
    );
  }

  private getCollection(projectId: string): AngularFirestoreCollection<Log> {
    return this.afs.collection(Collection.PROJECTS).doc(projectId).collection(Collection.LOGS);
  }

  private getNewestLog(projectId: string) {
    const queryFn = (ref) => ref.orderBy('time', 'desc').limit(1);

    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Log>(Collection.LOGS, queryFn);
  }
}
