import { Weights } from '@pageProjects/models/weights';
import { std } from 'mathjs';

export const weightedAverage = (estimate: Estimate, weights?: Weights): number => {
  const optimistic = (estimate?.optimistic || 0) * (weights?.optimistic || 0);
  const realistic = (estimate?.realistic || 0) * (weights?.realistic || 0);
  const pessimistic = (estimate?.pessimistic || 0) * (weights?.pessimistic || 0);
  return (
    (optimistic + realistic + pessimistic) /
    (weights?.optimistic + weights?.realistic + weights?.pessimistic)
  );
};

export const standardDeviation = (estimate: Estimate, weights?: Weights): number => {
  if (validateEstimate(estimate)) {
    const optimistic = new Array(weights.optimistic).fill(estimate.optimistic);
    const realistic = new Array(weights.realistic).fill(estimate.realistic);
    const pessimistic = new Array(weights.pessimistic).fill(estimate.pessimistic);

    return std([...optimistic, ...pessimistic, ...realistic], 'uncorrected');
  }
  return 0;
};

export interface Estimate {
  id?: string;
  task: string;
  project: string;
  technicalArea: string;
  optimistic: number;
  realistic: number;
  pessimistic: number;
  changeTime?: Date;
  authorId?: string;
  unbalanced?: boolean;
  initialized?: boolean;
  average?: number;
  deviation?: number;
  isEstimated?: boolean;
  userName?: string;
}

export const validateEstimate = (estimate: Estimate): boolean =>
  estimate.optimistic + estimate.realistic + estimate.pessimistic !== 0 &&
  estimate.optimistic <= estimate.realistic &&
  estimate.realistic <= estimate.pessimistic &&
  estimate.pessimistic !== null &&
  estimate.pessimistic !== undefined &&
  estimate.optimistic !== null &&
  estimate.optimistic !== undefined &&
  estimate.realistic !== null &&
  estimate.realistic !== undefined;
