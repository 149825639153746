import {
  addBusinessDays,
  addDays,
  addMonths,
  isWeekend,
  nextMonday,
  differenceInCalendarMonths,
  differenceInCalendarDays,
  differenceInBusinessDays,
  startOfMonth,
  endOfMonth,
  getDaysInMonth
} from 'date-fns';

/**
 * Duration class to calculate area duration
 */
export class Duration {
  /**
   * The day after end date (start date of the next phase),
   * can be Saturday if last day is Friday
   * - total number of days in phase will not include weekend if it is at the end.
   */
  private readonly endDate: Date;
  constructor(
    private readonly raw: number,
    private readonly total: number,
    private readonly sprint0: number,
    private readonly chargability: number,
    private readonly startDate: Date
  ) {
    this.endDate = this.calculateEndDate();
  }

  /**
   * Gets sprint0 len (or 0 if not on this phase)
   * Used by phase duration chart view, may be refactored in future
   */
  getSprint0() {
    return this.sprint0;
  }

  getWithChargability(): number {
    return this.total / (this.chargability / 100);
  }

  /**
   * Gets overhead
   * Used by phase duration chart view, may be refactored in future
   */
  getOverhead() {
    return this.total - this.raw;
  }

  /**
   * Gets raw (without overhead) but with chargability added
   * Used by phase duration chart view, may be refactored in future
   */
  getRawWithChargability() {
    return this.raw / (this.chargability / 100);
  }

  /**
   * Gets value in working days (with sprint0) for client cost calculation (without charchability)
   */
  getInWorkingDays(): number {
    return this.total + this.sprint0;
  }

  /**
   * Gets value in working days (with sprint0) for client cost calculation (without charchability) ceiled to full days
   */
  getInWorkingFullDays(): number {
    return Math.ceil(this.total + this.sprint0);
  }

  /**
   * Gets value in working days for displaying (with charchability) ceiled to full days
   * Used by phase duration chart view, may be refactored in future
   */
  getInWorkingDaysWithChargability(): number {
    return Math.ceil((this.total + this.sprint0) / (this.chargability / 100));
  }

  /**
   * Gets start day of the phase
   */
  getStartDate() {
    return this.startDate;
  }

  /**
   * Gets day after end date of the phase (or at least technical area end)
   */
  getEndDate() {
    return this.endDate;
  }

  /**
   * Gets the number of months with fractional part.
   * Use only to display, it is better to not use this value for calculations because of rounding problems
   *
   * @param rounded
   * If value should be rounded to 2 digits after coma
   */
  getInMonths(rounded: boolean = true): number {
    let duration = differenceInCalendarMonths(this.endDate, this.startDate);
    if (duration === 0) {
      // The same month, simple calculation
      duration =
        differenceInCalendarDays(this.endDate, this.startDate) / getDaysInMonth(this.startDate);
    } else {
      // Removing one month, because first and last month is partial
      duration -= 1;
      const eom = endOfMonth(this.startDate);
      duration +=
        (differenceInCalendarDays(eom, this.startDate) + 1) / getDaysInMonth(this.startDate);
      const som = startOfMonth(this.endDate);
      duration += differenceInCalendarDays(this.endDate, som) / getDaysInMonth(this.endDate);
    }

    if (rounded) {
      const factor = 10 ** 2;
      return Math.round(duration * factor) / factor;
    }

    return duration;
  }

  /**
   * Gets map of months to create cost schedule map
   */
  getMonthsFill(): Map<Date, number> {
    let duration = differenceInCalendarMonths(this.endDate, this.startDate);
    const retMap = new Map<Date, number>();
    let processDate = startOfMonth(this.startDate);
    if (duration === 0) {
      // The same month, simple calculation
      duration = differenceInBusinessDays(this.endDate, this.startDate);
      retMap.set(processDate, duration);
      return retMap;
    }

    let nextMonth = addMonths(processDate, 1);
    duration = differenceInBusinessDays(nextMonth, this.startDate);
    retMap.set(processDate, duration);
    processDate = nextMonth;
    const som = startOfMonth(this.endDate);
    while (processDate < som) {
      nextMonth = addMonths(nextMonth, 1);
      duration = differenceInBusinessDays(nextMonth, processDate);
      retMap.set(processDate, duration);
      processDate = nextMonth;
    }

    duration = differenceInBusinessDays(this.endDate, som);
    retMap.set(som, duration);

    return retMap;
  }

  /**
   * Calculates the day after end date (start date of the next phase),
   * can be Saturday if last day is Friday
   * - total number of days in phase will not include weekend if it is at the end.
   */
  private calculateEndDate() {
    let x = this.getInWorkingDaysWithChargability();
    x = x - 1;
    if (x < 0) {
      return this.startDate;
    } else if (x === 0) {
      if (isWeekend(this.startDate)) {
        return addDays(nextMonday(this.startDate), 1);
      }

      return addDays(this.startDate, 1);
    } else {
      if (isWeekend(this.startDate)) {
        return addDays(addBusinessDays(nextMonday(this.startDate), x), 1);
      }

      return addDays(addBusinessDays(this.startDate, x), 1);
    }
  }
}
