import { Injectable } from '@angular/core';
import { PhaseService } from '@core/services/phase.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';
import * as configActions from '@app/store/actions/phase-support-role-config.actions';
import { of } from 'rxjs';
import * as fromStore from '@app/store/selectors';
import { ProjectModuleState } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { handleDocumentChanges } from '@shared/operators';

@Injectable()
export class PhaseSupportRoleConfigEffects {
  constructor(
    private actions$: Actions,
    private phaseService: PhaseService,
    private store: Store<ProjectModuleState>
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.PhaseSupportRoleConfigActionTypes.LOAD),
      map((action: configActions.Load) => action),
      switchMap((loadAction) =>
        this.phaseService
          .getConfigs(loadAction.projectId)
          .pipe(handleDocumentChanges('[PHASE SUPPORT ROLE CONFIG API]'))
      )
    )
  );

  change$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.PhaseSupportRoleConfigActionTypes.CONFIG_CHANGED),
      map((action: configActions.ConfigChanged) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      map(
        ([action, project]) =>
          new configActions.Saved({
            phase: action.phase,
            isUniform: action.value,
            role: action.role,
            project: project.id
          })
      )
    )
  );

  saved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.PhaseSupportRoleConfigActionTypes.SAVED),
      map((action: configActions.Saved) => action),
      switchMap((data) =>
        this.phaseService.saveConfig(data.payload).pipe(
          map(() => new configActions.SavedSuccess()),
          catchError(() => of(new configActions.SavedFail()))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.PhaseSupportRoleConfigActionTypes.DELETED),
      map((action: configActions.Deleted) => action),
      switchMap((data) =>
        this.phaseService.deleteConfig(data.config).pipe(
          map(() => new configActions.DeletedSuccess()),
          catchError(() => of(new configActions.DeletedFail()))
        )
      )
    )
  );

  batchUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.PhaseSupportRoleConfigActionTypes.COPY_BATCH),
      map((action: configActions.Copy) => action),
      mergeMap((data) =>
        of(data).pipe(
          withLatestFrom(
            this.store.select(fromStore.selectRoleConfigForCopy(data.fromPhaseId, data.toPhaseId))
          )
        )
      ),
      switchMap(([, config]) =>
        this.phaseService
          .copyConfigBatch({
            from: config.fromConfig,
            to: config.toConfig
          })
          .pipe(
            map(() => new configActions.CopySuccess()),
            catchError(() => of(new configActions.CopyFail()))
          )
      )
    )
  );
}
