import { Gaussian } from 'ts-gaussian';
import { TechnicalArea } from '@pageProjects/models/technical-area';
import { EstimateForCalculation } from './estimateForCalculation';
import { TaskType } from '@pageProjects/models/task';

export interface ReducedAreaWorkdays {
  readonly area: string;
  readonly areaObject: TechnicalArea;
  readonly workdaysByTask: Map<string, number>;
  readonly workdaysByType: Map<TaskType, number>;
  readonly workdays: number;
}

export class EstimateGroup {
  estimates: EstimateForCalculation[] = [];

  /**
   *
   * @param area id of the technical area for witch this group is created
   * @param certainty certainty level to use in Gaussian Percent Point function
   */
  constructor(public readonly area: string, private readonly certainty: number) {}

  add(estimate: EstimateForCalculation) {
    this.estimates.push(estimate);
  }

  /**
   * Returns single workday number based on the configured certainty and overhead
   */
  reduced(ta: TechnicalArea): ReducedAreaWorkdays {
    const workdaysByTask = new Map<string, number>();
    const workdaysByType = new Map<TaskType, number>();

    this.estimates
      .map((estimate): EstimateForCalculation => {
        const mean = this.sum([estimate], 'average');
        const variance = this.sum([estimate], 'deviation', true);
        const value =
          variance === 0 ? mean : new Gaussian(mean, variance).ppf(this.certainty / 100);

        workdaysByTask.set(estimate.taskId, value);

        return estimate;
      })
      .reduce((accumulator, item) => {
        const estimates = accumulator.get(item.taskType) || [];

        estimates.push(item);

        return accumulator.set(item.taskType, estimates);
      }, new Map<TaskType, EstimateForCalculation[]>())
      .forEach((estimates, taskType) => {
        const mean = this.sum(estimates, 'average');
        const variance = this.sum(estimates, 'deviation', true);
        const value =
          variance === 0 ? mean : new Gaussian(mean, variance).ppf(this.certainty / 100);

        workdaysByType.set(taskType, Math.ceil(value));
      });

    const totalMean = this.sum(this.estimates, 'average');
    const totalVariance = this.sum(this.estimates, 'deviation', true);
    const totalValue =
      totalVariance === 0
        ? totalMean
        : new Gaussian(totalMean, totalVariance).ppf(this.certainty / 100);

    return {
      area: this.area,
      areaObject: ta,
      workdaysByTask,
      workdaysByType,
      workdays: Math.ceil(totalValue)
    };
  }

  /**
   * Calculates sum of values in given field of all estimates in the group
   *
   * @param estimates
   * @param field
   * @param pow
   * @private
   */
  private sum(estimates: EstimateForCalculation[], field: string, pow = false): number {
    return estimates
      ?.filter((estimate) => estimate.isEstimated)
      ?.map((estimate) => estimate[field])
      ?.filter((value) => !isNaN(value))
      ?.reduce((p, c) => p + (pow ? Math.pow(c, 2) : c), 0);
  }
}
