import * as taskActions from '@app/store/actions/task.actions';
import * as estimateActions from '@app/store/actions/estimate.actions';
import { ProjectModuleState } from '@app/store/reducers';
import { NbAuthService } from '@nebular/auth';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EstimateService } from '@core/services/estimate.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as fromStore from '@app/store/selectors';
import { Estimate } from '@pageProjects/models/estimate';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class EstimateEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly estimateService: EstimateService,
    private readonly store: Store<ProjectModuleState>,
    private readonly authService: NbAuthService
  ) {}

  copy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(taskActions.TaskActionTypes.COPY_CREATED),
        map((action: taskActions.CopyCreated) => action),
        switchMap((action) =>
          combineLatest(
            this.withEstimatesForTask(action.fromId, action),
            this.authService.getToken()
          )
        ),
        map(([[action, estimates], token]) => {
          const newEstimates = estimates.map((estimate) => {
            const copy: Partial<Estimate> = {
              ...estimate,
              task: action.taskId,
              changeTime: new Date(),
              authorId: token.getPayload()?.email
            };

            return copy;
          });

          newEstimates.forEach((estimate) => {
            this.estimateService.save(estimate);
          });
        })
      ),
    { dispatch: false }
  );

  deleteForArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(estimateActions.EstimateActionTypes.DELETE_FOR_AREA),
      map((action: estimateActions.DeleteForArea) => action),
      switchMap((action) =>
        this.estimateService.deleteForArea(action.projectId, action.areaId).pipe(
          map(() => new estimateActions.DeleteForAreaSuccess()),
          catchError(() => of(new estimateActions.DeleteForAreaFail()))
        )
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(estimateActions.EstimateActionTypes.UPDATED),
      map((action: estimateActions.Updated) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      map(([action, project]) => {
        const estimate: Partial<Estimate> = {
          project: project.id,
          task: action.taskId,
          authorId: action.email,
          technicalArea: action.technicalArea,
          changeTime: new Date()
        };
        estimate[action.field] = action.payload;
        return this.estimateService.save(estimate);
      }),
      mergeMap((id) => id),
      map((id) => new estimateActions.UpdatedSuccess(id)),
      catchError(() => of(new estimateActions.UpdatedFail()))
    )
  );

  loadEstimates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(estimateActions.EstimateActionTypes.LOAD),
      map((action: estimateActions.Load) => action),
      switchMap((loadAction) =>
        this.estimateService
          .getAll(loadAction.projectId)
          .pipe(handleDocumentChanges('[ESTIMATE API]'))
      )
    )
  );

  redistribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(estimateActions.EstimateActionTypes.REDISTRIBUTE),
      map((action: estimateActions.Redistribute) => action),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(this.authService.getToken()),
          concatLatestFrom(() => this.store.select(fromStore.selectAllEstimates)),
          switchMap(([[_, token], allEstimates]) =>
            this.estimateService.redistributeEstimates(
              action.projectId,
              action.areaId,
              action.otherAreasIds,
              allEstimates,
              token.getPayload()?.email
            )
          ),
          map(() => new estimateActions.RedistributeSuccess()),
          catchError(() => of(new estimateActions.RedistributeFail()))
        )
      )
    )
  );

  moveToTechnicalArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(estimateActions.EstimateActionTypes.MOVE_TO_TECHNICAL_AREA),
      map((action: estimateActions.MoveToTechnicalArea) => action),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(this.authService.getToken()),
          concatLatestFrom(() => [
            this.store.select(fromStore.selectEstimatesForArea(action.areaId)),
            this.store.select(fromStore.selectEstimatesForArea(action.toAreaId))
          ]),
          switchMap(([[_, token], estimatesFromArea, estimatesToArea]) =>
            this.estimateService.moveEstimatesToArea(
              action.projectId,
              estimatesFromArea,
              action.toAreaId,
              estimatesToArea,
              token.getPayload()?.email
            )
          ),
          map(() => new estimateActions.MoveToTechnicalAreaSuccess()),
          catchError(() => of(new estimateActions.MoveToTechnicalAreaFail()))
        )
      )
    )
  );

  private withEstimatesForTask<T>(taskId: string, data: T): Observable<[T, Estimate[]]> {
    return this.store.select(fromStore.selectEstimatesForTask(taskId)).pipe(
      first(),
      map((estimates) => [data, estimates])
    );
  }
}
