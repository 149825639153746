import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Project, ProjectWithChildren } from '@pageProjects/models/project';
import * as fromProject from '@app/store/reducers/project.reducer';
import { Weights } from '@pageProjects/models/weights';
import { ProjectUtils } from '@pageProjects/tools/utils';

export const selectProjectsState = createFeatureSelector<fromProject.ProjectState>('projects');

export const selectAllProjects = createSelector(selectProjectsState, fromProject.selectAll);

export const getCurrentProjectId = createSelector(
  selectProjectsState,
  (projects) => projects.currentProjectId
);

export const areProjectsLoaded = createSelector(
  selectProjectsState,
  (projects) => projects.areProjectsLoaded
);

export const selectProject = createSelector(selectProjectsState, (projectState) => {
  const project = projectState?.entities[projectState.currentProjectId];
  if (project === undefined) {
    return null;
  }

  return new Project({
    ...project,
    contributors: [...(project?.contributors ?? [])]?.sort(),
    owners: [...(project?.owners ?? [])]?.sort(),
    viewers: [...(project?.viewers ?? [])]?.sort()
  });
});

export const selectProjectWithParent = createSelector(selectProjectsState, (projectState) => {
  const project = projectState?.entities[projectState.currentProjectId];
  if (!project) {
    return null;
  }

  const parent = project.parentId ? projectState?.entities[project.parentId] : undefined;
  return {
    project: new Project(project),
    parent: new Project(parent)
  };
});

export const selectProjectById = (projectId: string) =>
  createSelector(selectAllProjects, (projects) =>
    projects.find((project) => project.id === projectId)
  );

export const selectCopiesByParentId = (projectId: string) =>
  createSelector(selectAllProjects, (projects) =>
    projects.filter((project) => project?.parentId === projectId)
  );

export const selectProjectWeights = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    const defaultWeights: Weights = {
      optimistic: 1,
      realistic: 3,
      pessimistic: 2
    };
    return selectedProject.weights || defaultWeights;
  }
});

export const selectProjectCertainty = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    return selectedProject.certainty;
  }
});

export const selectProjectVariantSupport = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    return selectedProject.variantsEnabled;
  }
});

export const selectProjectSprint0 = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    return selectedProject.sprint0Length;
  }
});

export const selectProjectMeetingOverhead = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    return selectedProject.meetingOverhead;
  }
});

export const selectProjectCurrency = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    return selectedProject.currency;
  }
});

export const selectProjectChargability = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    return selectedProject.chargability;
  }
});

export const selectProjectStartDate = createSelector(selectProject, (selectedProject) => {
  if (selectedProject) {
    return selectedProject.startDate;
  }
});

export const selectProjectRateCurrency = createSelector(
  selectProject,
  (selectedProject) => selectedProject && (selectedProject.rateCurrency ?? 'PLN')
);

export const selectProjectDiscoveryRateCurrency = createSelector(
  selectProject,
  (selectedProject) => selectedProject && (selectedProject.discoveryRateCurrency ?? 'PLN')
);

export const selectProjectOverhead = createSelector(
  selectProject,
  (selectedProject) => selectedProject && selectedProject.monthlyOverheadCost
);

export const selectVisibleProjects = createSelector(selectAllProjects, (projects) =>
  listToTree(projects.filter((project) => project.visible))
);

export const selectDiscoveryEnabled = createSelector(
  selectProject,
  (project) => project.discoveryEnabled
);

const listToTree = (projects: Project[]) => {
  const projectsWithChildren: { [key: string]: ProjectWithChildren } = {};
  projects.forEach((project) => {
    projectsWithChildren[project.id] = { project: Object.assign({}, project), children: [] };
  });

  const roots: ProjectWithChildren[] = [];
  projects.forEach((project) => {
    if (project.parentId && projectsWithChildren[project.parentId]) {
      projectsWithChildren[project.parentId].children.push(
        projectsWithChildren[project.id].project
      );
    } else {
      roots.push(projectsWithChildren[project.id]);
    }
  });

  roots.sort((a, b) => ProjectUtils.sortByValue(a, b, (obj) => obj.project.name));
  roots.forEach((p) => {
    if (p.children.length) {
      p.children.sort((a, b) => ProjectUtils.sortByProperty(a, b, 'name'));
    }
  });

  return roots;
};

export const selectProjectCopiesWithParent = (projectId: string) =>
  createSelector(selectAllProjects, (projects) => {
    const currentProject = projects.find((project) => project.id === projectId);
    const parentProject = projects.find(
      (project) => project.id === (currentProject?.parentId || currentProject?.id)
    );
    const otherCopies = projects
      .filter((project) => project?.parentId === (currentProject?.parentId || currentProject?.id))
      .sort((a, b) => ProjectUtils.sortByProperty(a, b, 'name'));
    return [parentProject, ...otherCopies];
  });
