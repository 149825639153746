import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference
} from '@angular/fire/compat/firestore';
import { BusinessLine } from '@shared/models/business-line';
import { Collection } from '@shared/models/collection';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessLinesService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(): AngularFirestoreCollection<BusinessLine> {
    return this.afs.collection(Collection.BUSINESS_LINE);
  }

  add(businessLine: Partial<BusinessLine>): Observable<{
    businessLineId: string;
  }> {
    return from(
      this.getCollection()
        .add({ ...businessLine } as BusinessLine)
        .catch((e) => e)
        .then((docRef: DocumentReference<BusinessLine>) => ({ businessLineId: docRef.id }))
    );
  }

  update(businessLine: Partial<BusinessLine>): Observable<string> {
    const updatedBusinessLineData = { ...businessLine };
    delete updatedBusinessLineData.id;

    return from(
      this.getCollection()
        .doc<BusinessLine>(`${businessLine.id}`)
        .update({
          ...updatedBusinessLineData
        })
        .catch((e) => e)
        .then(() => 'businessArea updated')
    );
  }

  delete(businessAreaId: string): Observable<boolean> {
    return from(
      this.getCollection()
        .doc(businessAreaId)
        .delete()
        .then(() => true)
        .catch(() => false)
    );
  }

  getAll(): Observable<DocumentChangeAction<BusinessLine>[]> {
    return this.getCollection().stateChanges();
  }
}
