import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TaskService } from '@core/services/task.service';
import * as taskActions from '@app/store/actions/task.actions';

import { Observable, of, zip } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ProjectModuleState } from '@app/store/reducers';
import * as fromStore from '@app/store/selectors';
import { Task } from '@pageProjects/models/task';
import { TaskAction } from '@app/store/actions/task.actions';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NbToastrService } from '@nebular/theme';
import { ProjectUtils } from '@pageProjects/tools/utils';
import { PhaseService } from '@core/services/phase.service';
import { Phase } from '@pageProjects/models/phase';
import { Project } from '@pageProjects/models/project';
import { EstimateService } from '@core/services/estimate.service';
import { NbAuthService } from '@nebular/auth';
import { handleDocumentChanges } from '../../shared/operators';

interface ActionWithTask<T extends TaskAction> {
  action: T;
  result: Task;
}

export type ActionWithTasks<T extends TaskAction, U extends Task[]> = [ActionWithTask<T>, U];

@Injectable()
export class TaskEffects {
  constructor(
    private actions$: Actions,
    private taskService: TaskService,
    private store: Store<ProjectModuleState>,
    private toastService: NbToastrService,
    private readonly phaseService: PhaseService,
    private readonly estimateService: EstimateService,
    private readonly authService: NbAuthService
  ) {}

  loadTasks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.LOAD),
      map((action: taskActions.Load) => action),
      switchMap((loadAction) =>
        this.taskService.getAll(loadAction.projectId).pipe(handleDocumentChanges('[TASK API]'))
      )
    )
  );

  created$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.CREATED),
      map((action: taskActions.Created) => action),
      switchMap((action) => this.getFirstPhaseIdIfUndefined(action).pipe(take(1))),
      switchMap(([action, phaseId]) => this.getMaxTaskOrder(action, phaseId)),
      switchMap(([action, phaseId, maxOrder]) => {
        const order = action.afterOrder !== undefined ? action.afterOrder : maxOrder;
        return this.getTasksByOrder<[taskActions.Created, string, number]>(phaseId, order, [
          action,
          phaseId,
          maxOrder
        ]);
      }),
      withLatestFrom(
        this.store.select(fromStore.selectProject),
        this.store.select(fromStore.selectTaskCount)
      ),
      switchMap(([[[action, phaseId, maxOrder], tasks], project, count]) =>
        zip(
          this.createTask(action, phaseId, maxOrder, project, count),
          of(action),
          this.taskService.updateBatch(project.id, this.getTasksForOrderUpdate(tasks))
        )
      ),
      switchMap(([res, action]) => {
        const actions = [];
        if (res && res.id) {
          actions.push(new taskActions.SetCreatedTaskId(res.id));

          if (action.fromExisting) {
            actions.push(new taskActions.CopyCreated(res.id, action.parentId));
          }
        }
        return actions;
      }),
      catchError((err, caught) => caught)
    )
  );

  import$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.IMPORTED),
      map((action: taskActions.Imported) => action),
      withLatestFrom(
        this.store.select(fromStore.selectTaskCount),
        this.store.select(fromStore.selectAllPhasess)
      ),
      map(([action, count, allPhases]) => {
        let maxOrder = ProjectUtils.getMaxOrder(allPhases);
        let phasesToCreate: Phase[] = [];
        const tasks = action.payload.map((task, index) => {
          const existsInPhases = allPhases.find((p) => p.name === task.phase);
          const existsInCreation = phasesToCreate.find((c) => c.name === task.phase);

          if (!existsInPhases && !existsInCreation && task.phase !== '') {
            const newPhase = { name: task.phase, project: task.project, order: maxOrder };
            phasesToCreate = [...phasesToCreate, newPhase];
            maxOrder++;
          }
          return {
            ...task,
            order: count + index + 1,
            backlogOrder: count + index + 1,
            variants: ['default']
          } as Partial<Task>;
        });
        return { tasks, phasesToCreate, allPhases };
      }),
      switchMap(({ tasks, phasesToCreate, allPhases }) =>
        zip(of(tasks), this.phaseService.createAll(phasesToCreate), of(allPhases))
      ),
      map(([tasks, createdPhases, allPhases]) => {
        const phasesList: Partial<Phase>[] = [...createdPhases, ...allPhases];
        return tasks.map((task) => ({
          ...task,
          phase: phasesList.find((list) => list.name === task.phase)?.id || null
        }));
      }),
      mergeMap((actions) => actions),
      map((task) => taskActions.Created.fromTask(task, undefined, undefined, true))
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.DELETED),
      map((action: taskActions.Deleted) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([data, project]) =>
        this.taskService.delete(project.id, data.taskId).pipe(
          map(() => new taskActions.DeletedSuccess()),
          catchError(() => of(new taskActions.DeletedFail()))
        )
      )
    )
  );

  addVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.VARIANT_ADDED),
      map((action: taskActions.VariantAdded) => action),
      mergeMap((action) => this.getTask(action)),
      map(([action, result]) => {
        const payload = {
          variants: [...result.variants, action.payload]
        };
        return new taskActions.Updated(payload, action.taskId);
      })
    )
  );

  removeVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.VARIANT_REMOVED),
      map((action: taskActions.VariantRemoved) => action),
      mergeMap((action) => this.getTask(action)),
      map(([action, result]) => {
        const payload = {
          variants: result.variants.filter((variant) => variant !== action.payload)
        };
        return new taskActions.Updated(payload, action.taskId);
      })
    )
  );

  renameVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.VARIANT_RENAMED),
      map((action: taskActions.VariantRenamed) => action),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(fromStore.selectTasksByVariant(action.oldName)))
        )
      ),
      map(
        ([action, tasksToUpdate]) =>
          new taskActions.BatchUpdated(
            tasksToUpdate.map((t) => {
              const newVariants = [...t.variants];
              const index = newVariants.indexOf(action.oldName);
              newVariants[index] = action.newName;
              return { ...t, variants: newVariants };
            })
          )
      )
    )
  );

  copy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.COPIED),
      map((action: taskActions.Copied) => action),
      switchMap((action) =>
        this.getTask(action).pipe(
          withLatestFrom(this.store.select(fromStore.selectTaskCount)),
          map(([[, result], count]) => {
            const payload = {
              ...result,
              name: `${result.name || 'untitled'} (copy)`,
              backlogOrder: count + 1
            };
            delete payload.id;
            delete payload.justCreated;
            return taskActions.Created.fromTask(payload, result.id, result.order);
          }),
          catchError(() => of({ type: 'ERRR' } as Action))
        )
      )
    )
  );

  reorder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.REORDERED),
      map((action: taskActions.Reordered) => action),
      switchMap((action) => this.withTasks(action)),
      map(([action, tasks]) => {
        moveItemInArray(tasks, action.previousIndex, action.newIndex);
        const taskReorderPayloads = tasks.map((task, index) => {
          const payload: Partial<Task> = {
            id: task.id,
            order: index + 1
          };
          return payload;
        });

        return new taskActions.BatchUpdated(taskReorderPayloads);
      })
    )
  );

  updateBatch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.BATCH_UPDATED),
      map((action: taskActions.BatchUpdated) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([action, project]) => this.taskService.updateBatch(project.id, action.payload)),
      map(() => new taskActions.UpdatedSuccess())
    )
  );

  removeFromPhase: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.REMOVED_FROM_PHASE),
      map((action: taskActions.RemovedFromPhase) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      map(([action, project]) =>
        this.taskService.update({
          id: action.taskId,
          phase: null,
          project: project.id
        })
      ),
      map(() => {
        this.toastService.success('Task moved to backlog', 'Task removed');
        return new taskActions.UpdatedSuccess();
      })
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.UPDATED),
      map((action: taskActions.Updated) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([data, project]) =>
        this.taskService.update({ ...data.payload, project: project.id }).pipe(
          map(() => new taskActions.UpdatedSuccess()),
          catchError(() => of(new taskActions.UpdatedFail()))
        )
      )
    )
  );

  copyTaskEstimated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.COPY_TASK_ESTIMATES),
      map((action: taskActions.CopyTaskEstimates) => action),
      withLatestFrom(this.store.select(fromStore.selectProject), this.authService.getToken()),
      map(([payload, project, token]) => {
        const estimates = payload.estimates.map((estimate) => ({
          ...estimate,
          project: project.id,
          task: payload.targetTaskId,
          authorId: token.getPayload().email,
          technicalArea: estimate.technicalArea,
          changeTime: new Date()
        }));
        return estimates;
      }),
      switchMap((estimates) => this.estimateService.copyTaskEstimated(estimates)),
      map(() => new taskActions.CopyTaskEstimatesSuccess()),
      catchError(() => of(new taskActions.CopyTaskEstimatesFail()))
    )
  );

  private createTask(
    data,
    phaseId: string,
    maxOrder: number,
    project: Project,
    count: number
  ): Observable<{ id: string }> {
    const payload: Partial<Task> = {
      ...data.payload,
      project: project.id,
      backlogOrder: data.payload.backlogOrder || count + 1,
      phase: phaseId
    };
    if (!data.isImport) {
      payload.order = data.afterOrder !== undefined ? data.afterOrder + 1 : maxOrder + 1;
    }
    return this.taskService.add(payload);
  }

  private getTasksForOrderUpdate(tasks): Partial<Task>[] {
    return tasks.map((task) => ({
      order: task.order + 1,
      id: task.id
    }));
  }

  private getTasksByOrder<T>(
    phaseId: string,
    order: number,
    passthrough: T
  ): Observable<[T, Task[]]> {
    return this.store.select(fromStore.selectPhaseTasksWithOrderHigherThan(phaseId, order)).pipe(
      first(),
      map((tasks) => [passthrough, tasks])
    );
  }

  private getTask<T extends { taskId: string }>(action: T): Observable<[T, Task]> {
    return this.store.select(fromStore.selectTaskById(action.taskId)).pipe(
      first(),
      map((result) => [action, result])
    );
  }

  private withTasks<T extends { phaseId: string }>(action: T): Observable<[T, Task[]]> {
    return this.store.select(fromStore.selectTasksByPhaseId(action.phaseId, true)).pipe(
      first(),
      map((result) => [action, result])
    );
  }

  private getMaxTaskOrder<T>(
    action: T,
    phaseId: string
  ): Observable<[action: T, phaseId: string, maxOrder: number]> {
    return this.store.select(fromStore.selectMaxTaskOrderByPhase(phaseId)).pipe(
      first(),
      map((result) => [action, phaseId, result])
    );
  }

  private getFirstPhaseIdIfUndefined<T extends taskActions.Created>(
    action: T
  ): Observable<[action: T, phaseId: string]> {
    return this.store.select(fromStore.selectFirstPhaseId).pipe(
      map((result) => {
        const phaseId = action.phaseId !== undefined ? action.phaseId : result;
        return [action, phaseId];
      })
    );
  }
}
