import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PhaseService } from '@core/services/phase.service';
import { TechnicalAreaService } from '@core/services/technical-area.service';
import * as technicalAreaActions from '@app/store/actions/technical-area.actions';
import * as estimateActions from '@app/store/actions/estimate.actions';
import * as fteActions from '@app/store/actions/fte.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { handleDocumentChanges } from '@shared/operators';
import { TechnicalAreaDeleteOptions } from '@pageProjects/models/technical-area';

@Injectable()
export class TechnicalAreaEffects {
  constructor(
    private actions$: Actions,
    private technicalAreaService: TechnicalAreaService,
    private phaseService: PhaseService
  ) {}

  loadTechnicalAreas$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(technicalAreaActions.TechnicalAreaActionTypes.LOAD),
      map((action: technicalAreaActions.Load) => action),
      switchMap((loadAction) =>
        this.technicalAreaService
          .getAll(loadAction.projectId)
          .pipe(handleDocumentChanges('[TECHNICAL AREA API]'))
      )
    )
  );

  created$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(technicalAreaActions.TechnicalAreaActionTypes.CREATED),
      map((action: technicalAreaActions.Created) => action),
      switchMap((data) =>
        this.technicalAreaService.add(data.payload).pipe(
          map((res) => {
            if (res && res.technicalAreaId) {
              return new technicalAreaActions.CreatedSuccess();
            }
          }),
          catchError(() => of(new technicalAreaActions.CreatedFail()))
        )
      )
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(technicalAreaActions.TechnicalAreaActionTypes.DELETE),
      map((action: technicalAreaActions.Delete) => {
        switch (action.deleteData.option) {
          case TechnicalAreaDeleteOptions.DeleteAll:
            return new estimateActions.DeleteForArea(action.projectId, action.deleteData.areaId);

          case TechnicalAreaDeleteOptions.RedistributeEstimates:
            return new estimateActions.Redistribute(
              action.projectId,
              action.deleteData.areaId,
              action.deleteData.otherAreasIds
            );

          case TechnicalAreaDeleteOptions.MoveEstimatesToArea:
            return new estimateActions.MoveToTechnicalArea(
              action.projectId,
              action.deleteData.areaId,
              action.deleteData.moveToAreaId
            );

          default:
            throw Error('Unsupported Technical Area delete option.');
        }
      }),
      mergeMap((action: estimateActions.DeleteTechnicalArea) => [
        action,
        new fteActions.DeleteForArea(action.projectId, action.areaId),
        new technicalAreaActions.DeleteTechnicalAreaWithAssociation(action.projectId, action.areaId)
      ])
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(technicalAreaActions.TechnicalAreaActionTypes.UPDATED),
      map((action: technicalAreaActions.Updated) => action),
      switchMap((data) =>
        this.technicalAreaService.update(data.payload).pipe(
          map(() => new technicalAreaActions.UpdatedSuccess()),
          catchError(() => of(new technicalAreaActions.UpdatedFail()))
        )
      )
    )
  );

  deleteTechnicalAreaWithAssociation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(technicalAreaActions.TechnicalAreaActionTypes.DELETE_TECHNICAL_AREA_WITH_ASSOCIATION),
      map((action: technicalAreaActions.DeleteTechnicalAreaWithAssociation) => action),
      switchMap((action) =>
        this.technicalAreaService.delete(action.areaId, action.projectId).pipe(
          switchMap(() => this.phaseService.removeTechnicalAreaAssociation(action.projectId)),
          map(() => new technicalAreaActions.DeleteTechnicalAreaWithAssociationSuccess()),
          catchError(() => of(new technicalAreaActions.DeleteTechnicalAreaWithAssociationFail()))
        )
      )
    )
  );
}
