import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Phase } from '@pageProjects/models/phase';
import { PhaseActions, PhaseActionTypes } from '@app/store/actions/phases.actions';

const sortByOrder = (p1: Phase, p2: Phase): number => p1.order - p2.order;

export const phaseAdapter: EntityAdapter<Phase> = createEntityAdapter<Phase>({
  sortComparer: sortByOrder
});
export interface PhaseState extends EntityState<Phase> {
  createdPhaseId: string | null;
}

export const phaseInitialState: PhaseState = phaseAdapter.getInitialState({
  createdPhaseId: null
});

export const phasesReducer = (
  phaseState: PhaseState = phaseInitialState,
  action: PhaseActions
): PhaseState => {
  switch (action.type) {
    case PhaseActionTypes.ADDED:
      return phaseAdapter.addMany(action.payload, phaseState);
    case PhaseActionTypes.MODIFIED:
      return phaseAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        phaseState
      );
    case PhaseActionTypes.REMOVED:
      return phaseAdapter.removeMany(
        action.payload.map((a) => a.id),
        phaseState
      );
    case PhaseActionTypes.CLEAR: {
      return phaseAdapter.removeAll(phaseState);
    }
    case PhaseActionTypes.SET_CREATED_PHASE_ID:
      return {
        ...phaseState,
        createdPhaseId: action.createdPhaseId
      };
    case PhaseActionTypes.CLEAR_CREATED_PHASE_ID:
      return {
        ...phaseState,
        createdPhaseId: null
      };
    default:
      return phaseState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = phaseAdapter.getSelectors();
