import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromStore from '@app/store/selectors';
import * as estimateActions from '@app/store/actions/estimate.actions';
import * as estimateHistoryActions from '@app/store/actions/estimate-history.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProjectModuleState } from '@app/store/reducers';
import { Action, Store } from '@ngrx/store';
import { EstimateHistoryService } from '@core/services/estimate-history.service';
import { of } from 'rxjs';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class EstimateHistoryEffects {
  constructor(
    private actions$: Actions,
    private estimateHistoryService: EstimateHistoryService,
    private store: Store<ProjectModuleState>
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(estimateHistoryActions.EstimateHistoryActionTypes.LOAD),
      map((action: estimateHistoryActions.Load) => action),
      switchMap((loadAction) =>
        this.estimateHistoryService
          .getAll(loadAction.projectId)
          .pipe(handleDocumentChanges('[ESTIMATE HISTORY API]'))
      )
    )
  );

  $estimatesUpdate = createEffect(() =>
    this.actions$.pipe(
      ofType(estimateActions.EstimateActionTypes.UPDATED_SUCCESS),
      map((action: estimateActions.UpdatedSuccess) => action),
      mergeMap((action) =>
        of(action.id).pipe(
          withLatestFrom(this.store.select(fromStore.selectEstimateById(action.id)))
        )
      ),
      switchMap(([, estimate]) =>
        this.estimateHistoryService.add(estimate).pipe(
          map(() => new estimateHistoryActions.CreatedSuccess()),
          catchError(() => of(new estimateHistoryActions.CreatedFail()))
        )
      )
    )
  );
}
