import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import localeGB from '@angular/common/locales/en-GB';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AuthModule } from '@core/auth/auth.module';
import { FirebaseModule } from '@core/firebase/firebase.module';
import { NebularModule } from '@shared/nebular/nebular.module';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SidebarModule } from '@shared/sidebar/sidebar.module';
import { NbAclOptions, NbAccessChecker, NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { ProjectRoleProvider } from '@pageProjects/tools/project-role-provider';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@env/environment';
import { reducers } from '@app/store/reducers';
import { effects } from '@app/store';
import { AccessChecker } from '@core/services/nebular/access-checker';
import { NbDatepickerModule, NbDateService } from '@nebular/theme';
import { NbDateFnsDateModule, NbDateFnsDateService } from '@nebular/date-fns';
import { enGB } from 'date-fns/locale';

registerLocaleData(localePl);
registerLocaleData(localeGB);

const securityOptions: NbAclOptions = {
  accessControl: {
    guest: {
      view: []
    },
    viewer: {
      parent: 'guest',
      view: ['development', 'estimate', 'backlog', 'variant-overview']
    },
    contributor: {
      parent: 'viewer',
      view: ['discount-rates'],
      edit: ['risk-list', 'phase', 'task', 'general-assumptions', 'team-composition']
    },
    owner: {
      parent: 'contributor',
      edit: ['calculation-adjustment', 'rates', 'settings', 'access', 'leader-checklist']
    }
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    NebularModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FirebaseModule,
    AuthModule,
    StoreModule.forRoot({
      projects: reducers.projects,
      phases: reducers.phases,
      tasks: reducers.tasks,
      taskFiles: reducers.taskFiles,
      taskComments: reducers.taskComments,
      technicalAreas: reducers.technicalAreas,
      roles: reducers.roles,
      currencies: reducers.currencies,
      userInterface: reducers.userInterface,
      nbpCurrencies: reducers.nbpCurrencies,
      logs: reducers.logs,
      estimates: reducers.estimates,
      estimateHistory: reducers.estimateHistory,
      clipboard: reducers.clipboard,
      phaseSupportRoleConfig: reducers.phaseSupportRoleConfig,
      ftes: reducers.ftes,
      generalAssumptions: reducers.generalAssumptions,
      leaderChecklist: reducers.leaderChecklist,
      adminUsers: reducers.adminUsers,
      rolesAndRates: reducers.rolesAndRates,
      businessLines: reducers.businessLines,
      users: reducers.users,
      risks: reducers.risks
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 100,
      logOnly: environment.production
    }),
    EffectsModule.forRoot(effects),
    SidebarModule,
    NbSecurityModule.forRoot(securityOptions),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: enGB },
      formatOptions: { locale: enGB }
    }),
    NbDatepickerModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: NbRoleProvider, useClass: ProjectRoleProvider },
    { provide: NbAccessChecker, useClass: AccessChecker }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
