import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PhaseSupportRoleConfig } from '@pageProjects/models/phase-support-role-config';
import {
  PhaseSupportRoleConfigActions,
  PhaseSupportRoleConfigActionTypes
} from '@app/store/actions/phase-support-role-config.actions';
import { PhaseService } from '@core/services/phase.service';

export const configAdapter: EntityAdapter<PhaseSupportRoleConfig> =
  createEntityAdapter<PhaseSupportRoleConfig>();

export interface PhaseSupportRoleConfigState extends EntityState<PhaseSupportRoleConfig> {}

export const phaseSupportRoleConfigInitialState: PhaseSupportRoleConfigState =
  configAdapter.getInitialState({});

export const phaseSupportRoleConfigReducer = (
  state: PhaseSupportRoleConfigState = phaseSupportRoleConfigInitialState,
  actions: PhaseSupportRoleConfigActions
): PhaseSupportRoleConfigState => {
  switch (actions.type) {
    case PhaseSupportRoleConfigActionTypes.ADDED: {
      return configAdapter.addMany(
        actions.payload.map((p) => ({
          ...p,
          id: PhaseService.getSupportRoleConfigId(p)
        })),
        state
      );
    }
    case PhaseSupportRoleConfigActionTypes.MODIFIED:
      return configAdapter.updateMany(
        actions.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        state
      );
    case PhaseSupportRoleConfigActionTypes.REMOVED:
      return configAdapter.removeMany(
        actions.payload.map((a) => PhaseService.getSupportRoleConfigId(a)),
        state
      );
    case PhaseSupportRoleConfigActionTypes.CLEAR: {
      return configAdapter.removeAll(state);
    }
    default:
      return state;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = configAdapter.getSelectors();
