import { Action } from '@ngrx/store';
import { Task, TaskType } from '@pageProjects/models/task';
import { PayloadAction } from '@core/models/payload-action';
import { Estimate } from '@pageProjects/models/estimate';

export enum TaskActionTypes {
  LOAD = '[TASK] LOAD TASKS',
  ADDED = '[TASK API] ADDED',
  MODIFIED = '[TASK API] MODIFIED',
  REMOVED = '[TASK API] REMOVED',
  API_ERROR = '[TASK API] ERROR',
  CREATED = '[TASK] CREATED',
  COPIED = '[TASK] COPIED',
  COPY_CREATED = '[TASK] COPY_CREATED',
  IMPORTED = '[TASK] IMPORTED',
  CREATED_SUCCESS = '[TASK] CREATED SUCCESS',
  CREATED_FAIL = '[TASK] CREATED FAIL',
  DELETED = '[TASK] DELETED',
  REMOVED_FROM_PHASE = '[TASK] REMOVED FROM PHASE',
  DELETED_SUCCESS = '[TASK] DELETED SUCCESS',
  DELETED_FAIL = '[TASK] DELETED FAIL',
  UPDATED = '[TASK] UPDATED',
  REORDERED = '[TASK] REORDERED',
  BATCH_UPDATED = '[TASK] BATCH UPDATED',
  VARIANT_ADDED = '[TASK] VARIANT ADDED',
  VARIANT_REMOVED = '[TASK] VARIANT REMOVED',
  VARIANT_RENAMED = '[TASK] VARIANT RENAMED',
  UPDATED_SUCCESS = '[TASK] UPDATED SUCCESS',
  UPDATED_FAIL = '[TASK] UPDATED FAIL',
  CLEAR = '[TASK] CLEAR',
  SET_CREATED_TASK_ID = '[TASK] SET CREATED TASK ID',
  CLEAR_CREATED_TASK_ID = '[TASK] CLEAR CREATED TASK ID',
  COPY_TASK_ESTIMATES = '[TASK] COPY TASK ESTIMATED',
  COPY_TASK_ESTIMATES_SUCCESS = '[TASK] COPY TASK ESTIMATED SUCCESS',
  COPY_TASK_ESTIMATES_FAIL = '[TASK] COPY TASK ESTIMATED FAIL'
}

export class Load implements Action {
  readonly type = TaskActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = TaskActionTypes.ADDED;

  constructor(public payload: Task[]) {}
}

export class Modified implements Action {
  readonly type = TaskActionTypes.MODIFIED;

  constructor(public payload: Task[]) {}
}

export class Removed implements Action {
  readonly type = TaskActionTypes.REMOVED;

  constructor(public payload: Task[]) {}
}

export class ApiError implements Action {
  readonly type = TaskActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = TaskActionTypes.CREATED;
  payload: Partial<Task>;
  fromExisting = false;
  parentId: string;
  isImport: boolean;

  constructor(public phaseId?: string, public afterOrder?: number) {
    this.payload = {
      variants: ['default'],
      area: null,
      phase: phaseId,
      project: null
    };
  }

  static fromTask(
    payload: Partial<Task>,
    parentId?: string,
    afterOrder?: number,
    isImport?: boolean
  ): Created {
    const action = new Created(payload.phase, afterOrder);
    action.payload = payload;
    action.fromExisting = parentId !== undefined || parentId !== null;
    action.parentId = parentId;
    action.isImport = isImport;
    return action;
  }

  static forRiskMitigation(riskId: string, taskName: string, variants: string[]): Created {
    const action = new Created();
    action.payload.riskId = riskId;
    action.payload.name = taskName;
    action.payload.type = TaskType.Mitigation;
    action.payload.variants = [...variants];
    return action;
  }
}

export class Copied implements Action {
  readonly type = TaskActionTypes.COPIED;

  constructor(public taskId: string) {}
}

export class CopyCreated implements Action {
  readonly type = TaskActionTypes.COPY_CREATED;

  constructor(public taskId: string, public fromId: string) {}
}

export class Reordered implements Action {
  readonly type = TaskActionTypes.REORDERED;

  constructor(public previousIndex: number, public newIndex: number, public phaseId: string) {}
}

export class Imported implements PayloadAction {
  readonly type = TaskActionTypes.IMPORTED;

  constructor(public payload: Partial<Task>[]) {}
}

export class CreatedSuccess implements Action {
  readonly type = TaskActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = TaskActionTypes.CREATED_FAIL;

  constructor(public error) {}
}

export class Deleted implements Action {
  readonly type = TaskActionTypes.DELETED;

  constructor(public taskId: string) {}
}

export class DeletedSuccess implements Action {
  readonly type = TaskActionTypes.DELETED_SUCCESS;
}

export class DeletedFail implements Action {
  readonly type = TaskActionTypes.DELETED_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = TaskActionTypes.UPDATED;
  payload: Partial<Task>;

  constructor(payload: Partial<Task>, taskId: string) {
    this.payload = {
      ...payload,
      id: taskId
    };
  }
}

export class RemovedFromPhase implements Action {
  readonly type = TaskActionTypes.REMOVED_FROM_PHASE;

  constructor(public taskId: string) {}
}

export class BatchUpdated implements PayloadAction {
  readonly type = TaskActionTypes.BATCH_UPDATED;

  constructor(public payload: Partial<Task>[]) {}
}

export class VariantAdded implements PayloadAction, TaskAction {
  readonly type = TaskActionTypes.VARIANT_ADDED;

  constructor(public payload: string, public taskId: string) {}
}

export class VariantRemoved implements PayloadAction, TaskAction {
  readonly type = TaskActionTypes.VARIANT_REMOVED;

  constructor(public payload: string, public taskId: string) {}
}

export class VariantRenamed implements Action {
  readonly type = TaskActionTypes.VARIANT_RENAMED;

  constructor(public oldName: string, public newName: string) {}
}

export class UpdatedSuccess implements Action {
  readonly type = TaskActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = TaskActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = TaskActionTypes.CLEAR;
}

export class SetCreatedTaskId implements Action {
  readonly type = TaskActionTypes.SET_CREATED_TASK_ID;

  constructor(public createdTaskId: string) {}
}

export class ClearCreatedTaskId implements Action {
  readonly type = TaskActionTypes.CLEAR_CREATED_TASK_ID;
}

export class CopyTaskEstimates implements Action {
  readonly type = TaskActionTypes.COPY_TASK_ESTIMATES;

  constructor(public estimates: Estimate[], public targetTaskId: string) {}
}

export class CopyTaskEstimatesSuccess implements Action {
  readonly type = TaskActionTypes.COPY_TASK_ESTIMATES_SUCCESS;
}

export class CopyTaskEstimatesFail implements Action {
  readonly type = TaskActionTypes.COPY_TASK_ESTIMATES_FAIL;
}

export interface TaskAction {
  taskId: string;
}

export type TaskActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Deleted
  | DeletedSuccess
  | DeletedFail
  | Updated
  | BatchUpdated
  | UpdatedSuccess
  | UpdatedFail
  | Clear
  | SetCreatedTaskId
  | ClearCreatedTaskId
  | VariantAdded
  | VariantRemoved
  | VariantRenamed
  | Copied
  | Reordered
  | RemovedFromPhase
  | CopyCreated
  | CopyTaskEstimates
  | CopyTaskEstimatesSuccess
  | CopyTaskEstimatesFail;
