import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPhase from '@app/store/reducers/phase.reducer';

export const selectPhasesState = createFeatureSelector<fromPhase.PhaseState>('phases');

export const selectAllPhasess = createSelector(selectPhasesState, fromPhase.selectAll);

export const selectEnabledPhasess = createSelector(selectAllPhasess, (phases) =>
  phases.filter((a) => a.enabled ?? true)
);

export const selectSortedPhases = createSelector(selectAllPhasess, (phases) =>
  phases.sort((a, b) => a.order - b.order)
);

export const selectPhaseIds = createSelector(selectAllPhasess, (phases) => phases.map((p) => p.id));

export const selectSortedEnabledPhases = createSelector(selectEnabledPhasess, (phases) =>
  phases.sort((a, b) => a.order - b.order)
);

export const selectPhase = (phaseId: string) =>
  createSelector(selectPhasesState, (phasesState) => phasesState.entities[phaseId]);

export const selectCreatePhaseId = createSelector(
  selectPhasesState,
  (phasesState) => phasesState.createdPhaseId
);

export const selectFirstPhaseId = createSelector(selectSortedPhases, (phases) => phases[0]?.id);
