import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  TaskCommentActions,
  TaskCommentActionTypes
} from '@app/store/actions/task-comment.actions';
import { TaskComment } from '@pageProjects/models/task-comment';
import { ProjectUtils as PU } from '@pageProjects/tools/utils';

export const taskCommentAdapter: EntityAdapter<TaskComment> = createEntityAdapter<TaskComment>();

export interface TaskCommentState extends EntityState<TaskComment> {}

export const taskCommentInitialState: TaskCommentState = taskCommentAdapter.getInitialState({});

export const taskCommentsReducer = (
  state: TaskCommentState = taskCommentInitialState,
  action: TaskCommentActions
): TaskCommentState => {
  switch (action.type) {
    case TaskCommentActionTypes.ADDED: {
      const tasks = action.payload.map((t) => PU.convertDates<TaskComment>(t));
      return taskCommentAdapter.addMany(tasks, state);
    }
    case TaskCommentActionTypes.CLEAR:
      return taskCommentAdapter.removeAll(state);
    default:
      return state;
  }
};

export const { selectAll } = taskCommentAdapter.getSelectors();
