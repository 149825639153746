import { AngularFirestore, DocumentReference, SetOptions } from '@angular/fire/compat/firestore';
import { defer, Observable } from 'rxjs';

const MAX_OPERATIONS_PER_BATCH = 499;

export class BatchCollection {
  private readonly batchArray = [this.afs.firestore.batch()];
  private batchIndex = 0;
  private operationCounter = 0;

  constructor(private afs: AngularFirestore) {}

  update<T>(documentRef: DocumentReference<T>, data: any): void {
    this.batchArray[this.batchIndex].update(documentRef, data);
    this.checkBatch();
  }

  create<T>(documentRef: DocumentReference<T>, data: Partial<T>, options: SetOptions = null): void {
    if (options) {
      this.batchArray[this.batchIndex].set(documentRef, data, options);
    } else {
      this.batchArray[this.batchIndex].set(documentRef, data);
    }
    this.checkBatch();
  }

  delete<T>(documentRef: DocumentReference<T>): void {
    this.batchArray[this.batchIndex].delete(documentRef);
    this.checkBatch();
  }

  commit(): Observable<void> {
    return defer(() => this.commitAsync());
  }

  async commitAsync(): Promise<void> {
    try {
      for (const batch of this.batchArray) {
        await batch.commit();
      }
      return Promise.resolve();
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  private checkBatch() {
    this.operationCounter++;

    if (this.operationCounter === MAX_OPERATIONS_PER_BATCH) {
      this.batchArray.push(this.afs.firestore.batch());
      this.batchIndex++;
      this.operationCounter = 0;
    }
  }
}
