import { PayloadAction } from '@core/models/payload-action';
import { Action } from '@ngrx/store';
import { BusinessLine } from '@shared/models/business-line';

export enum BusinessLinesActionTypes {
  LOAD = '[BUSINESS LINE] LOAD BUSINESS LINES',
  ADDED = '[BUSINESS LINE] ADDED',
  MODIFIED = '[BUSINESS LINE] MODIFIED',
  REMOVED = '[BUSINESS LINE] REMOVED',
  API_ERROR = '[BUSINESS LINE] ERROR',
  CREATED = '[BUSINESS LINE] CREATED',
  CREATED_SUCCESS = '[BUSINESS LINE] CREATED SUCCESS',
  CREATED_FAIL = '[BUSINESS LINE] CREATED FAIL',
  DELETED = '[BUSINESS LINE] DELETED',
  DELETED_SUCCESS = '[BUSINESS LINE] DELETED SUCCESS',
  DELETED_FAIL = '[BUSINESS LINE] DELETED FAIL',
  UPDATED = '[BUSINESS LINE] UPDATED',
  UPDATED_SUCCESS = '[BUSINESS LINE] UPDATED SUCCESS',
  UPDATED_FAIL = '[BUSINESS LINE] UPDATED FAIL',
  CLEAR = '[BUSINESS LINE] CLEAR'
}

export class Load implements Action {
  readonly type = BusinessLinesActionTypes.LOAD;
  constructor() {}
}

export class Added implements Action {
  readonly type = BusinessLinesActionTypes.ADDED;
  constructor(public payload: BusinessLine[]) {}
}

export class Modified implements Action {
  readonly type = BusinessLinesActionTypes.MODIFIED;
  constructor(public payload: BusinessLine[]) {}
}

export class Removed implements Action {
  readonly type = BusinessLinesActionTypes.REMOVED;
  constructor(public payload: BusinessLine[]) {}
}

export class ApiError implements Action {
  readonly type = BusinessLinesActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = BusinessLinesActionTypes.CREATED;
  constructor(public payload: Partial<BusinessLine>) {}
}

export class CreatedSuccess implements Action {
  readonly type = BusinessLinesActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = BusinessLinesActionTypes.CREATED_FAIL;
}

export class Deleted implements Action {
  readonly type = BusinessLinesActionTypes.DELETED;
  constructor(public businessLineId: string) {}
}

export class DeletedSuccess implements Action {
  readonly type = BusinessLinesActionTypes.DELETED_SUCCESS;
}

export class DeletedFail implements Action {
  readonly type = BusinessLinesActionTypes.DELETED_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = BusinessLinesActionTypes.UPDATED;
  constructor(public payload: Partial<BusinessLine>) {}
}

export class UpdatedSuccess implements Action {
  readonly type = BusinessLinesActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = BusinessLinesActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = BusinessLinesActionTypes.CLEAR;
}

export type BussinesLinesActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Deleted
  | DeletedSuccess
  | DeletedFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear;
