import { NgModule } from '@angular/core';
import { CanActivateChild, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { HasAccessGuard } from '@core/guards/has-access.guard';

const routes: Routes = [
  {
    path: 'projects',
    loadChildren: () =>
      import('./pages/projects/projects.module').then((module) => module.ProjectsModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((module) => module.LoginModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then((module) => module.AdminModule),
    canLoad: [AuthGuard],
    canActivate: [HasAccessGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'access-denied',
    loadChildren: () =>
      import('./pages/access-denied/access-denied.module').then((module) => module.AccessDenied)
  },
  { path: '**', redirectTo: '/projects', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
