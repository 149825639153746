import { createSelector } from '@ngrx/store';
import * as fromStore from '@app/store/selectors/index';
import { CalculationLogic } from './calculation-helpers/calculation-logic';
import {
  selectAllEstimatesForCalculation,
  selectRiskEstimatesForCalculation
} from '@app/store/selectors/estimate.selectors';
import { selectAllFtes } from '@app/store/selectors/fte.selectors';
import { selectAllConfigs } from '@app/store/selectors/phase-support-role-config.selectors';
import { Currency } from '@pageProjects/models/currency';

export const selectTotalWorkdays = (variant: string, certainties: number[]) =>
  createSelector(selectPhasesAreasWorkdaysCustomCertainties(variant, certainties), (workmap) =>
    workmap.map((w) => ({
      certainty: w.certainty,
      workmap: [...w.workdays.entries()].map(([phase, workdays]) => ({
        phase,
        workdays: workdays.getWorkdays()
      }))
    }))
  );

export const selectSprint0DurationForPhase = (phaseId: string) =>
  createSelector(fromStore.selectAllPhasess, fromStore.selectProjectSprint0, (phases, sprint0) => {
    const phaseOrders = phases.filter((phase) => phase.id !== phaseId).map((phase) => phase.order);
    const isPhaseFirst =
      Math.min(...phaseOrders) > phases.find((phase) => phase.id === phaseId)?.order;
    return isPhaseFirst ? sprint0 : 0;
  });

export const getCertaintyAdjustment = (certainty, certaintyOverride: 'low' | 'high'): number => {
  const factor = 25;
  const maxCertainty = certainty + factor < 95 ? certainty + factor : 95;
  const minCertainty = certainty - factor > 5 ? certainty - factor : 5;
  const correctedCertainty = certaintyOverride === 'low' ? minCertainty : maxCertainty;
  return certaintyOverride === undefined ? certainty : correctedCertainty;
};

export const selectDurationForProject = (variant: string) =>
  createSelector(selectPhasesAreasWorkdays(variant, true), (workdaysMap) => {
    const durationMap: Map<string, number> = new Map<string, number>();
    for (const [phaseId, workdays] of workdaysMap) {
      durationMap.set(phaseId, workdays.getDuration());
    }

    return durationMap;
  });

export const selectCalculationLogic = createSelector(
  fromStore.selectSortedPhases,
  selectAllEstimatesForCalculation,
  fromStore.selectProjectMeetingOverhead,
  fromStore.selectSortedRoles,
  selectAllFtes,
  fromStore.selectProjectStartDate,
  fromStore.selectProjectSprint0,
  fromStore.selectProjectChargability,
  selectAllConfigs,
  fromStore.selectAllTechnicalAreas,
  (
    orderedPhases,
    estimates,
    overhead,
    roles,
    ftes,
    startDate,
    sprint0,
    chargability,
    phaseRoleConfigs,
    techAreas
  ) =>
    new CalculationLogic(
      orderedPhases,
      estimates,
      roles,
      ftes,
      overhead,
      startDate,
      sprint0,
      chargability,
      phaseRoleConfigs,
      techAreas
    )
);

export const selectCalculationLogicForRisks = createSelector(
  fromStore.selectSortedPhases,
  selectRiskEstimatesForCalculation,
  fromStore.selectProjectMeetingOverhead,
  fromStore.selectSortedRoles,
  selectAllFtes,
  fromStore.selectProjectStartDate,
  fromStore.selectProjectSprint0,
  fromStore.selectProjectChargability,
  selectAllConfigs,
  fromStore.selectAllTechnicalAreas,
  (
    orderedPhases,
    estimates,
    overhead,
    roles,
    ftes,
    startDate,
    sprint0,
    chargability,
    phaseRoleConfigs,
    techAreas
  ) =>
    new CalculationLogic(
      orderedPhases,
      estimates,
      roles,
      ftes,
      overhead,
      startDate,
      sprint0,
      chargability,
      phaseRoleConfigs,
      techAreas
    )
);

export const selectCalculationLogicEnabledPhases = createSelector(
  fromStore.selectSortedEnabledPhases,
  selectAllEstimatesForCalculation,
  fromStore.selectProjectMeetingOverhead,
  fromStore.selectSortedRoles,
  selectAllFtes,
  fromStore.selectProjectStartDate,
  fromStore.selectProjectSprint0,
  fromStore.selectProjectChargability,
  selectAllConfigs,
  fromStore.selectAllTechnicalAreas,
  (
    orderedPhases,
    estimates,
    overhead,
    roles,
    ftes,
    startDate,
    sprint0,
    chargability,
    phaseRoleConfigs,
    techAreas
  ) =>
    new CalculationLogic(
      orderedPhases,
      estimates,
      roles,
      ftes,
      overhead,
      startDate,
      sprint0,
      chargability,
      phaseRoleConfigs,
      techAreas
    )
);

export const selectPhasesAreasWorkdays = (variant: string, excludeEmpty?: boolean) =>
  createSelector(
    fromStore.selectCalculationLogicEnabledPhases,
    fromStore.selectProjectCertainty,
    (calculationLogic, certainty) => {
      calculationLogic.setFinalCertainty(certainty);
      return calculationLogic.getGroupedForAllPhases(variant, excludeEmpty);
    }
  );

const selectPhasesAreasWorkdaysCustomCertainties = (variant: string, certainties: number[]) =>
  createSelector(fromStore.selectCalculationLogicEnabledPhases, (calculationLogic) => {
    return certainties.map((certainty) => {
      calculationLogic.setFinalCertainty(certainty);
      return {
        certainty,
        workdays: calculationLogic.getGroupedForAllPhases(variant)
      };
    });
  });

export const getMultiplier = createSelector(
  fromStore.selectCombinedCurrencies,
  fromStore.selectProjectRateCurrency,
  (allCurrencies: Currency[], rateCurrency: string) => {
    let multiplier = 1;
    if (rateCurrency !== 'PLN') {
      const selectedRateCurrency = allCurrencies.find((c) => c.code === rateCurrency);
      if (selectedRateCurrency) {
        multiplier = selectedRateCurrency.overwritten
          ? selectedRateCurrency.customRate
          : selectedRateCurrency.mid;
      }
    }
    return multiplier;
  }
);

export const getRate = createSelector(
  fromStore.selectCombinedCurrencies,
  fromStore.selectProjectCurrency,
  (allCurrencies: Currency[], currency: string) => {
    if (currency === 'PLN') {
      return 1;
    }
    const configuredCurrency = allCurrencies.find((c) => c.code === currency);
    return configuredCurrency?.overwritten
      ? configuredCurrency?.customRate
      : configuredCurrency?.mid;
  }
);
