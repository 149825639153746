import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { Fte } from '@pageProjects/models/fte';
import { Collection } from '@shared/models/collection';
import { EMPTY, from, iif, Observable, of, zip } from 'rxjs';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { map, switchMap } from 'rxjs/operators';
import { BatchCollection } from '../../pages/projects/tools/batch-collection';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class FteService {
  constructor(private afs: AngularFirestore, private fns: AngularFireFunctions) {}

  private getCollection(projectId: string): AngularFirestoreCollection<Fte> {
    const queryFn = (ref) => ref.where('project', '==', projectId);
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Fte>(Collection.FTES, queryFn);
  }

  getAll(projectId: string): Observable<DocumentChangeAction<Fte>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  save(fte: Partial<Fte>): Observable<boolean> {
    let promise: Promise<void | DocumentReference<Fte>>;
    if (fte.id !== undefined && fte.id !== null) {
      promise = this.getCollection(fte.project).doc(fte.id).update(fte);
    } else {
      promise = this.getCollection(fte.project).add(fte as Fte);
    }
    const mappedPromise: Promise<boolean> = promise.then(() => true).catch(() => false);
    return from(mappedPromise);
  }

  copyFtes(
    projectId: string,
    sourcePhase: string,
    targetPhase: string,
    setCopying: () => void
  ): Observable<boolean> {
    const callable = this.fns.httpsCallable('fpert/copyFtes');
    return callable({ projectId, sourcePhase, targetPhase }).pipe(
      map(() => {
        setCopying();
        return true;
      })
    );
  }

  delete(projectId: string, fteId: string): Observable<boolean> {
    return from(
      this.getCollection(projectId)
        .doc(fteId)
        .delete()
        .then(() => true)
        .catch(() => false)
    );
  }

  deleteProjectFteForRole(
    projectId: string,
    roleId: string
  ): Observable<Observable<never> | boolean[]> {
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection(Collection.FTES, (ref) => ref.where('role', '==', roleId))
      .get()
      .pipe(
        switchMap((data: QuerySnapshot<Fte>) =>
          iif(() => data.empty, of(EMPTY), this.deleteFtes(data, projectId))
        )
      );
  }

  deleteFtesByArea(projectId: string, areaId: string): Observable<void> {
    const batchCollection = new BatchCollection(this.afs);
    const ftesForAreaQuerySnapshot = this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection(Collection.FTES, (ref) => ref.where('area', '==', areaId))
      .get();

    return ftesForAreaQuerySnapshot.pipe(
      map((ftes) => ftes.docs.map((fte) => batchCollection.delete(fte.ref))),
      switchMap(() => batchCollection.commit())
    );
  }

  deleteFtesByPhase(projectId: string, phaseId: string): Observable<void> {
    const batchCollection = new BatchCollection(this.afs);
    const ftesByPhaseQuerySnapshot = this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection(Collection.FTES, (ref) => ref.where('phase', '==', phaseId))
      .get();

    return ftesByPhaseQuerySnapshot.pipe(
      map((ftes) => ftes.docs.map((fte) => batchCollection.delete(fte.ref))),
      switchMap(() => batchCollection.commit())
    );
  }

  private deleteFtes(ftes: QuerySnapshot<Fte>, projectId): Observable<boolean[]> {
    const ftesDeleteRequests: Observable<boolean>[] = [];
    ftes.forEach((fte) => {
      ftesDeleteRequests.push(this.delete(projectId, fte.id));
    });

    return zip(...ftesDeleteRequests);
  }
}
