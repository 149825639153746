import { NgModule } from '@angular/core';
import { FpFirebaseAzureStrategy } from '@core/auth/firebase-azure.strategy';
import { NbAuthModule } from '@nebular/auth';
import { environment } from '@env/environment';
import { NbFirebaseGoogleStrategy } from '@nebular/firebase-auth';

@NgModule({
  imports: [
    NbAuthModule.forRoot({
      strategies: [
        FpFirebaseAzureStrategy.setup({
          name: 'fp-ad',
          customParameters: {
            domain_hint: 'future-processing.com',
            tenant: environment.azureTenantId
          },
          scopes: ['profile', 'openid', 'email']
        }),
        NbFirebaseGoogleStrategy.setup({
          name: 'google-firebase'
        })
      ]
    })
  ],
  providers: [FpFirebaseAzureStrategy]
})
export class AuthModule {}
