import { Action } from '@ngrx/store';
import { SelectedPhase } from '@pageProjects/models/selected-phase';
import { SelectedTask } from '@pageProjects/models/selected-task';

export enum UserInterfaceActionTypes {
  SELECT_TASK = '[UI] SELECT TASK',
  UNSELECT_TASK = '[UI] UNSELECT TASK',
  PHASE_TASKS_SELECTION_REQUESTED = '[UI] PHASE TASKS SELECTION REQUESTED',
  ALL_TASKS_SELECTED = '[UI] ALL TASKS SELECTED',
  PHASE_TASKS_SELECTED = '[UI] PHASE TASKS SELECTED',
  UNSELECT_PHASE_TASKS = '[UI] UNSELECT PHSAES TASKS',
  UNSELECT_ALL = '[UI] UNSELECT ALL',
  SET_PHASES_DATA = '[UI] SET PHASES DATA',
  REMOVE_PHASES_DATA = '[UI] REMOVE PHASES DATA',
  SELECT_TASK_IN_BACKLOG = '[UI] SELECT TASK IN BACKLOG',
  UNSELECT_TASK_IN_BACKLOG = '[UI] UNSELECT TASK IN BACKLOG',
  SELECT_ALL_TASKS_IN_BACKLOG = '[UI] SELECT ALL TASKS IN BACKLOG',
  UNSELECT_ALL_TASKS_IN_BACKLOG = '[UI] UNSELECT ALL TASKS IN BACKLOG'
}

export class SelectTask implements Action {
  readonly type = UserInterfaceActionTypes.SELECT_TASK;

  constructor(public data: SelectedTask) {}
}

export class UnselectTask implements Action {
  readonly type = UserInterfaceActionTypes.UNSELECT_TASK;

  constructor(public data: SelectedTask) {}
}

export class PhaseTasksSelectionRequested implements Action {
  readonly type = UserInterfaceActionTypes.PHASE_TASKS_SELECTION_REQUESTED;

  constructor(public phaseId: string) {}
}

export class PhaseTasksSelected implements Action {
  readonly type = UserInterfaceActionTypes.PHASE_TASKS_SELECTED;

  constructor(public selectedTasks: SelectedTask[]) {}
}

export class UnselectPhaseTasks implements Action {
  readonly type = UserInterfaceActionTypes.UNSELECT_PHASE_TASKS;

  constructor(public phase: string) {}
}

export class UnselectAll implements Action {
  readonly type = UserInterfaceActionTypes.UNSELECT_ALL;
}

export class AllTasksSelected implements Action {
  readonly type = UserInterfaceActionTypes.ALL_TASKS_SELECTED;
}

export class SetPhasesData implements Action {
  readonly type = UserInterfaceActionTypes.SET_PHASES_DATA;

  constructor(public data: SelectedPhase) {}
}

export class RemovePhasesData implements Action {
  readonly type = UserInterfaceActionTypes.REMOVE_PHASES_DATA;

  constructor(public phase: string) {}
}

export class SelectTaskInBacklog implements Action {
  readonly type = UserInterfaceActionTypes.SELECT_TASK_IN_BACKLOG;

  constructor(public data: SelectedTask) {}
}

export class UnselectTaskInBacklog implements Action {
  readonly type = UserInterfaceActionTypes.UNSELECT_TASK_IN_BACKLOG;

  constructor(public data: SelectedTask) {}
}

export class SelectAllTasksInBacklog implements Action {
  readonly type = UserInterfaceActionTypes.SELECT_ALL_TASKS_IN_BACKLOG;
}

export class UnselectAllTasksInBacklog implements Action {
  readonly type = UserInterfaceActionTypes.UNSELECT_ALL_TASKS_IN_BACKLOG;
}

export type UserInterfaceActions =
  | SelectTask
  | UnselectTask
  | PhaseTasksSelectionRequested
  | UnselectPhaseTasks
  | UnselectAll
  | SetPhasesData
  | RemovePhasesData
  | PhaseTasksSelected
  | AllTasksSelected
  | SelectTaskInBacklog
  | UnselectTaskInBacklog
  | SelectAllTasksInBacklog
  | UnselectAllTasksInBacklog;
