import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Collection } from '@shared/models/collection';
import { from, Observable, of, zip } from 'rxjs';
import { FileToUpload, TaskFile, UnhandledFile } from '@pageProjects/models/task-file';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { ImagesMimeTypes } from '@core/consts/allowed-mime-types';

@Injectable({
  providedIn: 'root'
})
export class TaskFilesService {
  constructor(private readonly afs: AngularFirestore, private storage: AngularFireStorage) {}

  private getCollection(projectId: string): AngularFirestoreCollection<TaskFile> {
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<TaskFile>(Collection.TASK_FILES);
  }

  getAll(projectId: string): Observable<DocumentChangeAction<TaskFile>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  async upload(
    project: string,
    task: string,
    files: FileToUpload[]
  ): Promise<Observable<[boolean, UnhandledFile[]]>> {
    const uploads: TaskFile[] = [];
    const unhandledFiles: UnhandledFile[] = [];

    for (const element of files) {
      const isImage = element.isImage;
      const path = this.getFilePath(project, task, element.file.name, isImage);
      const uploadTask = this.storage.upload(path, element.file);
      let storage: UploadTaskSnapshot;
      try {
        storage = await uploadTask;
        if (storage.state === 'success') {
          uploads.push({
            project,
            task,
            isImage,
            path,
            mimeType: element.file.type,
            name: element.file.name,
            downloadUrl: await storage.ref.getDownloadURL()
          });
        }
      } catch (e) {
        unhandledFiles.push({
          guid: element.guid,
          name: element.file.name,
          type: isImage ? 'images' : 'files'
        });
        // eslint-disable-next-line no-empty
      } finally {
      }
    }
    return zip(this.batchAdd(uploads), of(unhandledFiles));
  }

  delete(
    project: string,
    id: string,
    downloadUrl: string,
    shouldRemoveFile: boolean
  ): Observable<string> {
    return from(
      this.getCollection(project)
        .doc(id)
        .delete()
        .catch((e) => e)
        .then(() => {
          if (shouldRemoveFile) {
            this.storage.refFromURL(downloadUrl).delete();
          }
          return id;
        })
    );
  }

  copyFiles(task: string, taskFiles: TaskFile[]): Observable<boolean> {
    if (!taskFiles.length) {
      return of(false);
    }

    const batch = this.afs.firestore.batch();
    for (const taskFile of taskFiles) {
      const newTaskFile = { ...taskFile, task };
      const ref = this.getCollection(taskFile.project).doc().ref;
      batch.set(ref, newTaskFile);
    }

    return from(
      batch
        .commit()
        .then(() => true)
        .catch(() => false)
    );
  }

  private getFilePath(project: string, task: string, name: string, isImage: boolean): string {
    const fileName = `${Date.now()}_${name}`;
    const directory = isImage ? 'images' : 'files';
    return `project/${project}/task/${task}/${directory}/${fileName}`;
  }

  private batchAdd(taskFiles: TaskFile[]): Observable<boolean> {
    const batch = this.afs.firestore.batch();

    if (taskFiles.length > 0) {
      taskFiles.forEach((taskFile) => {
        const ref = this.getCollection(taskFile.project).doc().ref;
        batch.set(ref, taskFile);
      });

      return from(
        batch
          .commit()
          .then(() => true)
          .catch(() => false)
      );
    }

    return of(false);
  }
}
