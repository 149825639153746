import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { Collection } from '@shared/models/collection';
import { TechnicalArea } from '@pageProjects/models/technical-area';
import { EMPTY, from, iif, Observable, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TechnicalAreaService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(projectId: string): AngularFirestoreCollection<TechnicalArea> {
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<TechnicalArea>(Collection.TECHNICAL_AREAS);
  }

  add(technicalArea: Partial<TechnicalArea>): Observable<{ technicalAreaId: string }> {
    return from(
      this.getCollection(technicalArea.project)
        .add({ ...technicalArea, created: technicalArea.created || new Date() } as TechnicalArea)
        .catch((e) => e)
        .then((docRef: DocumentReference<TechnicalArea>) => ({ technicalAreaId: docRef.id }))
    );
  }

  update(technicalArea: Partial<TechnicalArea>): Observable<string> {
    const updatedTechnicalAreaData = { ...technicalArea };
    delete updatedTechnicalAreaData.id;

    return from(
      this.getCollection(technicalArea.project)
        .doc<TechnicalArea>(`${technicalArea.id}`)
        .update({
          ...updatedTechnicalAreaData
        })
        .catch((e) => e)
        .then(() => 'technicalArea updated')
    );
  }

  delete(technicalAreaId: string, projectId: string): Observable<string[]> {
    return from(
      this.getCollection(projectId)
        .doc(technicalAreaId)
        .delete()
        .catch((e) => e)
        .then(() => [technicalAreaId, projectId])
    );
  }

  getAll(projectId: string): Observable<DocumentChangeAction<TechnicalArea>[]> {
    return this.getCollectionFilterByProject(projectId).stateChanges();
  }

  removeProjectTechnicalAreas(projectId: string): Observable<any[] | Observable<never>> {
    return this.getCollectionFilterByProject(projectId)
      .get()
      .pipe(
        switchMap((technicalAreas) =>
          iif(
            () => technicalAreas.empty,
            of(EMPTY),
            this.removeTechnicalAreas(technicalAreas, projectId)
          )
        )
      );
  }

  private getCollectionFilterByProject(
    projectId: string
  ): AngularFirestoreCollection<TechnicalArea> {
    return this.getCollection(projectId);
  }

  private removeTechnicalAreas(
    technicalAreas: QuerySnapshot<TechnicalArea>,
    projectId: string
  ): Observable<string[][]> {
    const technicalAreaDeleteRequests: Observable<string[]>[] = [];
    technicalAreas.docs.forEach((technicalArea) => {
      technicalAreaDeleteRequests.push(this.delete(technicalArea.id, projectId));
    });
    return zip(...technicalAreaDeleteRequests);
  }
}
