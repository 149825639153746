import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh.pipe(
      tap((authenticated) => {
        if (!authenticated) {
          const config = {
            queryParams: {
              return: state.url
            }
          };
          this.router.navigate(['login'], config);
        }
      })
    );
  }

  canLoad(): boolean {
    return true;
  }
}
