import { Injectable } from '@angular/core';
import { ProjectModuleState } from '@app/store/reducers';
import { FteService } from '@core/services/fte.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fteActions from '@app/store/actions/fte.actions';
import { of } from 'rxjs';
import * as fromStore from '@app/store/selectors';
import { handleDocumentChanges } from '@shared/operators';
import { NbToastrService } from '@nebular/theme';

@Injectable()
export class FteEffects {
  constructor(
    private actions$: Actions,
    private fteService: FteService,
    private store: Store<ProjectModuleState>,
    private toastService: NbToastrService
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fteActions.FteActionTypes.LOAD),
      map((action: fteActions.Load) => action),
      switchMap((loadAction) =>
        this.fteService.getAll(loadAction.projectId).pipe(handleDocumentChanges('[FTE API]'))
      )
    )
  );

  saved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fteActions.FteActionTypes.SAVED),
      map((action: fteActions.Saved) => action),
      switchMap((data) =>
        this.fteService.save(data.payload).pipe(
          map(() => new fteActions.SavedSuccess()),
          catchError(() => of(new fteActions.SavedFail()))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fteActions.FteActionTypes.DELETED),
      map((action: fteActions.Deleted) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([data, project]) =>
        this.fteService.delete(project.id, data.id).pipe(
          map(() => new fteActions.DeletedSuccess()),
          catchError(() => of(new fteActions.DeletedFail()))
        )
      )
    )
  );

  deleteForRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fteActions.FteActionTypes.DELETE_FOR_ROLE),
      map((action: fteActions.DeleteForRole) => action),
      switchMap((data) =>
        this.fteService.deleteProjectFteForRole(data.projectId, data.roleId).pipe(
          map(() => new fteActions.DeleteForRoleSuccess()),
          catchError(() => of(new fteActions.DeleteForRoleFail()))
        )
      )
    )
  );

  copyFtes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fteActions.FteActionTypes.COPY),
      map((action: fteActions.Copy) => ({
        action,
        toastr: this.toastService.info('Copying Ftes', 'FTE', { duration: 0 })
      })),
      switchMap(({ action, toastr }) =>
        this.fteService
          .copyFtes(action.projectId, action.sourcePhase, action.targetPhase, action.setCopying)
          .pipe(
            map(() => {
              toastr.close();
              this.toastService.success('FTE copied', 'FTE');
              return new fteActions.CopySuccess();
            }),
            catchError(() => {
              toastr.close();
              this.toastService.danger('FTE copy failed', 'FTE');
              return of(new fteActions.CopyFail());
            })
          )
      )
    )
  );

  deleteForArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fteActions.FteActionTypes.DELETE_FOR_AREA),
      map((action: fteActions.DeleteForArea) => action),
      switchMap((action) =>
        this.fteService.deleteFtesByArea(action.projectId, action.areaId).pipe(
          map(() => new fteActions.DeleteForAreaSuccess()),
          catchError(() => of(new fteActions.DeleteForAreaFail()))
        )
      )
    )
  );
}
