export interface Task {
  id?: string;
  project: string;
  phase: string;
  phaseName: string;
  order?: number;
  backlogOrder?: number;
  area?: string;
  variants?: string[];
  name?: string;
  description?: string;
  justCreated?: boolean;
  unbalanced?: boolean;
  selected?: boolean;
  assumptions: string;
  implementationDetails: string;
  riskId?: string;
  type?: TaskType;
}

export enum TaskType {
  Regular = 1,
  Mitigation = 2
}

export interface MitigationTaskCreate {
  riskId: string;
  taskName: string;
}
