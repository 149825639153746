import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CurrencyService } from '@core/services/currency.service';
import * as currencyActions from '@app/store/actions/currency.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

@Injectable()
export class CurrencyEffects {
  constructor(private actions$: Actions, private currencyService: CurrencyService) {}

  loadNbp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(currencyActions.CurrencyActionTypes.LOAD),
      map((action: currencyActions.Load) => action),
      switchMap(() =>
        this.currencyService.currencies().pipe(
          map((result) =>
            result.map((c) => ({
              ...c,
              id: c.code
            }))
          ),
          map((currencies) => ({
            type: currencyActions.CurrencyActionTypes.LOAD_NBP,
            payload: currencies
          }))
        )
      )
    )
  );

  loadCurrencies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(currencyActions.CurrencyActionTypes.LOAD),
      map((action: currencyActions.Load) => action),
      switchMap((loadAction) =>
        this.currencyService.getAll(loadAction.projectId).pipe(
          mergeMap((actions) => actions),
          map((action) => ({
            type: `[CURRENCY API] ${action.type}`.toUpperCase(),
            payload: {
              ...action.payload.doc.data(),
              id: action.payload.doc.id
            }
          })),
          catchError(() => of(new currencyActions.ApiError()))
        )
      )
    )
  );

  created$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(currencyActions.CurrencyActionTypes.CREATED),
      map((action: currencyActions.Created) => action),
      switchMap((data) =>
        this.currencyService.add(data.payload).pipe(
          map((res) => {
            if (res && res.currencyId) {
              return new currencyActions.CreatedSuccess();
            }
          }),
          catchError(() => of(new currencyActions.CreatedFail()))
        )
      )
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(currencyActions.CurrencyActionTypes.DELETED),
      map((action: currencyActions.Deleted) => action),
      switchMap((data) =>
        this.currencyService.delete(data.projectId, data.currencyId).pipe(
          map(() => new currencyActions.DeletedSuccess()),
          catchError(() => of(new currencyActions.DeletedFail()))
        )
      )
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(currencyActions.CurrencyActionTypes.UPDATED),
      map((action: currencyActions.Updated) => action),
      switchMap((data) =>
        this.currencyService.update(data.payload).pipe(
          map(() => new currencyActions.UpdatedSuccess()),
          catchError(() => of(new currencyActions.UpdatedFail()))
        )
      )
    )
  );
}
