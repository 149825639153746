import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProjectModuleState } from '@app/store/reducers';
import * as fromStore from '@app/store/selectors';
import * as adminUserActions from '@app/store/actions/admin-user.actions';
import { AuthService } from '@core/services/auth/auth.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasAccessGuard implements CanActivate {
  constructor(
    private store: Store<ProjectModuleState>,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      this.store
        .select(fromStore.selectAdminUsers)
        .pipe(
          tap((adminUserState) => {
            if (adminUserState.isLoaded) {
              const result = adminUserState.ids?.includes(this.authService.id);
              if (!result) {
                this.router.navigate(['projects']);
                resolve(false);
              }
              resolve(true);
            }
          })
        )
        .subscribe();
    });
  }
}
