import { Role } from '@pageProjects/models/role';
import { Fte } from '@pageProjects/models/fte';
import { Duration } from './duration';
import { AreaFteEffort } from '@app/store/selectors/calculation-helpers/area-fte-effort';
import { PhaseSupportRoleConfig } from '@pageProjects/models/phase-support-role-config';
import { TechnicalArea } from '@pageProjects/models/technical-area';
import { TaskType } from '@pageProjects/models/task';

export class RevenueMap {
  convertedTotal: number;

  constructor(public cost: number = 0, public fpCost: number = 0, converted?: number) {
    this.convertedTotal = converted;
  }

  get gpm(): number {
    if (this.cost > 0) {
      return ((this.cost - this.fpCost) / this.cost) * 100;
    }
    return 0;
  }

  added(map: RevenueMap, rate?: number): RevenueMap {
    const cost = this.cost + map.cost;
    const fpCost = this.fpCost + map.fpCost;
    const converted = rate ? cost / rate : 0;
    return new RevenueMap(cost, fpCost, converted);
  }

  converted(rate: number): RevenueMap {
    return new RevenueMap(this.cost, this.fpCost, this.cost / rate);
  }
}

export type FteRoleType = 'support' | 'productive';

/**
 *
 */
export class AreaEffort {
  constructor(
    public readonly area: string,
    private readonly areaObject: TechnicalArea,
    public readonly efforts: AreaFteEffort[],
    public readonly durationByTask: Map<string, Duration>,
    public readonly durationByType: Map<TaskType, Duration>,
    public readonly duration: Duration,
    public readonly coveredByFtes: boolean
  ) {}

  totalWorkdays(): number {
    return this.efforts.reduce((a, b) => a + b.realWorkdays(), 0);
  }

  totalWorkdaysWithoutMeetings(): number {
    return this.efforts.reduce((a, b) => a + b.rawWorkdays(), 0);
  }

  cost(multiplier: number): number {
    if (this.areaObject?.hideCost) {
      return 0;
    }

    const durationDays = this.duration.getInWorkingDays();

    return (
      this.efforts.map((ae) => ae.dayCost(multiplier)).reduce((p, c) => p + c, 0) * durationDays
    );
  }

  realCost(maxDuration: Duration, multiplier: number): number {
    if (this.areaObject?.hideCost) {
      return 0;
    }

    const durationDays = maxDuration.getInWorkingFullDays();

    return (
      this.efforts.map((ae) => ae.dayCost(multiplier)).reduce((p, c) => p + c, 0) * durationDays
    );
  }

  revenueMapByBusinessLine(maxDuration: Duration, multiplier: number): Map<string, RevenueMap> {
    const durationInMonths = maxDuration.getInMonths(false);
    const durationDays = maxDuration.getInWorkingFullDays();
    const initialValue = new Map<string, RevenueMap>();
    if (this.areaObject?.hideCost) {
      return initialValue;
    }
    const lines = this.efforts.map((ae) => ae.businessLine());
    lines.forEach((line) => initialValue.set(line, new RevenueMap()));

    return this.efforts.reduce((p, c) => {
      const currentRevenueMap = p.get(c.businessLine());
      currentRevenueMap.cost += c.dayCost(multiplier) * durationDays;
      currentRevenueMap.fpCost += c.fpMonthCost() * durationInMonths;
      return p;
    }, initialValue);
  }

  revenueMapByRoleType(maxDuration: Duration, multiplier: number): Map<FteRoleType, RevenueMap> {
    const durationInMonths = maxDuration.getInMonths(false);
    const durationDays = maxDuration.getInWorkingFullDays();
    const initialValue = new Map<FteRoleType, RevenueMap>();
    initialValue.set('support', new RevenueMap());
    initialValue.set('productive', new RevenueMap());
    if (this.areaObject?.hideCost) {
      return initialValue;
    }

    return this.efforts.reduce((p, c) => {
      const currentRevenueMap = p.get(c.isRoleOfTypeSupport() ? 'support' : 'productive');
      currentRevenueMap.cost += c.dayCost(multiplier) * durationDays;
      currentRevenueMap.fpCost += c.fpMonthCost() * durationInMonths;
      return p;
    }, initialValue);
  }

  fpCost(maxDuration: Duration, monthlyOverhead: number = 0): number {
    if (this.areaObject?.hideCost) {
      return 0;
    }
    const durationInMonths = maxDuration.getInMonths(false);
    return (
      this.efforts.map((ae) => ae.fpMonthCost(monthlyOverhead)).reduce((p, c) => p + c, 0) *
      durationInMonths
    );
  }
}

export class AreaFteGroup {
  private readonly ftes: Fte[];

  constructor(
    private readonly area: string,
    private readonly areaObject: TechnicalArea,
    private readonly workdaysByTask: Map<string, number>,
    private readonly workdaysByType: Map<TaskType, number>,
    private readonly workdays: number,
    private readonly roles: Role[],
    private readonly overhead: number,
    ftes: Fte[],
    private readonly phaseRoleConfigs: PhaseSupportRoleConfig[],
    private readonly totalAreas: number,
    private readonly chargability: number,
    private readonly sprint0Length: number,
    private readonly startDate: Date
  ) {
    this.ftes = ftes.filter((fte) => {
      let isRoleUniform = false;
      if (phaseRoleConfigs && typeof phaseRoleConfigs.find === 'function') {
        isRoleUniform = phaseRoleConfigs?.find((prc) => prc.role === fte.role)?.isUniform;
      }
      const excludeBasedOnArea =
        (isRoleUniform && fte.area === null) || (!isRoleUniform && fte.area === area);
      return excludeBasedOnArea && this.roles.findIndex((role) => role.id === fte.role) > -1;
    });
  }

  reduced(): AreaEffort {
    const rawDuration = this.calculateProductiveDuration(this.getWorkdays(false));
    const totalDuration = this.calculateProductiveDuration(this.getWorkdays(true));
    const duration = new Duration(
      rawDuration,
      totalDuration,
      this.sprint0Length,
      this.chargability,
      this.startDate
    );

    const durationByType = new Map<TaskType, Duration>();

    for (const item of this.workdaysByType) {
      const typeRawDuration = this.calculateProductiveDuration(item[1]);
      const typeTotalDuration = this.calculateProductiveDuration(
        this.calculateTotalWorkdays(item[1])
      );

      durationByType.set(
        item[0],
        new Duration(
          typeRawDuration,
          typeTotalDuration,
          this.sprint0Length,
          this.chargability,
          this.startDate
        )
      );
    }

    const durationByTask = new Map<string, Duration>();

    for (const item of this.workdaysByTask) {
      const typeRawDuration = this.calculateProductiveDuration(item[1]);
      const typeTotalDuration = this.calculateProductiveDuration(
        this.calculateTotalWorkdays(item[1])
      );

      durationByTask.set(
        item[0],
        new Duration(
          typeRawDuration,
          typeTotalDuration,
          this.sprint0Length,
          this.chargability,
          this.startDate
        )
      );
    }

    const productiveEfforts = this.calculateProductiveEffort();
    const supportEffort = this.calculateSupportEffort(rawDuration, totalDuration);
    const efforts = [...productiveEfforts, ...supportEffort];

    const ftesSummary = this.ftes.reduce((prev, curr) => prev + (curr.fte || 0), 0);
    const coveredByFtes = this.workdays > 0 ? ftesSummary > 0 : true;

    return new AreaEffort(
      this.area,
      this.areaObject,
      efforts,
      durationByTask,
      durationByType,
      duration,
      coveredByFtes
    );
  }

  calculateProductiveDuration(workdays: number): number {
    const productiveFteSum = this.getProductiveFteSum();

    if (productiveFteSum === 0) {
      return 0;
    }

    return workdays / productiveFteSum;
  }

  calculateSupportEffort(duration: number, totalDuration: number): AreaFteEffort[] {
    return this.getSupportFtes().map((fte) => {
      const role = this.getRoleById(fte.role);
      let processedFtes = fte.fte;

      if (this.phaseRoleConfigs && typeof this.phaseRoleConfigs.find === 'function') {
        const phaseSupportRoleConfig = this.phaseRoleConfigs.find((prc) => prc.role === fte.role);
        if (phaseSupportRoleConfig !== undefined && phaseSupportRoleConfig.isUniform) {
          processedFtes = processedFtes / this.totalAreas;
        }
      }

      const workdays = duration * processedFtes;
      const totalWorkdays = (totalDuration + this.sprint0Length) * processedFtes;

      return new AreaFteEffort(
        this.area,
        role,
        fte.level,
        workdays,
        totalWorkdays,
        processedFtes,
        this.areaObject
      );
    });
  }

  calculateProductiveEffort(): AreaFteEffort[] {
    const productiveFtes = this.getProductiveFtes();
    const totalAreaFtes = this.getProductiveFteSum();
    return productiveFtes.map((fte) => {
      const roleLevelRatio = fte.fte / totalAreaFtes;
      const sprint0Effort = fte.fte * this.sprint0Length;
      const currentRoleWorkdays = this.workdays * roleLevelRatio + sprint0Effort;
      const totalWorkdays =
        this.calculateTotalWorkdays(this.workdays) * roleLevelRatio + sprint0Effort;
      const role = this.getRoleById(fte.role);
      return new AreaFteEffort(
        this.area,
        role,
        fte.level,
        currentRoleWorkdays,
        totalWorkdays,
        fte.fte,
        this.areaObject
      );
    });
  }

  getWorkdays(total: boolean = true): number {
    return total ? this.calculateTotalWorkdays(this.workdays) : this.workdays;
  }

  private getProductiveFteSum(): number {
    return this.getProductiveFtes().reduce((a, b) => a + b.fte, 0);
  }

  private calculateTotalWorkdays(workdays: number): number {
    return workdays * (1 + this.overhead / 100);
  }

  private getProductiveFtes(): Fte[] {
    return this.ftes.filter((fte) => {
      const find = this.roles.find((role) => role.id === fte.role);
      return !find.support && fte.fte > 0;
    });
  }

  private getSupportFtes(): Fte[] {
    return this.ftes.filter((fte) => {
      const find = this.roles.find((role) => role.id === fte.role);
      return find.support && fte.fte > 0;
    });
  }

  private getRoleById(roleId: string): Role {
    return this.roles.find((role) => role.id === roleId);
  }
}
