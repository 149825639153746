import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromEstimateHistory from '@app/store/reducers/estimate-history.reducer';
import { selectAllUsers } from '@app/store';

export const selectEstimateHistoryState =
  createFeatureSelector<fromEstimateHistory.EstimateHistoryState>('estimateHistory');

const selectAllEstimateHistory = createSelector(
  selectEstimateHistoryState,
  fromEstimateHistory.selectAll
);

export const selectEstimateHistoryByTask = (taskId: string) =>
  createSelector(selectAllEstimateHistory, selectAllUsers, (estimateHistory, users) =>
    estimateHistory
      .filter((eH) => eH.task === taskId)
      .map((eH) => ({
        ...eH,
        userName: users.find((u) => u.id === eH.authorId)?.name
      }))
      .sort((a, b) => new Date(a.changeTime).getTime() - new Date(b.changeTime).getTime())
  );
