import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskFile, UnhandledFile } from '@pageProjects/models/task-file';
import { TaskFilesActions, TaskFilesActionTypes } from '@app/store/actions/task-files.actions';

export const taskFilesAdapter: EntityAdapter<TaskFile> = createEntityAdapter<TaskFile>();

export interface TaskFilesState extends EntityState<TaskFile> {
  unhandledFiles: UnhandledFile[];
}

export const taskFilesInitialState: TaskFilesState = taskFilesAdapter.getInitialState({
  unhandledFiles: []
});

export const taskFilesReducer = (
  taskFilesState: TaskFilesState = taskFilesInitialState,
  action: TaskFilesActions
): TaskFilesState => {
  switch (action.type) {
    case TaskFilesActionTypes.ADDED:
      return taskFilesAdapter.addMany(action.payload, taskFilesState);
    case TaskFilesActionTypes.REMOVED:
      return taskFilesAdapter.removeMany(
        action.payload.map((a) => a.id),
        taskFilesState
      );
    case TaskFilesActionTypes.CLEAR:
      return taskFilesAdapter.removeAll(taskFilesState);
    case TaskFilesActionTypes.UPLOAD_SUCCESS:
      return { ...taskFilesState, unhandledFiles: action.unhandledFiles };
    case TaskFilesActionTypes.CLEAR_UNHANDLED_FILES:
      return { ...taskFilesState, unhandledFiles: [] };
    default:
      return taskFilesState;
  }
};

export const { selectAll } = taskFilesAdapter.getSelectors();
