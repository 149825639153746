import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { Collection } from '@shared/models/collection';
import { TaskComment } from '@pageProjects/models/task-comment';

@Injectable({
  providedIn: 'root'
})
export class TaskCommentService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(projectId: string): AngularFirestoreCollection<TaskComment> {
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<TaskComment>(Collection.TASK_COMMENTS);
  }

  getAll(projectId: string): Observable<DocumentChangeAction<TaskComment>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  save(taskComment: Partial<TaskComment>): Observable<boolean> {
    const result = this.getCollection(taskComment.project)
      .add({ ...taskComment } as TaskComment)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }

  copyComments(taskId: string, taskComments: TaskComment[]): Observable<boolean> {
    if (!taskComments.length) {
      return of(false);
    }

    const batch = this.afs.firestore.batch();
    for (const taskComment of taskComments) {
      const newTaskComment = { ...taskComment, taskId };
      const ref = this.getCollection(taskComment.project).doc().ref;
      batch.set(ref, newTaskComment);
    }

    return from(
      batch
        .commit()
        .then(() => true)
        .catch(() => false)
    );
  }
}
