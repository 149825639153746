import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Collection } from '@shared/models/collection';
import { Estimate } from '@pageProjects/models/estimate';
import { from, Observable, of } from 'rxjs';
import { DocumentChangeAction } from '@angular/fire/compat/firestore';
import { BatchCollection } from '../../pages/projects/tools/batch-collection';
import { map, switchMap } from 'rxjs/operators';
import { EstimateLogicService } from './estimate-logic.service';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {
  constructor(private afs: AngularFirestore) {}

  static getId(estimate: Partial<Estimate>): string {
    return `${estimate.task}-${estimate.technicalArea}`;
  }

  getAll(projectId: string): Observable<DocumentChangeAction<Estimate>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  remove(estimate: Estimate): Observable<boolean> {
    return from(
      this.getCollection(estimate.project)
        .doc(estimate.id)
        .delete()
        .then(() => true)
    );
  }

  deleteForArea(projectId: string, areaId: string): Observable<void> {
    const batchCollection = new BatchCollection(this.afs);
    const estimatesForAreaQuerySnapshot = this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection(Collection.ESTIMATES, (ref) => ref.where('technicalArea', '==', areaId))
      .get();

    return estimatesForAreaQuerySnapshot.pipe(
      map((estimates) => estimates.docs.map((estimate) => batchCollection.delete(estimate.ref))),
      switchMap(() => batchCollection.commit())
    );
  }

  save(estimate: Partial<Estimate>): Observable<string | null> {
    const id = EstimateService.getId(estimate);
    const collection = this.getCollection(estimate.project);
    const payload = { ...estimate, id } as Estimate;
    return from(
      collection
        .doc(id)
        .update(payload)
        .then(() => id)
        .catch(() =>
          collection
            .doc(id)
            .set(payload)
            .then(() => id)
            .catch(() => null)
        )
    );
  }

  private getCollection(projectId: string): AngularFirestoreCollection<Estimate> {
    const queryFn = (ref) => ref.where('project', '==', projectId);
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Estimate>(Collection.ESTIMATES, queryFn);
  }

  async copyTaskEstimated(estimates: Estimate[]): Promise<Observable<boolean>> {
    if (!estimates.length) {
      return of(false);
    }
    const batchCollection = new BatchCollection(this.afs);
    for (const estimate of estimates) {
      const id = EstimateService.getId(estimate);
      const collection = this.getCollection(estimate.project).doc(id);
      const document = await collection.get().toPromise();
      const ref = collection.ref;

      if (document.data()) {
        batchCollection.update(ref, estimate);
      } else {
        batchCollection.create(ref, estimate);
      }
    }

    return from(
      batchCollection
        .commitAsync()
        .then(() => true)
        .catch((e) => e)
    );
  }

  moveEstimatesToArea(
    projectId: string,
    estimatesFromArea: Estimate[],
    toAreaId: string,
    estimatesToArea: Estimate[],
    email: string
  ): Observable<void> {
    const batchCollection = new BatchCollection(this.afs);
    const estimatesRef = this.afs.firestore.collection(
      `${Collection.PROJECTS}/${projectId}/${Collection.ESTIMATES}`
    );
    const estimateLogic = new EstimateLogicService(projectId, email);

    estimatesFromArea.forEach((fromEstimate: Estimate) => {
      const toEstimateId = EstimateService.getId({
        task: fromEstimate.task,
        technicalArea: toAreaId
      });
      const toEstimate = estimatesToArea.find((e) => e.id === toEstimateId);
      if (toEstimate) {
        const updateData = estimateLogic.moveEstimatePointsToExistingEstimate(
          fromEstimate,
          toEstimate
        );
        batchCollection.update(estimatesRef.doc(toEstimateId), updateData);
      } else {
        const createData = estimateLogic.moveEstimatePointsToNewEstimate(
          fromEstimate,
          toEstimateId,
          toAreaId
        );
        batchCollection.create(estimatesRef.doc(toEstimateId), createData);
      }
      batchCollection.delete(estimatesRef.doc(fromEstimate.id));
    });

    return batchCollection.commit();
  }

  redistributeEstimates(
    projectId: string,
    areaId: string,
    otherAreasIds: string[],
    allEstimates: Estimate[],
    email: string
  ): Observable<void> {
    const batchCollection = new BatchCollection(this.afs);
    const estimatesRef = this.afs.firestore.collection(
      `${Collection.PROJECTS}/${projectId}/${Collection.ESTIMATES}`
    );
    const estimatesToDelete = allEstimates.filter((e) => e.technicalArea === areaId);
    const estimateLogic = new EstimateLogicService(projectId, email);

    estimatesToDelete.forEach((estimateToDelete: Estimate) => {
      const redistribution = estimateLogic.calculateRedistribution(estimateToDelete, otherAreasIds);

      otherAreasIds.forEach((otherAreaId: string) => {
        const otherAreaEstimateId = EstimateService.getId({
          task: estimateToDelete.task,
          technicalArea: otherAreaId
        });
        const otherAreaEstimate = allEstimates.find((e) => e.id === otherAreaEstimateId);
        if (otherAreaEstimate) {
          const updateData = estimateLogic.moveRedistributionPointsToExistingEstimate(
            otherAreaEstimate,
            redistribution
          );
          batchCollection.update(estimatesRef.doc(otherAreaEstimateId), updateData);
        } else {
          const createData = estimateLogic.moveRedistributionPointsToNewEstimate(
            otherAreaEstimateId,
            estimateToDelete.task,
            otherAreaId,
            redistribution
          );
          batchCollection.create(estimatesRef.doc(otherAreaEstimateId), createData);
        }
      });

      batchCollection.delete(estimatesRef.doc(estimateToDelete.id));
    });

    return batchCollection.commit();
  }
}
