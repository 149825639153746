import { Action } from '@ngrx/store';
import { PayloadAction } from '@core/models/payload-action';
import { GeneralAssumption } from '@pageProjects/models/general-assumption';
import { Role } from '@pageProjects/models/role';

export enum GeneralAssumptionActionTypes {
  LOAD = '[GENERAL ASSUMPTION] LOAD',
  ADDED = '[GENERAL ASSUMPTION] API ADDED',
  MODIFIED = '[GENERAL ASSUMPTION] API MODIFIED',
  SAVE = '[GENERAL ASSUMPTION] SAVE',
  SAVE_SUCCESS = '[GENERAL ASSUMPTION] SAVE SUCCESS',
  SAVE_FAIL = '[GENERAL ASSUMPTION] SAVE FAIL',
  UPDATE = '[GENERAL ASSUMPTION] UPDATE',
  UPDATE_SUCCESS = '[GENERAL ASSUMPTION] UPDATE SUCCESS',
  UPDATE_FAIL = '[GENERAL ASSUMPTION] UPDATE FAIL',
  CLEAR = '[GENERAL ASSUMPTION] CLEAR'
}

export class Load implements Action {
  readonly type = GeneralAssumptionActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = GeneralAssumptionActionTypes.ADDED;

  constructor(public payload: GeneralAssumption[]) {}
}

export class Modified implements Action {
  readonly type = GeneralAssumptionActionTypes.MODIFIED;

  constructor(public payload: GeneralAssumption[]) {}
}

export class Save implements PayloadAction {
  readonly type = GeneralAssumptionActionTypes.SAVE;
  payload: Partial<GeneralAssumption>;

  constructor(payload: Partial<GeneralAssumption>) {
    this.payload = payload;
  }
}

export class SaveSuccess implements Action {
  readonly type = GeneralAssumptionActionTypes.SAVE_SUCCESS;
}

export class SaveFail implements Action {
  readonly type = GeneralAssumptionActionTypes.SAVE_FAIL;
}

export class Update implements PayloadAction {
  readonly type = GeneralAssumptionActionTypes.UPDATE;
  payload: Partial<GeneralAssumption>;

  constructor(payload: Partial<GeneralAssumption>) {
    this.payload = payload;
  }
}

export class UpdateSuccess implements Action {
  readonly type = GeneralAssumptionActionTypes.UPDATE_SUCCESS;
}

export class UpdateFail implements Action {
  readonly type = GeneralAssumptionActionTypes.UPDATE_FAIL;
}

export class Clear implements Action {
  readonly type = GeneralAssumptionActionTypes.CLEAR;
}

export type GeneralAssumptionActions =
  | Load
  | Added
  | Save
  | SaveSuccess
  | SaveFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Modified
  | Clear;
