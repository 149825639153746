import { Action } from '@ngrx/store';
import { PayloadAction } from '@core/models/payload-action';
import { RolesAndRates } from '@shared/models/roles-and-rates';

export enum RolesAndRatesActionTypes {
  LOAD = '[ROLES AND RATES] LOAD',
  ADDED = '[ROLES AND RATES] API ADDED',
  MODIFIED = '[ROLES AND RATES] API MODIFIED',
  REMOVED = '[ROLES AND RATES] API REMOVED',
  API_ERROR = '[ROLES AND RATES] API ERROR',
  SAVE = '[ROLES AND RATES] SAVE',
  SAVE_SUCCESS = '[ROLES AND RATES] SAVE SUCCESS',
  SAVE_FAIL = '[ROLES AND RATES] SAVE FAIL',
  UPDATE = '[ROLES AND RATES] UPDATE',
  UPDATE_SUCCESS = '[ROLES AND RATES] UPDATE SUCCESS',
  UPDATE_FAIL = '[ROLES AND RATES] UPDATE FAIL',
  DELETE = '[ROLES AND RATES] DELETE',
  DELETE_SUCCESS = '[ROLES AND RATES] DELETE SUCCESS',
  DELETE_FAIL = '[ROLES AND RATES] DELETE FAIL',
  CLEAR = '[ROLES AND RATES] CLEAR'
}

export class Load implements Action {
  readonly type = RolesAndRatesActionTypes.LOAD;
}

export class Added implements Action {
  readonly type = RolesAndRatesActionTypes.ADDED;

  constructor(public payload: RolesAndRates[]) {}
}

export class Modified implements Action {
  readonly type = RolesAndRatesActionTypes.MODIFIED;

  constructor(public payload: RolesAndRates[]) {}
}

export class Removed implements Action {
  readonly type = RolesAndRatesActionTypes.REMOVED;

  constructor(public payload: RolesAndRates[]) {}
}

export class ApiError implements Action {
  readonly type = RolesAndRatesActionTypes.API_ERROR;
}

export class Save implements PayloadAction {
  readonly type = RolesAndRatesActionTypes.SAVE;
  payload: Partial<RolesAndRates>;

  constructor(payload: Partial<RolesAndRates>) {
    this.payload = payload;
  }
}

export class SaveSuccess implements Action {
  readonly type = RolesAndRatesActionTypes.SAVE_SUCCESS;
}

export class SaveFail implements Action {
  readonly type = RolesAndRatesActionTypes.SAVE_FAIL;
}

export class Update implements PayloadAction {
  readonly type = RolesAndRatesActionTypes.UPDATE;
  payload: Partial<RolesAndRates>;

  constructor(payload: Partial<RolesAndRates>) {
    this.payload = payload;
  }
}

export class UpdateSuccess implements Action {
  readonly type = RolesAndRatesActionTypes.UPDATE_SUCCESS;
}

export class UpdateFail implements Action {
  readonly type = RolesAndRatesActionTypes.UPDATE_FAIL;
}

export class Delete implements PayloadAction {
  readonly type = RolesAndRatesActionTypes.DELETE;
  payload: string;

  constructor(payload: string) {
    this.payload = payload;
  }
}

export class DeleteSuccess implements Action {
  readonly type = RolesAndRatesActionTypes.DELETE_SUCCESS;
}

export class DeleteFail implements Action {
  readonly type = RolesAndRatesActionTypes.DELETE_FAIL;
}

export class Clear implements Action {
  readonly type = RolesAndRatesActionTypes.CLEAR;
}

export type RolesAndRatesActions =
  | Load
  | Added
  | Save
  | SaveSuccess
  | SaveFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Modified
  | Removed
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Clear
  | ApiError;
