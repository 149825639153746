import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { AdminUserActions, AdminUserActionTypes } from '@app/store/actions/admin-user.actions';
import { AdminUser } from '@pageProjects/models/admin-user';

export const adminUserAdapter: EntityAdapter<AdminUser> = createEntityAdapter<AdminUser>();

export interface AdminUserState extends EntityState<AdminUser> {}

export const adminUserInitialState: AdminUserState = adminUserAdapter.getInitialState({});

export const adminUserReducer = (
  state: AdminUserState = adminUserInitialState,
  action: AdminUserActions
): AdminUserState => {
  switch (action.type) {
    case AdminUserActionTypes.ADDED:
      return adminUserAdapter.addOne(action.payload, state);
    case AdminUserActionTypes.CLEAR:
      return adminUserAdapter.removeAll(state);
    default:
      return state;
  }
};

export const { selectAll } = adminUserAdapter.getSelectors();
