import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as uiActions from '@app/store/actions/user-interface.actions';
import * as fromStore from '@app/store/selectors';
import { ProjectModuleState } from '@app/store/reducers';
import { first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as taskActions from '@app/store/actions/task.actions';
import { SelectedTask } from '@pageProjects/models/selected-task';

@Injectable()
export class UserInterfaceEffects {
  constructor(private store: Store<ProjectModuleState>, private actions$: Actions) {}

  taskRemoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.REMOVED),
      switchMap((action: taskActions.Removed) =>
        action.payload.map((t) => new uiActions.UnselectTask({ task: t.id, phase: null }))
      )
    )
  );

  selectAllTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uiActions.UserInterfaceActionTypes.ALL_TASKS_SELECTED),
      map((action: uiActions.AllTasksSelected) => action),
      withLatestFrom(this.store.select(fromStore.selectAllTasksWithPhase)),
      switchMap(([, tasks]) =>
        tasks.map(
          (task) =>
            new uiActions.SelectTask({
              task: task.id,
              phase: task.phase,
              variants: task.variants
            })
        )
      )
    )
  );

  selectAllTasksInBacklog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uiActions.UserInterfaceActionTypes.SELECT_ALL_TASKS_IN_BACKLOG),
      map((action: uiActions.AllTasksSelected) => action),
      withLatestFrom(this.store.select(fromStore.selectAllTasks)),
      switchMap(([, tasks]) => {
        const selectedTasksAction = tasks.map(
          (task) =>
            new uiActions.SelectTaskInBacklog({
              task: task.id,
              phase: task.phase,
              variants: task.variants
            })
        );
        return [new uiActions.UnselectAllTasksInBacklog(), ...selectedTasksAction];
      })
    )
  );

  selectPhaseTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uiActions.UserInterfaceActionTypes.PHASE_TASKS_SELECTION_REQUESTED),
      map((action: uiActions.PhaseTasksSelectionRequested) => action),
      switchMap((action) =>
        this.store.select(fromStore.selectTasksByPhaseId(action.phaseId)).pipe(first())
      ),
      map((tasks) => {
        const selectedTasks: SelectedTask[] = tasks.map((task) => {
          const selected: SelectedTask = {
            task: task.id,
            phase: task.phase,
            variants: task.variants
          };
          return selected;
        });

        return new uiActions.PhaseTasksSelected(selectedTasks);
      })
    )
  );
}
