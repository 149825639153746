import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { Collection } from '@shared/models/collection';
import { LeaderChecklist } from '@pageProjects/models/leader-checklist';

@Injectable({
  providedIn: 'root'
})
export class LeaderChecklistService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(projectId: string): AngularFirestoreCollection<LeaderChecklist> {
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<LeaderChecklist>(Collection.LEADER_CHECKLIST);
  }

  getAll(projectId: string): Observable<DocumentChangeAction<LeaderChecklist>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  save(leaderChecklist: Partial<LeaderChecklist>): Observable<boolean> {
    const result = this.getCollection(leaderChecklist.project)
      .add({ ...leaderChecklist } as LeaderChecklist)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }

  update(leaderChecklist: Partial<LeaderChecklist>): Observable<boolean> {
    const result = this.getCollection(leaderChecklist.project)
      .doc(leaderChecklist.id)
      .update(leaderChecklist)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }
}
