import { Injectable, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SidebarInterface } from '@shared/sidebar/sidebar-interface';

export interface SidebarUpdateRequest {
  sidebar: Type<SidebarInterface>;
  input: { [key: string]: any };
}

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarSubject: Subject<SidebarUpdateRequest> = new Subject<SidebarUpdateRequest>();

  constructor() {}

  clear(): void {
    this.sidebarSubject.next(null);
  }

  updateSidebar(component: Type<SidebarInterface>, input?: { [key: string]: any }): void {
    this.sidebarSubject.next({ sidebar: component, input });
  }

  sidebarUpdated(): Observable<SidebarUpdateRequest> {
    return this.sidebarSubject.asObservable();
  }
}
