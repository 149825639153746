import { Action } from '@ngrx/store';
import { Role } from '@pageProjects/models/role';
import { PayloadAction } from '@core/models/payload-action';

export enum RoleActionTypes {
  LOAD = '[ROLE] LOAD ROLES',
  ADDED = '[ROLE API] ADDED',
  MODIFIED = '[ROLE API] MODIFIED',
  REMOVED = '[ROLE API] REMOVED',
  API_ERROR = '[ROLE API] ERROR',
  CREATED = '[ROLE] CREATED',
  CREATED_SUCCESS = '[ROLE] CREATED SUCCESS',
  CREATED_FAIL = '[ROLE] CREATED FAIL',
  DELETED = '[ROLE] DELETED',
  DELETED_SUCCESS_REMOVE_ROLE_ASSOCIATION = '[ROLE] DELETED SUCCESS REMOVE ROLE ASSOCIATION',
  DELETED_FAIL = '[ROLE] DELETED FAIL',
  REMOVE_ROLE_ASSOCIATION_SUCCESS = '[ROLE] REMOVE ROLE ASSOCIATION SUCCESS',
  REMOVE_ROLE_ASSOCIATION_FAIL = '[ROLE] REMOVE ROLE ASSOCIATION FAIL',
  UPDATED = '[ROLE] UPDATED',
  UPDATED_SUCCESS = '[ROLE] UPDATED SUCCESS',
  UPDATED_FAIL = '[ROLE] UPDATED FAIL',
  CLEAR = '[ROLE] CLEAR',
  UPDATE_RATE_BY_CURRENCY = '[ROLE] UPDATE RATE BY CURRENCY',
  UPDATE_DISCOVERY_RATE_BY_CURRENCY = '[ROLE] UPDATE DISCOVERY RATE BY CURRENCY',
  CREATED_BATCH = '[ROLE] CREATED BATCH'
}

export class Load implements Action {
  readonly type = RoleActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = RoleActionTypes.ADDED;

  constructor(public payload: Role[]) {}
}

export class Modified implements Action {
  readonly type = RoleActionTypes.MODIFIED;

  constructor(public payload: Role[]) {}
}

export class Removed implements Action {
  readonly type = RoleActionTypes.REMOVED;

  constructor(public payload: Role[]) {}
}

export class ApiError implements Action {
  readonly type = RoleActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = RoleActionTypes.CREATED;

  constructor(public payload: Partial<Role>) {}
}

export class CreatedSuccess implements Action {
  readonly type = RoleActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = RoleActionTypes.CREATED_FAIL;
}

export class Deleted implements Action {
  readonly type = RoleActionTypes.DELETED;

  constructor(public roleId: string, public projectId: string) {}
}

export class DeletedSuccessRemoveRoleAssociation implements Action {
  readonly type = RoleActionTypes.DELETED_SUCCESS_REMOVE_ROLE_ASSOCIATION;

  constructor(public roleId: string, public projectId: string) {}
}

export class DeletedFail implements Action {
  readonly type = RoleActionTypes.DELETED_FAIL;
}

export class RemoveRoleAssociationSuccess implements Action {
  readonly type = RoleActionTypes.REMOVE_ROLE_ASSOCIATION_SUCCESS;
}

export class RemoveRoleAssociationFail implements Action {
  readonly type = RoleActionTypes.REMOVE_ROLE_ASSOCIATION_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = RoleActionTypes.UPDATED;

  constructor(public payload: Partial<Role>) {}
}

export class UpdatedSuccess implements Action {
  readonly type = RoleActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = RoleActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = RoleActionTypes.CLEAR;
}

export class UpdateRateByCurrency implements Action {
  readonly type = RoleActionTypes.UPDATE_RATE_BY_CURRENCY;

  constructor(public projectId: string) {}
}

export class UpdateDiscoveryRateByCurrency implements Action {
  readonly type = RoleActionTypes.UPDATE_DISCOVERY_RATE_BY_CURRENCY;

  constructor(public projectId: string) {}
}

export class CreatedBatch implements PayloadAction {
  readonly type = RoleActionTypes.CREATED_BATCH;

  constructor(public payload: Role[]) {}
}

export type RoleActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Deleted
  | DeletedSuccessRemoveRoleAssociation
  | DeletedFail
  | RemoveRoleAssociationSuccess
  | RemoveRoleAssociationFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear
  | UpdateRateByCurrency
  | UpdateDiscoveryRateByCurrency
  | CreatedBatch;
