import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { User } from '@pageUser/model/user';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  static COLLECTION_NAME = 'users';
  private readonly userCollection: AngularFirestoreCollection<User>;

  constructor(private afs: AngularFirestore) {
    this.userCollection = this.afs.collection<User>(UserService.COLLECTION_NAME);
  }

  add(user: Partial<User>): Observable<string> {
    return from(
      this.userCollection
        .doc(user.email)
        .set({ ...user } as User)
        .then(() => user.id)
    );
  }

  getAll(): Observable<DocumentChangeAction<User>[]> {
    return from(this.userCollection.stateChanges());
  }

  get(email: string): Observable<User> {
    return this.userCollection.doc(email).valueChanges();
  }
}
