import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as businessLineActions from '@app/store/actions/business-lines.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { BusinessLinesService } from '@core/services/business-lines.service';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class BusinessLinesEffects {
  constructor(private actions$: Actions, private businessLinesService: BusinessLinesService) {}

  loadBusinessLines$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(businessLineActions.BusinessLinesActionTypes.LOAD),
      map((action: businessLineActions.Load) => action),
      switchMap(() =>
        this.businessLinesService.getAll().pipe(handleDocumentChanges('[BUSINESS LINE]'))
      )
    )
  );

  created$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(businessLineActions.BusinessLinesActionTypes.CREATED),
      map((action: businessLineActions.Created) => action),
      switchMap((data) =>
        this.businessLinesService.add(data.payload).pipe(
          map((res) => {
            if (res && res.businessLineId) {
              return new businessLineActions.CreatedSuccess();
            }
          }),
          catchError(() => of(new businessLineActions.CreatedFail()))
        )
      )
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(businessLineActions.BusinessLinesActionTypes.DELETED),
      map((action: businessLineActions.Deleted) => action),
      switchMap((data) =>
        this.businessLinesService.delete(data.businessLineId).pipe(
          map(() => new businessLineActions.DeletedSuccess()),
          catchError(() => of(new businessLineActions.DeletedFail()))
        )
      )
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(businessLineActions.BusinessLinesActionTypes.UPDATED),
      map((action: businessLineActions.Updated) => action),
      switchMap((data) =>
        this.businessLinesService.update(data.payload).pipe(
          map(() => new businessLineActions.UpdatedSuccess()),
          catchError(() => of(new businessLineActions.UpdatedFail()))
        )
      )
    )
  );
}
