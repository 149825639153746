import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, REGION, USE_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';

// Edit useFirebaseEmulators in environment.ts to use production firebase

@NgModule({
  providers: [
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useAuthEmulator ? ['http://localhost:9099'] : undefined
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useFirebaseEmulators ? ['localhost', 8095] : undefined
    },
    {
      provide: USE_EMULATOR,
      useValue: environment.useFirebaseEmulators ? ['localhost', 5001] : undefined
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.production ? undefined : ['localhost', 9199]
    },
    { provide: REGION, useValue: 'europe-west3' }
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule
  ]
})
export class FirebaseModule {}
