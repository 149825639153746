import { KeyValue } from '@angular/common';
import { Estimate } from '@pageProjects/models/estimate';

export class EstimateLogicService {
  private readonly _projectId: string;
  private readonly _email: string;

  constructor(projectId: string, email: string) {
    this._projectId = projectId;
    this._email = email;
  }

  moveEstimatePointsToExistingEstimate(
    fromEstimate: Estimate,
    toEstimate: Estimate
  ): Partial<Estimate> {
    return {
      authorId: this._email,
      changeTime: new Date(),
      optimistic: fromEstimate.optimistic + toEstimate.optimistic,
      pessimistic: fromEstimate.pessimistic + toEstimate.pessimistic,
      realistic: fromEstimate.realistic + toEstimate.realistic
    } as Partial<Estimate>;
  }

  moveEstimatePointsToNewEstimate(
    fromEstimate: Estimate,
    toEstimateId: string,
    toAreaId: string
  ): Estimate {
    return {
      id: toEstimateId,
      authorId: this._email,
      changeTime: new Date(),
      optimistic: fromEstimate.optimistic,
      pessimistic: fromEstimate.pessimistic,
      realistic: fromEstimate.realistic,
      project: this._projectId,
      task: fromEstimate.task,
      technicalArea: toAreaId
    } as Estimate;
  }

  calculateRedistribution(estimate: Estimate, areasIds: string[]): RedistributionForTypes {
    const optimistic = this.calculateRedistributionForSpecificType(estimate.optimistic, areasIds);
    const pessimistic = this.calculateRedistributionForSpecificType(estimate.pessimistic, areasIds);
    const realistic = this.calculateRedistributionForSpecificType(estimate.realistic, areasIds);

    return {
      optimistic,
      pessimistic,
      realistic
    };
  }

  moveRedistributionPointsToExistingEstimate(
    toEstimate: Estimate,
    redistribution: RedistributionForTypes
  ): Partial<Estimate> {
    return {
      authorId: this._email,
      changeTime: new Date(),
      optimistic:
        toEstimate.optimistic +
        redistribution.optimistic.find((a) => a.key === toEstimate.technicalArea).value,
      pessimistic:
        toEstimate.pessimistic +
        redistribution.pessimistic.find((a) => a.key === toEstimate.technicalArea).value,
      realistic:
        toEstimate.realistic +
        redistribution.realistic.find((a) => a.key === toEstimate.technicalArea).value
    } as Partial<Estimate>;
  }

  moveRedistributionPointsToNewEstimate(
    toEstimateId: string,
    toTaskId: string,
    toAreaId: string,
    redistribution: RedistributionForTypes
  ): Estimate {
    return {
      id: toEstimateId,
      authorId: this._email,
      changeTime: new Date(),
      optimistic: redistribution.optimistic.find((a) => a.key === toAreaId).value,
      pessimistic: redistribution.pessimistic.find((a) => a.key === toAreaId).value,
      realistic: redistribution.realistic.find((a) => a.key === toAreaId).value,
      project: this._projectId,
      task: toTaskId,
      technicalArea: toAreaId
    } as Estimate;
  }

  private calculateRedistributionForSpecificType(
    pointsToRedistribute: number,
    otherAreasIds: string[]
  ): KeyValue<string, number>[] {
    const modulo = pointsToRedistribute % otherAreasIds.length;
    const minPoints = (pointsToRedistribute - modulo) / otherAreasIds.length;
    const otherAreasPoints = otherAreasIds.map(
      (id) => ({ key: id, value: minPoints } as KeyValue<string, number>)
    );

    for (let index = 0; index < modulo; index++) {
      otherAreasPoints[index].value++;
    }

    return otherAreasPoints;
  }
}

export type RedistributionForTypes = {
  optimistic: KeyValue<string, number>[];
  pessimistic: KeyValue<string, number>[];
  realistic: KeyValue<string, number>[];
};
