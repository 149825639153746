import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Estimate } from '@pageProjects/models/estimate';
import { EstimateActions, EstimateActionTypes } from '@app/store/actions/estimate.actions';
import { ProjectUtils as PU } from '@pageProjects/tools/utils';

export const estimateAdapter: EntityAdapter<Estimate> = createEntityAdapter<Estimate>();

export interface EstimateState extends EntityState<Estimate> {}

export const estimateInitialState: EstimateState = estimateAdapter.getInitialState({});

export const estimateReducer = (
  estimateState: EstimateState = estimateInitialState,
  action: EstimateActions
): EstimateState => {
  switch (action.type) {
    case EstimateActionTypes.ADDED: {
      const estimates = action.payload.map((estimate) => PU.convertDates<Estimate>(estimate));
      return estimateAdapter.addMany(estimates, estimateState);
    }
    case EstimateActionTypes.MODIFIED:
      return estimateAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: PU.convertDates<Estimate>(a)
        })),
        estimateState
      );
    case EstimateActionTypes.REMOVED:
      return estimateAdapter.removeMany(
        action.payload.map((a) => a.id),
        estimateState
      );
    case EstimateActionTypes.CLEAR:
      return estimateAdapter.removeAll(estimateState);
    default:
      return estimateState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = estimateAdapter.getSelectors();
