import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectUtils } from '@pageProjects/tools/utils';
import { Estimate } from '@pageProjects/models/estimate';
import {
  EstimateHistoryActions,
  EstimateHistoryActionTypes
} from '@app/store/actions/estimate-history.actions';

export const estimateHistoryAdapter: EntityAdapter<Estimate> = createEntityAdapter<Estimate>();

export interface EstimateHistoryState extends EntityState<Estimate> {}

export const estimateHistoryInitialState: EstimateHistoryState =
  estimateHistoryAdapter.getInitialState([]);

export const estimateHistoryReducer = (
  estimateHistoryState: EstimateHistoryState = estimateHistoryInitialState,
  actions: EstimateHistoryActions
): EstimateHistoryState => {
  switch (actions.type) {
    case EstimateHistoryActionTypes.ADDED: {
      const estimates = actions.payload.map((estimate) =>
        ProjectUtils.convertDates<Estimate>(estimate)
      );
      return estimateHistoryAdapter.addMany(estimates, estimateHistoryState);
    }
    default:
      return estimateHistoryState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } =
  estimateHistoryAdapter.getSelectors();
