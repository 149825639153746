import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PhaseService } from '@core/services/phase.service';
import { RoleService } from '@core/services/role.service';
import * as roleActions from '@app/store/actions/role.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { handleDocumentChanges } from '@shared/operators';
import { FinalRate } from '@pageProjects/models/role';
import { RoleUtils } from '@core/common/role-utils';

@Injectable()
export class RoleEffects {
  constructor(
    private actions$: Actions,
    private roleService: RoleService,
    private phaseService: PhaseService
  ) {}

  loadRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(roleActions.RoleActionTypes.LOAD),
      map((action: roleActions.Load) => action),
      switchMap((loadAction) =>
        this.roleService.getAll(loadAction.projectId).pipe(handleDocumentChanges('[ROLE API]'))
      )
    )
  );

  created$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(roleActions.RoleActionTypes.CREATED),
      map((action: roleActions.Created) => action),
      switchMap((data) =>
        this.roleService.add(data.payload).pipe(
          map((res) => {
            if (res && res.roleId) {
              return new roleActions.CreatedSuccess();
            }
          }),
          catchError(() => of(new roleActions.CreatedFail()))
        )
      )
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(roleActions.RoleActionTypes.DELETED),
      map((action: roleActions.Deleted) => action),
      switchMap((data) =>
        this.roleService.delete(data.roleId, data.projectId).pipe(
          map(
            ([deletedRoleId, projectId]) =>
              new roleActions.DeletedSuccessRemoveRoleAssociation(deletedRoleId, projectId)
          ),
          catchError(() => of(new roleActions.DeletedFail()))
        )
      )
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(roleActions.RoleActionTypes.UPDATED),
      map((action: roleActions.Updated) => action),
      switchMap((data) => {
        const finalRates: FinalRate = {};
        const payload = data.payload;
        const updatedPayload = { ...payload };
        if (payload.discounts !== undefined && payload.rates !== undefined) {
          const levels = RoleUtils.getLevels(payload.levelSplitValue, payload.levelSplit);
          for (const level of levels) {
            const technicalAreaDiscountSplit = payload?.technicalAreaDiscountSplit?.[level.id];
            finalRates[level.id] = {};
            if (technicalAreaDiscountSplit) {
              for (const ta of Object.keys(payload.technicalAreaDiscounts[level.id])) {
                const discount = payload.technicalAreaDiscounts[level.id][ta] || 0;
                finalRates[level.id][ta] =
                  payload.rates[level.id] - (payload.rates[level.id] * discount) / 100;
              }
            } else {
              const discount = payload.discounts[level.id] || 0;
              finalRates[level.id] =
                payload.rates[level.id] - (payload.rates[level.id] * discount) / 100;
            }
          }
          updatedPayload.finalRate = finalRates;
        }

        return this.roleService.update(updatedPayload).pipe(
          map(() => new roleActions.UpdatedSuccess()),
          catchError(() => of(new roleActions.UpdatedFail()))
        );
      })
    )
  );

  removeRoleAssociation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(roleActions.RoleActionTypes.DELETED_SUCCESS_REMOVE_ROLE_ASSOCIATION),
      map((action: roleActions.DeletedSuccessRemoveRoleAssociation) => action),
      switchMap((data) =>
        this.phaseService.removeRoleAssociation(data.projectId).pipe(
          map(() => new roleActions.RemoveRoleAssociationSuccess()),
          catchError(() => of(new roleActions.RemoveRoleAssociationFail()))
        )
      )
    )
  );

  updateRateByCurrency$: Observable<boolean> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(roleActions.RoleActionTypes.UPDATE_RATE_BY_CURRENCY),
        map((action: roleActions.UpdateRateByCurrency) => action),
        switchMap((data) => this.roleService.updateRoleRateByCurrency(data.projectId))
      ),
    { dispatch: false }
  );

  updateDiscoveryRateByCurrency$: Observable<boolean> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(roleActions.RoleActionTypes.UPDATE_DISCOVERY_RATE_BY_CURRENCY),
        map((action: roleActions.UpdateDiscoveryRateByCurrency) => action),
        switchMap((data) => this.roleService.updateRoleDiscoveryRateByCurrency(data.projectId))
      ),
    { dispatch: false }
  );

  createdBatch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(roleActions.RoleActionTypes.CREATED_BATCH),
      map((action: roleActions.CreatedBatch) => action),
      switchMap((data) =>
        this.roleService.addBatch(data.payload).pipe(
          map((res) => {
            if (res) {
              return new roleActions.CreatedSuccess();
            }
          }),
          catchError(() => of(new roleActions.CreatedFail()))
        )
      )
    )
  );
}
