import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TechnicalArea } from '@pageProjects/models/technical-area';
import {
  TechnicalAreaActions,
  TechnicalAreaActionTypes
} from '@app/store/actions/technical-area.actions';
import { ProjectUtils as PU } from '@pageProjects/tools/utils';

export const technicalAreaAdapter: EntityAdapter<TechnicalArea> =
  createEntityAdapter<TechnicalArea>();
export interface TechnicalAreaState extends EntityState<TechnicalArea> {}

export const technicalAreaInitialState: TechnicalAreaState = technicalAreaAdapter.getInitialState(
  {}
);

export const technicalAreaReducer = (
  technicalAreaState: TechnicalAreaState = technicalAreaInitialState,
  action: TechnicalAreaActions
): TechnicalAreaState => {
  switch (action.type) {
    case TechnicalAreaActionTypes.ADDED: {
      const areas = action.payload.map((a) => PU.convertDates<TechnicalArea>(a));
      return technicalAreaAdapter.addMany(areas, technicalAreaState);
    }
    case TechnicalAreaActionTypes.MODIFIED:
      return technicalAreaAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: PU.convertDates<TechnicalArea>(a)
        })),
        technicalAreaState
      );
    case TechnicalAreaActionTypes.REMOVED:
      return technicalAreaAdapter.removeMany(
        action.payload.map((a) => a.id),
        technicalAreaState
      );
    case TechnicalAreaActionTypes.CLEAR: {
      return technicalAreaAdapter.removeAll(technicalAreaState);
    }
    default:
      return technicalAreaState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } =
  technicalAreaAdapter.getSelectors();
