import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { Collection } from '@shared/models/collection';
import { Phase } from '@pageProjects/models/phase';
import { EMPTY, from, iif, Observable, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PhaseSupportRoleConfig } from '@pageProjects/models/phase-support-role-config';

@Injectable({
  providedIn: 'root'
})
export class PhaseService {
  constructor(private afs: AngularFirestore) {}

  static getSupportRoleConfigId(config: PhaseSupportRoleConfig): string {
    return `${config.phase}-${config.role}`;
  }

  add(phase: Partial<Phase>): Observable<{ id: string; name: string }> {
    return from(
      this.getCollection(phase.project)
        .add({ ...phase } as Phase)
        .catch((e) => e)
        .then((docRef: DocumentReference<Phase>) => ({ id: docRef.id, name: phase.name }))
    );
  }

  createAll(phases: Partial<Phase>[]): Observable<Partial<Phase>[]> {
    if (!phases.length) {
      return of([]);
    }

    const phaseRequests: Observable<{ id: string; name: string }>[] = [];

    phases.forEach(async (phase) => {
      phaseRequests.push(this.add(phase));
    });

    return zip(...phaseRequests);
  }

  saveConfig(config: PhaseSupportRoleConfig): Observable<boolean> {
    const collection = this.getSupportRoleConfigCollection(config.project);
    const id = PhaseService.getSupportRoleConfigId(config);

    const promise = collection
      .doc(id)
      .update(config)
      .then(() => true)
      .catch(() =>
        collection
          .doc(id)
          .set(config)
          .then(() => true)
          .catch(() => false)
      );

    return from(promise);
  }

  getConfigs(projectId: string): Observable<DocumentChangeAction<PhaseSupportRoleConfig>[]> {
    return this.getSupportRoleConfigCollection(projectId).stateChanges();
  }

  deleteConfig(config: PhaseSupportRoleConfig): Observable<boolean> {
    const id = PhaseService.getSupportRoleConfigId(config);
    return from(
      this.getSupportRoleConfigCollection(config.project)
        .doc(id)
        .delete()
        .then(() => true)
        .catch(() => false)
    );
  }

  update(phase: Partial<Phase>): Observable<any> {
    const updatedPhaseData = { ...phase };
    delete updatedPhaseData.id;

    return from(
      this.getCollection(phase.project)
        .doc<Phase>(`${phase.id}`)
        .update({
          ...updatedPhaseData
        })
        .catch((e) => e)
        .then(() => 'phase updated')
    );
  }

  copyConfigBatch(data: {
    from: { [key: string]: PhaseSupportRoleConfig };
    to: { [key: string]: PhaseSupportRoleConfig };
  }): Observable<boolean> {
    const batch = this.afs.firestore.batch();
    for (const config of Object.values(data.from)) {
      const id = PhaseService.getSupportRoleConfigId(config);
      const ref = this.getSupportRoleConfigCollection(config.project).doc(id).ref;
      batch.delete(ref);
    }

    for (const config of Object.values(data.to)) {
      const updatedPhaseConfig = { ...config };
      delete updatedPhaseConfig.id;

      const id = PhaseService.getSupportRoleConfigId(updatedPhaseConfig);
      const ref = this.getSupportRoleConfigCollection(updatedPhaseConfig.project).doc(id).ref;
      batch.set(ref, updatedPhaseConfig);
    }

    return from(
      batch
        .commit()
        .then(() => true)
        .catch((e) => e)
    );
  }

  updateBatch(projectId: string, phases: Partial<Phase>[]): Observable<boolean> {
    if (phases.length === 0) {
      return of(false);
    }

    const batch = this.afs.firestore.batch();
    phases.forEach((phase) => {
      const ref = this.getCollection(projectId).doc(phase.id).ref;
      batch.update(ref, phase);
    });

    return from(
      batch
        .commit()
        .then(() => true)
        .catch((e) => e)
    );
  }

  delete(projectId: string, phaseId: string): Observable<{ phase: string; project: string }> {
    return from(
      this.getCollection(projectId)
        .doc(phaseId)
        .delete()
        .then(() => true)
        .catch((e) => e)
    ).pipe(
      switchMap(() =>
        this.fixOrder(projectId).pipe(switchMap(() => of({ phase: phaseId, project: projectId })))
      )
    );
  }

  fixOrder(projectId: string): Observable<boolean> {
    const batch = this.afs.firestore.batch();
    return this.getCollection(projectId)
      .get()
      .pipe(
        switchMap((projectPhases) => {
          projectPhases.docs.sort((a, b) => b.data().order - a.data().order);
          projectPhases.docs.forEach((phase, index) => {
            batch.update(phase.ref, { order: index });
          });
          return from(batch.commit().then(() => true));
        })
      );
  }

  getAll(projectId: string): Observable<DocumentChangeAction<Phase>[]> {
    return this.getCollectionFilterByProject(projectId).stateChanges();
  }

  removeRoleAssociation(projectId: string): Observable<any[] | Observable<never>> {
    return this.getCollectionFilterByProject(projectId)
      .get()
      .pipe(switchMap(() => of(EMPTY)));
  }

  removeTechnicalAreaAssociation(projectId: string): Observable<any[] | Observable<never>> {
    return this.getCollectionFilterByProject(projectId)
      .get()
      .pipe(switchMap(() => of(EMPTY)));
  }

  removeProjectPhases(projectId: string): Observable<any[] | Observable<never>> {
    return this.getCollectionFilterByProject(projectId)
      .get()
      .pipe(switchMap((phases) => iif(() => phases.empty, of(EMPTY), this.removePhases(phases))));
  }

  private getCollectionFilterByProject(projectId: string): AngularFirestoreCollection<Phase> {
    return this.getCollection(projectId);
  }

  private getCollection(projectId: string): AngularFirestoreCollection<Phase> {
    const queryFn = (ref) => ref.where('project', '==', projectId);
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Phase>(Collection.PHASES, queryFn);
  }

  private getSupportRoleConfigCollection(
    projectId: string
  ): AngularFirestoreCollection<PhaseSupportRoleConfig> {
    if (!projectId) {
      throw new Error('project Id not defined!');
    }

    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<PhaseSupportRoleConfig>(Collection.PHASE_SUPPORT_ROLE_CONFIG);
  }

  private removePhases(
    phases: QuerySnapshot<Phase>
  ): Observable<{ phase: string; project: string }[]> {
    const phasesDeleteRequests: Observable<{ phase: string; project: string }>[] = [];
    phases.docs.forEach((phase) => {
      phasesDeleteRequests.push(this.delete(phase.data().project, phase.id));
    });
    return zip(...phasesDeleteRequests);
  }
}
