import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRolesAndRates from '@app/store/reducers/rates-and-roles.reducer';

export const selectRolesAndRatesState =
  createFeatureSelector<fromRolesAndRates.RolesAndRatesState>('rolesAndRates');

export const selectAllRolesAndRates = createSelector(
  selectRolesAndRatesState,
  fromRolesAndRates.selectAll
);
