import { Action } from '@ngrx/store';
import { TechnicalArea, TechnicalAreaDelete } from '@pageProjects/models/technical-area';
import { PayloadAction } from '@core/models/payload-action';

export enum TechnicalAreaActionTypes {
  LOAD = '[TECHNICAL AREA] LOAD TECHNICAL AREAS',
  ADDED = '[TECHNICAL AREA API] ADDED',
  MODIFIED = '[TECHNICAL AREA API] MODIFIED',
  REMOVED = '[TECHNICAL AREA API] REMOVED',
  API_ERROR = '[TECHNICAL AREA API] ERROR',
  CREATED = '[TECHNICAL AREA] CREATED',
  CREATED_SUCCESS = '[TECHNICAL AREA] CREATED SUCCESS',
  CREATED_FAIL = '[TECHNICAL AREA] CREATED FAIL',
  DELETE = '[TECHNICAL AREA] DELETE',
  DELETE_SUCCESS = '[TECHNICAL AREA] DELETE SUCCESS',
  DELETE_FAIL = '[TECHNICAL AREA] DELETE FAIL',
  DELETE_TECHNICAL_AREA_WITH_ASSOCIATION = '[TECHNICAL AREA] DELETE TECHNICAL AREA WITH ASSOCIATION',
  DELETE_TECHNICAL_AREA_WITH_ASSOCIATION_SUCCESS = '[TECHNICAL AREA] DELETE TECHNICAL AREA WITH ASSOCIATION SUCCESS',
  DELETE_TECHNICAL_AREA_WITH_ASSOCIATION_FAIL = '[TECHNICAL AREA] DELETE TECHNICAL AREA WITH ASSOCIATION FAIL',
  UPDATED = '[TECHNICAL AREA] UPDATED',
  UPDATED_SUCCESS = '[TECHNICAL AREA] UPDATED SUCCESS',
  UPDATED_FAIL = '[TECHNICAL AREA] UPDATED FAIL',
  CLEAR = '[TECHNICAL AREA] CLEAR'
}

export class Load implements Action {
  readonly type = TechnicalAreaActionTypes.LOAD;
  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = TechnicalAreaActionTypes.ADDED;
  constructor(public payload: TechnicalArea[]) {}
}

export class Modified implements Action {
  readonly type = TechnicalAreaActionTypes.MODIFIED;
  constructor(public payload: TechnicalArea[]) {}
}

export class Removed implements Action {
  readonly type = TechnicalAreaActionTypes.REMOVED;
  constructor(public payload: TechnicalArea[]) {}
}

export class ApiError implements Action {
  readonly type = TechnicalAreaActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = TechnicalAreaActionTypes.CREATED;
  constructor(public payload: Partial<TechnicalArea>) {}
}

export class CreatedSuccess implements Action {
  readonly type = TechnicalAreaActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = TechnicalAreaActionTypes.CREATED_FAIL;
}

export class Delete implements Action {
  readonly type = TechnicalAreaActionTypes.DELETE;
  constructor(public deleteData: TechnicalAreaDelete, public projectId: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = TechnicalAreaActionTypes.DELETE_SUCCESS;
}

export class DeleteFail implements Action {
  readonly type = TechnicalAreaActionTypes.DELETE_FAIL;
}

export class DeleteTechnicalAreaWithAssociation implements Action {
  readonly type = TechnicalAreaActionTypes.DELETE_TECHNICAL_AREA_WITH_ASSOCIATION;
  constructor(public projectId: string, public areaId: string) {}
}

export class DeleteTechnicalAreaWithAssociationSuccess implements Action {
  readonly type = TechnicalAreaActionTypes.DELETE_TECHNICAL_AREA_WITH_ASSOCIATION_SUCCESS;
}

export class DeleteTechnicalAreaWithAssociationFail implements Action {
  readonly type = TechnicalAreaActionTypes.DELETE_TECHNICAL_AREA_WITH_ASSOCIATION_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = TechnicalAreaActionTypes.UPDATED;
  constructor(public payload: Partial<TechnicalArea>) {}
}

export class UpdatedSuccess implements Action {
  readonly type = TechnicalAreaActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = TechnicalAreaActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = TechnicalAreaActionTypes.CLEAR;
}

export type TechnicalAreaActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | DeleteTechnicalAreaWithAssociation
  | DeleteTechnicalAreaWithAssociationSuccess
  | DeleteTechnicalAreaWithAssociationFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear;
