import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLog from '@app/store/reducers/log.reducer';
import * as fromProject from '@app/store/selectors/project.selectors';

export const selectLogState = createFeatureSelector<fromLog.LogState>('logs');

export const selectAllLogs = createSelector(selectLogState, fromLog.selectAll);

export const selectLastLogForProject = (projectId: string) =>
  createSelector(selectAllLogs, fromProject.selectProjectById(projectId), (logs, project) => {
    const first = logs
      .filter((log) => log.project === projectId)
      .map((log) => log.time)
      .sort((d1: Date, d2: Date) => d2.getTime() - d1.getTime())[0];
    if (first !== undefined) {
      return first;
    }

    if (project) {
      return project.created;
    }

    return null;
  });
