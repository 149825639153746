import { NbAuthService } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store/selectors';
import { map } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class ProjectRoleProvider implements NbRoleProvider {
  constructor(
    private nbAuthService: NbAuthService,
    private store: Store,
    private authService: AuthService
  ) {}

  getRole(): Observable<string> {
    return combineLatest([
      this.nbAuthService.onTokenChange(),
      this.store.select(fromStore.selectProject),
      this.authService.isAdmin$
    ]).pipe(
      map(([token, project, isAdmin]) => {
        const email = token.getPayload()?.email;
        if (project?.owners.find((user) => user === email)) {
          return 'owner';
        }

        if (project?.contributors.find((user) => user === email)) {
          return 'contributor';
        }

        if (project?.viewers.find((user) => user === email)) {
          return 'viewer';
        }

        if (isAdmin) {
          return 'administrator';
        }

        return 'user';
      })
    );
  }
}
