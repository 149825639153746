export enum Collection {
  CURRENCIES = 'currencies',
  PHASES = 'phases',
  PROJECTS = 'projects',
  ROLES = 'roles',
  TASKS = 'tasks',
  TASK_FILES = 'taskFiles',
  TASK_COMMENTS = 'taskComments',
  TECHNICAL_AREAS = 'technicalAreas',
  LOGS = 'logs',
  ESTIMATES = 'estimates',
  ESTIMATE_HISTORY = 'estimateHistory',
  PHASE_SUPPORT_ROLE_CONFIG = 'phaseSupportRoleConfigs',
  FTES = 'ftes',
  GENERAL_ASSUMPTIONS = 'generalAssumptions',
  LEADER_CHECKLIST = 'leaderChecklist',
  ADMIN_USERS = 'adminUsers',
  ROLES_AND_RATES = 'rolesAndRates',
  BUSINESS_LINE = 'businessLines',
  RISKS = 'risks'
}
