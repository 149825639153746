import { Role } from '@pageProjects/models/role';
import { TechnicalArea } from '@pageProjects/models/technical-area';

export class InfiniteWorkdaysError extends Error {}

export class AreaFteEffort {
  constructor(
    public readonly area: string,
    private readonly role: Role,
    private readonly levelId: string,
    private readonly workdays: number,
    private readonly totalWorkdays: number,
    private readonly fte: number,
    private readonly areaObject: TechnicalArea
  ) {}

  rawWorkdays(): number {
    if (!isFinite(this.workdays)) {
      throw new InfiniteWorkdaysError();
    }
    return this.workdays;
  }

  realWorkdays(): number {
    if (!isFinite(this.workdays)) {
      throw new InfiniteWorkdaysError();
    }
    return this.totalWorkdays;
  }

  cost(): number {
    if (!isFinite(this.workdays)) {
      throw new InfiniteWorkdaysError();
    }
    if (
      this.role.technicalAreaDiscountSplit &&
      this.role.technicalAreaDiscountSplit[this.levelId]
    ) {
      const finalRate =
        this.role.finalRate[this.levelId][this.areaObject.name] ||
        this.role.finalRate[this.levelId];

      return finalRate * this.workdays;
    }
    if (Number.isInteger(this.role.finalRate[this.levelId])) {
      return (this.role.finalRate[this.levelId] as number) * this.workdays;
    }
  }

  fpMonthCost(monthlyOverhead: number = 0): number {
    return (this.role.cost[this.levelId] + monthlyOverhead) * this.fte;
  }

  dayCost(multiplier: number = 1): number {
    let finalRate = 0;

    if (
      this.role.technicalAreaDiscountSplit &&
      this.role.technicalAreaDiscountSplit[this.levelId]
    ) {
      if (
        this.role.finalRate[this.levelId] &&
        this.role.finalRate[this.levelId][this.areaObject.name]
      ) {
        finalRate = this.role.finalRate[this.levelId][this.areaObject.name];
      }
    } else if (typeof this.role.finalRate[this.levelId] === 'number') {
      finalRate = this.role.finalRate[this.levelId] as number;
    }

    const rate = Math.round(finalRate * multiplier);

    return rate * this.fte;
  }

  isRoleOfTypeSupport(): boolean {
    return this.role.support;
  }

  businessLine(): string {
    return this.role.businessLine;
  }

  isFromBusinessLine(line: string): boolean {
    return this.role.businessLine === line;
  }
}
