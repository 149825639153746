import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCurrency from '@app/store/reducers/currency.reducer';
import { Currency } from '@pageProjects/models/currency';

export const selectCurrenciesState =
  createFeatureSelector<fromCurrency.CurrencyState>('currencies');

export const selectNbpCurrenciesState =
  createFeatureSelector<fromCurrency.NbpCurrencyState>('nbpCurrencies');

export const selectAllCurrencies = createSelector(selectCurrenciesState, fromCurrency.selectAll);

export const selectAllNbpCurrencies = createSelector(
  selectNbpCurrenciesState,
  fromCurrency.selectAllNbp
);

export const selectCombinedCurrencies = createSelector(
  selectAllNbpCurrencies,
  selectAllCurrencies,
  (nbp, custom) => {
    const newNbp: Currency[] = nbp.map((currency) => {
      const copy = {};
      Object.assign(copy, currency);
      return copy as Currency;
    });

    custom.forEach((customCurrency) => {
      const customMatch = newNbp.find(
        (currency: Currency) => currency.code === customCurrency.currency
      );

      const forAssignment = {
        overwritten: customCurrency.active,
        customRate: customCurrency.rate,
        customCurrencyId: customCurrency.id
      };

      Object.assign(customMatch, forAssignment);
    });
    return newNbp;
  }
);
