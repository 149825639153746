import { Injectable } from '@angular/core';
import {
  NbAuthJWTToken,
  NbAuthResult,
  NbAuthStrategyClass,
  NbAuthStrategyOptions
} from '@nebular/auth';
import {
  NbFirebaseBaseStrategy,
  NbFirebaseIdentityProviderStrategyOptions as Options
} from '@nebular/firebase-auth';

import { defer, Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '@env/environment';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class FpFirebaseAzureStrategy extends NbFirebaseBaseStrategy {
  protected defaultOptions = new Options();

  constructor(afAuth: AngularFireAuth, private http: HttpClient) {
    super(afAuth);
  }

  static setup(options: Options): [NbAuthStrategyClass, NbAuthStrategyOptions] {
    return [FpFirebaseAzureStrategy, options];
  }

  authenticate(data?: any): Observable<NbAuthResult> {
    const module = 'authenticate';
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    const scopes = this.getOption('scopes');

    scopes.forEach((scope) => provider.addScope(scope));
    provider.setCustomParameters(this.getOption('customParameters'));

    return defer(() => this.afAuth.signInWithPopup(provider)).pipe(
      take(1),
      switchMap((response) => this.processSuccess(response, module))
    );
  }

  refreshToken(data?: any): Observable<NbAuthResult> {
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      return this.http
        .post(
          `https://securetoken.googleapis.com/v1/token?key=${environment.firebase.apiKey}`,
          `grant_type=refresh_token&refresh_token=${firebase.auth().currentUser.refreshToken}`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .pipe(
          take(1),
          map(
            (response) =>
              response as { id_token: string; access_token: string; refresh_token: string }
          ),
          map(
            (response) =>
              new NbAuthResult(
                true,
                null,
                null,
                null,
                null,
                new NbAuthJWTToken(response.access_token, environment.strategyName)
              )
          )
        );
    } else {
      const module = 'authenticate';

      return this.processSuccess(null, module).pipe(
        map(() => new NbAuthResult(false, null, 'login'))
      );
    }
  }
}
