import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Role } from '@pageProjects/models/role';
import { RoleActions, RoleActionTypes } from '@app/store/actions/role.actions';

export const roleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export interface RoleState extends EntityState<Role> {}

export const roleInitialState: RoleState = roleAdapter.getInitialState({});

export const rolesReducer = (
  roleState: RoleState = roleInitialState,
  action: RoleActions
): RoleState => {
  switch (action.type) {
    case RoleActionTypes.ADDED: {
      const rs = action.payload.map((r) => new Role(r));
      return roleAdapter.addMany(rs, roleState);
    }
    case RoleActionTypes.MODIFIED:
      return roleAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        roleState
      );
    case RoleActionTypes.REMOVED:
      return roleAdapter.removeMany(
        action.payload.map((a) => a.id),
        roleState
      );
    case RoleActionTypes.CLEAR: {
      return roleAdapter.removeAll(roleState);
    }
    default:
      return roleState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = roleAdapter.getSelectors();
