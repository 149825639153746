import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRole from '@app/store/reducers/role.reducer';
import { Level } from '@core/models/level';
import { LevelSplit, RoleUtils } from '@core/common/role-utils';
import { ProjectUtils } from '@pageProjects/tools/utils';

export const selectRolesState = createFeatureSelector<fromRole.RoleState>('roles');

const selectAllRoles = createSelector(selectRolesState, fromRole.selectAll);

export const selectSortedRoles = createSelector(selectAllRoles, (roles) =>
  roles.sort((a, b) => ProjectUtils.sortByProperty(a, b, 'name'))
);

export const selectLevelsForRoles = createSelector(selectAllRoles, (roles) => {
  const levels: { [key: string]: Level[] } = {};
  roles.forEach((role) => {
    levels[role.id] = RoleUtils.getLevels(
      role.levelSplit ? role.levelSplitValue : LevelSplit.None,
      role.levelSplit
    );
  });

  return levels;
});
