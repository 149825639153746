import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { AuthenticatedUser } from '@core/models/authenticated-user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  id: string | null;
  email: string | null;
  isAdmin$ = new BehaviorSubject<boolean | null>(null);
  user$ = new BehaviorSubject<AuthenticatedUser | null>(null);
  private _isAuthenticated: boolean;

  constructor(private nbAuthService: NbAuthService, private router: Router) {
    this.spyOnAuthenticationStatus();
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  get isAuthenticatedOrRefresh(): Observable<boolean> {
    return this.nbAuthService.isAuthenticatedOrRefresh();
  }

  logout(): void {
    this.nbAuthService.logout(environment.strategyName).subscribe(() => {
      this.router.navigate([environment.loginPath]);
    });
  }

  private spyOnAuthenticationStatus(): void {
    this.nbAuthService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      this._isAuthenticated = token && token.isValid();
      this.user$.next(token.getPayload());
      this.email = token.getPayload()?.email;
      this.id = token.getPayload()?.user_id;
    });
  }
}
