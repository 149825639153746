import { Action } from '@ngrx/store';
import { CustomCurrency } from '@pageProjects/models/custom-currency';
import { Currency } from '@pageProjects/models/currency';
import { PayloadAction } from '@core/models/payload-action';

export enum CurrencyActionTypes {
  LOAD = '[CURRENCY] LOAD CURRENCIES',
  ADDED = '[CURRENCY API] ADDED',
  MODIFIED = '[CURRENCY API] MODIFIED',
  REMOVED = '[CURRENCY API] REMOVED',
  API_ERROR = '[CURRENCY API] ERROR',
  CREATED = '[CURRENCY] CREATED',
  CREATED_SUCCESS = '[CURRENCY] CREATED SUCCESS',
  CREATED_FAIL = '[CURRENCY] CREATED FAIL',
  DELETED = '[CURRENCY] DELETED',
  DELETED_SUCCESS = '[CURRENCY] DELETED SUCCESS',
  DELETED_FAIL = '[CURRENCY] DELETED FAIL',
  UPDATED = '[CURRENCY] UPDATED',
  UPDATED_SUCCESS = '[CURRENCY] UPDATED SUCCESS',
  UPDATED_FAIL = '[CURRENCY] UPDATED FAIL',
  CLEAR = '[CURRENCY] CLEAR',
  LOAD_NBP = '[CURRENCY] LOAD NBP'
}

export class Load implements Action {
  readonly type = CurrencyActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class LoadNbp implements Action {
  readonly type = CurrencyActionTypes.LOAD_NBP;

  constructor(public payload: Currency[]) {}
}

export class Added implements Action {
  readonly type = CurrencyActionTypes.ADDED;

  constructor(public payload: CustomCurrency) {}
}

export class Modified implements Action {
  readonly type = CurrencyActionTypes.MODIFIED;

  constructor(public payload: CustomCurrency) {}
}

export class Removed implements Action {
  readonly type = CurrencyActionTypes.REMOVED;

  constructor(public payload: CustomCurrency) {}
}

export class ApiError implements Action {
  readonly type = CurrencyActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = CurrencyActionTypes.CREATED;

  constructor(public payload: Partial<CustomCurrency>) {}
}

export class CreatedSuccess implements Action {
  readonly type = CurrencyActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = CurrencyActionTypes.CREATED_FAIL;
}

export class Deleted implements Action {
  readonly type = CurrencyActionTypes.DELETED;

  constructor(public projectId: string, public currencyId: string) {}
}

export class DeletedSuccess implements Action {
  readonly type = CurrencyActionTypes.DELETED_SUCCESS;
}

export class DeletedFail implements Action {
  readonly type = CurrencyActionTypes.DELETED_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = CurrencyActionTypes.UPDATED;

  constructor(public payload: Partial<CustomCurrency>) {}
}

export class UpdatedSuccess implements Action {
  readonly type = CurrencyActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = CurrencyActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = CurrencyActionTypes.CLEAR;
}

export type CurrencyActions =
  | Load
  | LoadNbp
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Deleted
  | DeletedSuccess
  | DeletedFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear;
