import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLeaderChecklist from '@app/store/reducers/leader-checklist.reducer';
import {
  LeaderChecklist,
  LeaderChecklistAnswer,
  LeaderChecklistType
} from '@pageProjects/models/leader-checklist';

export const selectLeaderChecklistState =
  createFeatureSelector<fromLeaderChecklist.LeaderChecklistState>('leaderChecklist');

export const selectAllLeaderChecklist = createSelector(
  selectLeaderChecklistState,
  fromLeaderChecklist.selectAll
);

export const selectLeaderChecklist = createSelector(
  selectAllLeaderChecklist,
  (state) => ({ ...initialLeaderChecklist, ...state[0] } ?? initialLeaderChecklist)
);

const initialLeaderChecklist = {
  estimationProcessValidity: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  estimationEvaluation: { type: LeaderChecklistType.RADIO, value: LeaderChecklistAnswer.UNDEFINED },
  estimationElementsAnalysisMeetingOverhead: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  estimationElementsAnalysisMeetingOverheadComment: {
    type: LeaderChecklistType.INPUT,
    value: null
  },
  estimationElementsAnalysisSprintLength: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  estimationElementsAnalysisSprintLengthComment: { type: LeaderChecklistType.INPUT, value: null },
  estimationElementsAnalysisRoles: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  teamCompositionAnalysis: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  teamCompositionAnalysisCommentQA: { type: LeaderChecklistType.INPUT, value: null },
  teamCompositionAnalysisCommentLeader: { type: LeaderChecklistType.INPUT, value: null },
  teamCompositionAnalysisReason: { type: LeaderChecklistType.INPUT, value: null },
  risksAnalysed: { type: LeaderChecklistType.RADIO, value: LeaderChecklistAnswer.UNDEFINED },
  risksCooperation: { type: LeaderChecklistType.RADIO, value: null },
  risksDescription: { type: LeaderChecklistType.INPUT, value: null },
  risksUnclearRequirements: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  risksUnclearRequirementsAffection: { type: LeaderChecklistType.INPUT, value: null },
  risksNonFunctionalRequirements: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  technicalRisksCooperation: { type: LeaderChecklistType.RADIO, value: null },
  technicalRisksDescription: { type: LeaderChecklistType.INPUT, value: null },
  fillingPerson: { type: LeaderChecklistType.INPUT, value: null },
  roleResponsibleForTests: { type: LeaderChecklistType.INPUT, value: null },
  teamLeadershipRole: { type: LeaderChecklistType.INPUT, value: null },
  projectPlanPrepare: { type: LeaderChecklistType.RADIO, value: LeaderChecklistAnswer.UNDEFINED },
  projectPlanCheckpoints: { type: LeaderChecklistType.RADIO, value: null },
  projectPlanCheckpointsComment: { type: LeaderChecklistType.INPUT, value: null },
  projectPlanMilestones: { type: LeaderChecklistType.RADIO, value: null },
  projectPlanMilestonesComment: { type: LeaderChecklistType.INPUT, value: null },
  projectPlanFinalAcceptance: { type: LeaderChecklistType.RADIO, value: null },
  projectPlanFinalAcceptanceComment: { type: LeaderChecklistType.INPUT, value: null },
  projectPlanConfirmed: { type: LeaderChecklistType.RADIO, value: null },
  projectPlanConfirmedAdopt: { type: LeaderChecklistType.INPUT, value: null },
  projectPlanConfirmedApproach: { type: LeaderChecklistType.INPUT, value: null },
  costOptimizationPurchase: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  costOptimizationPurchaseComment: { type: LeaderChecklistType.INPUT, value: null },
  costOptimizationResearch: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  costOptimizationResearchComment: { type: LeaderChecklistType.INPUT, value: null },
  additionalCostInProject: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  },
  additionalCostInProjectComment: { type: LeaderChecklistType.INPUT, value: null },
  tableRiskChecked: {
    type: LeaderChecklistType.RADIO,
    value: LeaderChecklistAnswer.UNDEFINED
  }
} as LeaderChecklist;
