import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { Collection } from '@shared/models/collection';
import { GeneralAssumption } from '@pageProjects/models/general-assumption';

@Injectable({
  providedIn: 'root'
})
export class GeneralAssumptionService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(projectId: string): AngularFirestoreCollection<GeneralAssumption> {
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<GeneralAssumption>(Collection.GENERAL_ASSUMPTIONS);
  }

  getAll(projectId: string): Observable<DocumentChangeAction<GeneralAssumption>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  save(generalAssumption: Partial<GeneralAssumption>): Observable<boolean> {
    const result = this.getCollection(generalAssumption.project)
      .add({ ...generalAssumption } as GeneralAssumption)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }

  update(generalAssumption: Partial<GeneralAssumption>): Observable<boolean> {
    const result = this.getCollection(generalAssumption.project)
      .doc(generalAssumption.id)
      .update(generalAssumption)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }
}
