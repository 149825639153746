import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { RolesAndRates } from '@shared/models/roles-and-rates';
import { Collection } from '@shared/models/collection';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesAndRatesService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(): AngularFirestoreCollection<RolesAndRates> {
    return this.afs.collection(Collection.ROLES_AND_RATES);
  }

  getAll(): Observable<DocumentChangeAction<RolesAndRates>[]> {
    return this.getCollection().stateChanges();
  }

  save(rolesAndRates: Partial<RolesAndRates>): Observable<boolean> {
    const result = this.getCollection()
      .add({ ...rolesAndRates } as RolesAndRates)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }

  update(rolesAndRates: Partial<RolesAndRates>): Observable<boolean> {
    const result = this.getCollection()
      .doc(rolesAndRates.id)
      .update(rolesAndRates)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }

  delete(id: string): Observable<boolean> {
    return from(
      this.getCollection()
        .doc(id)
        .delete()
        .then(() => true)
        .catch(() => false)
    );
  }
}
