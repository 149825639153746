import { Action } from '@ngrx/store';
import { User } from '@pageUser/model/user';

export enum UserActionTypes {
  LOADED = '[USER] LOADED USERS',
  ADDED = '[USER API] ADDED',
  MODIFIED = '[USER API] MODIFIED',
  REMOVED = '[USER API] REMOVED',
  API_ERROR = '[USER API] ERROR',
  CREATED = '[USER] CREATED',
  CREATED_SUCCESS = '[USER] CREATED SUCCESS',
  CREATED_FAIL = '[USER] CREATED FAIL',
  UPDATED = '[USER] UPDATED',
  UPDATED_SUCCESS = '[USER] UPDATED SUCCESS',
  UPDATED_FAIL = '[USER] UPDATED FAIL',
  CLEAR = '[USER] CLEAR'
}

export class Loaded implements Action {
  readonly type = UserActionTypes.LOADED;
}

export class Added implements Action {
  readonly type = UserActionTypes.ADDED;

  constructor(public payload: User[]) {}
}

export class Modified implements Action {
  readonly type = UserActionTypes.MODIFIED;

  constructor(public payload: User[]) {}
}

export class Removed implements Action {
  readonly type = UserActionTypes.REMOVED;

  constructor(public payload: User[]) {}
}

export class ApiError implements Action {
  readonly type = UserActionTypes.API_ERROR;
}

export class Created implements Action {
  readonly type = UserActionTypes.CREATED;

  constructor(public payload: Partial<User>) {}
}

export class CreatedSuccess implements Action {
  readonly type = UserActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = UserActionTypes.CREATED_FAIL;
}

export class Updated implements Action {
  readonly type = UserActionTypes.UPDATED;

  constructor(public payload: Partial<User>) {}
}

export class UpdatedSuccess implements Action {
  readonly type = UserActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = UserActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = UserActionTypes.CLEAR;
}

export type UserActions =
  | Loaded
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear;
