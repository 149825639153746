import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { Collection } from '@shared/models/collection';
import { Risk } from '@pageProjects/models/risk';

@Injectable({
  providedIn: 'root'
})
export class RiskService {
  constructor(private afs: AngularFirestore) {}

  private getCollection(projectId: string): AngularFirestoreCollection<Risk> {
    const queryFn = (ref) => ref.where('project', '==', projectId).orderBy('order', 'asc');

    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Risk>(Collection.RISKS, queryFn);
  }

  getAll(projectId: string): Observable<DocumentChangeAction<Risk>[]> {
    return this.getCollection(projectId).stateChanges();
  }

  save(risk: Partial<Risk>): Observable<boolean> {
    const result = this.getCollection(risk.project)
      .add({ ...risk } as Risk)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }

  update(risk: Partial<Risk>): Observable<boolean> {
    const result = this.getCollection(risk.project)
      .doc(risk.id)
      .update(risk)
      .then(() => true)
      .catch(() => false);

    return from(result);
  }

  delete(projectId: string, id: string): Observable<boolean> {
    return from(
      this.getCollection(projectId)
        .doc(id)
        .delete()
        .then(() => true)
        .catch(() => false)
    );
  }
}
