import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTechnicalArea from '@app/store/reducers/technical-area.reducer';
import { TechnicalArea } from '@pageProjects/models/technical-area';

export const selectTechnicalAreasState =
  createFeatureSelector<fromTechnicalArea.TechnicalAreaState>('technicalAreas');

const selectAll = createSelector(selectTechnicalAreasState, fromTechnicalArea.selectAll);

export const selectAllTechnicalAreas = createSelector(selectAll, (areas) =>
  areas.sort(sortByCreated)
);

const sortByCreated = (a: TechnicalArea, b: TechnicalArea) => {
  if (a.created < b.created) {
    return -1;
  } else if (a.created === b.created) {
    return 0;
  } else {
    return 1;
  }
};

export const selectVisibleTechnicalAreas = createSelector(selectAll, (areas) =>
  areas.filter((area) => area.hideArea !== true)
);
