import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRisk from '@app/store/reducers/risk.reducer';

export const selectRiskState = createFeatureSelector<fromRisk.RiskState>('risks');

export const selectAllRisks = createSelector(selectRiskState, fromRisk.selectAll);

export const selectNextRiskOrder = createSelector(
  selectAllRisks,
  (risks) => risks[risks?.length - 1]?.order + 1 || 1
);
