import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TaskFilesService } from '@core/services/task-files.service';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as taskFilesActions from '@app/store/actions/task-files.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectProject } from '@app/store/selectors/project.selectors';
import { ProjectModuleState } from '@app/store/reducers';
import { selectTaskFileByDownloadUrl, selectTaskFiles } from '@app/store';
import * as taskActions from '@app/store/actions/task.actions';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class TaskFilesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly taskFilesService: TaskFilesService,
    private store: Store<ProjectModuleState>
  ) {}

  loadTaskFiles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(taskFilesActions.TaskFilesActionTypes.LOAD),
      map((action: taskFilesActions.Load) => action),
      withLatestFrom(this.store.select(selectProject)),
      switchMap(([, project]) =>
        this.taskFilesService.getAll(project.id).pipe(handleDocumentChanges('[TASK FILES]'))
      )
    )
  );

  upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskFilesActions.TaskFilesActionTypes.UPLOAD),
      map((action: taskFilesActions.Upload) => action),
      withLatestFrom(this.store.select(selectProject)),
      switchMap(([action, project]) =>
        this.taskFilesService.upload(project.id, action.taskId, action.files)
      ),
      mergeMap((result) => result),
      map(([, unhandledFiles]) => new taskFilesActions.UploadSuccess(unhandledFiles)),
      catchError(() => of(new taskFilesActions.UploadFail()))
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskFilesActions.TaskFilesActionTypes.DELETE),
      map((action: taskFilesActions.Delete) => action),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(selectProject),
            this.store.select(selectTaskFileByDownloadUrl(action.downloadUrl))
          )
        )
      ),
      switchMap(([action, project, taskFiles]) =>
        this.taskFilesService.delete(
          project.id,
          action.id,
          action.downloadUrl,
          taskFiles.length === 1
        )
      ),
      map(() => new taskFilesActions.DeleteSuccess()),
      catchError(() => of(new taskFilesActions.DeleteFail()))
    )
  );

  copyTaskFiles = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.TaskActionTypes.COPY_CREATED),
      map((action: taskActions.CopyCreated) => action),
      mergeMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(selectTaskFiles(action.fromId))))
      ),
      switchMap(([action, taskFiles]) => this.taskFilesService.copyFiles(action.taskId, taskFiles)),
      map(() => new taskFilesActions.CopyTaskFilesSuccess()),
      catchError(() => of(new taskFilesActions.CopyTaskFilesFail()))
    )
  );
}
