import { Action } from '@ngrx/store';
import { Estimate } from '@pageProjects/models/estimate';

export enum EstimateHistoryActionTypes {
  LOAD = '[ESTIMATE HISTORY] LOADED LOGS',
  CREATED = '[ESTIMATE HISTORY] CREATED LOGS',
  ADDED = '[ESTIMATE HISTORY API] ADDED',
  API_ERROR = '[ESTIMATE HISTORY API] ERROR',
  CREATED_SUCCESS = '[ESTIMATE HISTORY] CREATED SUCCESS',
  CREATED_FAIL = '[ESTIMATE HISTORY] CREATED FAIL',
  CLEAR = '[ESTIMATE HISTORY] CLEAR'
}

export class Load implements Action {
  readonly type = EstimateHistoryActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = EstimateHistoryActionTypes.ADDED;

  constructor(public payload: Estimate[]) {}
}

export class Created implements Action {
  readonly type = EstimateHistoryActionTypes.CREATED;

  constructor(public log: Estimate) {}
}

export class CreatedSuccess implements Action {
  readonly type = EstimateHistoryActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = EstimateHistoryActionTypes.CREATED_FAIL;
}

export class ApiError implements Action {
  readonly type = EstimateHistoryActionTypes.API_ERROR;
}

export type EstimateHistoryActions =
  | Load
  | Created
  | CreatedSuccess
  | CreatedFail
  | Added
  | ApiError;
