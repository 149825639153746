import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RoleUtils } from '@core/common/role-utils';
import { RolesAndRates } from '@shared/models/roles-and-rates';
import {
  RolesAndRatesActions,
  RolesAndRatesActionTypes
} from '@app/store/actions/roles-and-rates.actions';

export const rolesAndRatesAdapter: EntityAdapter<RolesAndRates> =
  createEntityAdapter<RolesAndRates>();

export interface RolesAndRatesState extends EntityState<RolesAndRates> {}

export const rolesAndRatesInitialState: RolesAndRatesState = rolesAndRatesAdapter.getInitialState(
  {}
);

const mapRolesAndRates = (data: RolesAndRates): RolesAndRates => {
  const mappedRoles = data.roles.map((r) => ({
    ...r,
    levelSplitValue: r.levelSplitValue
      ? r.levelSplitValue
      : RoleUtils.setInitialLevelSplitValue(r.levelSplit, r.levelSplitValue)
  }));

  return {
    ...data,
    roles: mappedRoles
  };
};

export const rolesAndRatesReducer = (
  state: RolesAndRatesState = rolesAndRatesInitialState,
  action: RolesAndRatesActions
): RolesAndRatesState => {
  switch (action.type) {
    case RolesAndRatesActionTypes.ADDED: {
      const roles = action.payload.map((role) => mapRolesAndRates(role));
      return rolesAndRatesAdapter.addMany(roles, state);
    }
    case RolesAndRatesActionTypes.MODIFIED:
      return rolesAndRatesAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: mapRolesAndRates(a)
        })),
        state
      );
    case RolesAndRatesActionTypes.REMOVED:
      return rolesAndRatesAdapter.removeMany(
        action.payload.map((a) => a.id),
        state
      );
    case RolesAndRatesActionTypes.CLEAR:
      return rolesAndRatesAdapter.removeAll(state);
    default:
      return state;
  }
};

export const { selectAll } = rolesAndRatesAdapter.getSelectors();
