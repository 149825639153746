import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as leaderChecklistActions from '@app/store/actions/leader-checklist.actions';
import { LeaderChecklistService } from '@core/services/leader-checklist.service';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class LeaderChecklistEffects {
  constructor(private actions$: Actions, private leaderChecklistService: LeaderChecklistService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leaderChecklistActions.LeaderChecklistActionTypes.LOAD),
      map((action: leaderChecklistActions.Load) => action),
      switchMap((data) =>
        this.leaderChecklistService
          .getAll(data.projectId)
          .pipe(handleDocumentChanges('[LEADER CHECKLIST] API'))
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leaderChecklistActions.LeaderChecklistActionTypes.SAVE),
      map((action: leaderChecklistActions.Save) => action),
      switchMap((data) =>
        this.leaderChecklistService.save(data.payload).pipe(
          map(() => new leaderChecklistActions.SaveSuccess()),
          catchError(() => of(new leaderChecklistActions.SaveFail()))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leaderChecklistActions.LeaderChecklistActionTypes.UPDATE),
      map((action: leaderChecklistActions.Update) => action),
      switchMap((data) =>
        this.leaderChecklistService.update(data.payload).pipe(
          map(() => new leaderChecklistActions.UpdateSuccess()),
          catchError(() => of(new leaderChecklistActions.UpdateFail()))
        )
      )
    )
  );
}
