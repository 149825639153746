export class ProjectUtils {
  static stringIsValidAfterTrim(
    value: string,
    minNameLength: number,
    maxNameLength: number
  ): boolean {
    return value.length >= minNameLength && value.length <= maxNameLength;
  }

  static stringChanged(newValue: string, cacheValue: string): boolean {
    return newValue !== cacheValue;
  }

  static getNewUniqueName(defaultName: string, array: Partial<{ name: string }>[]): string {
    let counter = this.countSameNames(array, defaultName);
    if (counter === 0) {
      counter = 1;
    }
    return this.generateName(defaultName, counter, array);
  }

  static sortByProperty<T>(a: T, b: T, field: string, descendingOrder = false): number {
    return this.sortValue(a[field as keyof T], b[field as keyof T], descendingOrder);
  }

  static sortByValue<T>(
    a: T,
    b: T,
    valueSelector: (obj: T) => unknown,
    descendingOrder = false
  ): number {
    const valueA = valueSelector(a);
    const valueB = valueSelector(b);
    return this.sortValue(valueA, valueB, descendingOrder);
  }

  private static sortValue(value1: unknown, value2: unknown, descendingOrder: boolean): number {
    if (value1 < value2) {
      return descendingOrder ? 1 : -1;
    }

    if (value1 > value2) {
      return descendingOrder ? -1 : 1;
    }

    return 0;
  }

  static chunkArray<T>(array: T[], chunkSize): T[][] {
    const result = new Array<T[]>();
    const arrayCopy = [...array];

    while (arrayCopy.length > 0) {
      const chunk = arrayCopy.splice(0, chunkSize);
      result.push(chunk);
    }

    return result;
  }

  private static countSameNames(array: Partial<{ name: string }>[], defaultName: string): number {
    return array
      .map((item) => item.name)
      .filter((name) => name.toLowerCase().startsWith(defaultName.toLowerCase())).length;
  }

  private static generateName(
    name: string,
    index: number,
    array: Partial<{ name: string }>[]
  ): string {
    const compoundName = `${name} ${index}`;
    const conflict = array
      .map((item) => item.name)
      .filter((itemName) => itemName === compoundName).length;

    if (conflict > 0) {
      return this.generateName(name, ++index, array);
    }

    return compoundName;
  }

  static getMaxOrder(array: Partial<{ order: number }>[]): number {
    let maxOrder = 0;
    if (array && array.length > 0) {
      maxOrder = Math.max(...array.map((item) => item.order));
      maxOrder++;
    }
    return maxOrder;
  }

  static convertDates<T>(payload: T): T {
    const project: T = { ...payload };

    Object.assign(project, payload);

    Object.keys(payload)
      .filter((key) => typeof payload[key]?.toDate === 'function')
      .forEach((key) => {
        project[key] = payload[key].toDate();
      });
    return project;
  }

  static guidGenerator() {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }

  static getFullDate(date: Date): string {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();

    return `${dd}.${mm}.${yyyy}, ${date.getHours()}:${date.getMinutes()}`;
  }
}
