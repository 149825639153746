import { Action } from '@ngrx/store';
import { Phase } from '@pageProjects/models/phase';
import { PayloadAction } from '@core/models/payload-action';

export enum PhaseActionTypes {
  LOAD = '[PHASE] LOAD PHASES',
  ADDED = '[PHASE API] ADDED',
  MODIFIED = '[PHASE API] MODIFIED',
  REMOVED = '[PHASE API] REMOVED',
  API_ERROR = '[PHASE API] ERROR',
  CREATED = '[PHASE] CREATED',
  CREATED_SUCCESS = '[PHASE] CREATED SUCCESS',
  CREATED_FAIL = '[PHASE] CREATED FAIL',
  DELETED = '[PHASE] DELETED',
  DELETED_SUCCESS_REMOVE_PHASE_ASSOCIATION = '[PHASE] DELETED SUCCESS REMOVE PHASE ASSOCIATION',
  DELETED_FAIL = '[PHASE] DELETED FAIL',
  REMOVE_PHASE_ASSOCIATION_SUCCESS = '[PHASE] REMOVE PHASE ASSOCIATION SUCCESS',
  REMOVE_PHASE_ASSOCIATION_FAIL = '[PHASE] REMOVE PHASE ASSOCIATION FAIL',
  UPDATED = '[PHASE] UPDATED',
  UPDATED_SUCCESS = '[PHASE] UPDATED SUCCESS',
  UPDATED_FAIL = '[PHASE] UPDATED FAIL',
  CLEAR = '[PHASE] CLEAR',
  SET_CREATED_PHASE_ID = '[PHASE] SET CREATED PHASE ID',
  CLEAR_CREATED_PHASE_ID = '[PHASE] CLEAR CREATED PHASE ID',
  REORDERED = '[PHASE] REORDERED',
  BATCH_UPDATED = '[PHASE] BATCH UPDATED'
}

export class Load implements Action {
  readonly type = PhaseActionTypes.LOAD;
  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = PhaseActionTypes.ADDED;
  constructor(public payload: Phase[]) {}
}

export class Modified implements Action {
  readonly type = PhaseActionTypes.MODIFIED;
  constructor(public payload: Phase[]) {}
}

export class Removed implements Action {
  readonly type = PhaseActionTypes.REMOVED;
  constructor(public payload: Phase[]) {}
}
export class ApiError implements Action {
  readonly type = PhaseActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = PhaseActionTypes.CREATED;
  constructor(public payload: Partial<Phase>) {}
}

export class CreatedSuccess implements Action {
  readonly type = PhaseActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = PhaseActionTypes.CREATED_FAIL;
}

export class Deleted implements Action {
  readonly type = PhaseActionTypes.DELETED;
  constructor(public projectId: string, public phaseId: string) {}
}

export class DeletedSuccessRemovePhaseAssociation implements Action {
  readonly type = PhaseActionTypes.DELETED_SUCCESS_REMOVE_PHASE_ASSOCIATION;
  constructor(public projectId: string, public phaseId: string) {}
}

export class DeletedFail implements Action {
  readonly type = PhaseActionTypes.DELETED_FAIL;
}

export class RemovePhaseAssociationSuccess implements Action {
  readonly type = PhaseActionTypes.REMOVE_PHASE_ASSOCIATION_SUCCESS;
}

export class RemovePhaseAssociationFail implements Action {
  readonly type = PhaseActionTypes.REMOVE_PHASE_ASSOCIATION_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = PhaseActionTypes.UPDATED;
  constructor(public payload: Partial<Phase>) {}
}

export class UpdatedSuccess implements Action {
  readonly type = PhaseActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = PhaseActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = PhaseActionTypes.CLEAR;
}

export class SetCreatedPhaseId implements Action {
  readonly type = PhaseActionTypes.SET_CREATED_PHASE_ID;
  constructor(public createdPhaseId: string) {}
}

export class ClearCreatedPhaseId implements Action {
  readonly type = PhaseActionTypes.CLEAR_CREATED_PHASE_ID;
}

export class Reordered implements Action {
  readonly type = PhaseActionTypes.REORDERED;

  constructor(public previousIndex: number, public newIndex: number) {}
}

export class BatchUpdated implements PayloadAction {
  readonly type = PhaseActionTypes.BATCH_UPDATED;

  constructor(public payload: Partial<Phase>[]) {}
}

export type PhaseActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | Deleted
  | DeletedSuccessRemovePhaseAssociation
  | DeletedFail
  | RemovePhaseAssociationSuccess
  | RemovePhaseAssociationFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear
  | SetCreatedPhaseId
  | ClearCreatedPhaseId
  | Reordered
  | BatchUpdated;
