import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarContainerComponent } from './sidebar-container.component';
import { SidebarHostDirective } from './sidebar-host.directive';

@NgModule({
  declarations: [SidebarContainerComponent, SidebarHostDirective],
  exports: [SidebarContainerComponent],
  imports: [CommonModule]
})
export class SidebarModule {}
