import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RiskActions, RiskActionTypes } from '@app/store/actions/risk.actions';
import { Risk } from '@pageProjects/models/risk';

export const riskAdapter: EntityAdapter<Risk> = createEntityAdapter<Risk>();

export interface RiskState extends EntityState<Risk> {}

export const riskInitialState: RiskState = riskAdapter.getInitialState({});

export const riskReducer = (
  state: RiskState = riskInitialState,
  action: RiskActions
): RiskState => {
  switch (action.type) {
    case RiskActionTypes.ADDED:
      return riskAdapter.addMany(action.payload, state);
    case RiskActionTypes.MODIFIED:
      return riskAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        state
      );
    case RiskActionTypes.REMOVED:
      return riskAdapter.removeMany(
        action.payload.map((a) => a.id),
        state
      );
    case RiskActionTypes.CLEAR:
      return riskAdapter.removeAll(state);
    default:
      return state;
  }
};

export const { selectAll } = riskAdapter.getSelectors();
