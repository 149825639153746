import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as adminUserActions from '@app/store/actions/admin-user.actions';
import { AdminUserService } from '@core/services/admin-user.service';
import { AdminUser } from '@pageProjects/models/admin-user';
import { AuthService } from '@core/services/auth/auth.service';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import * as projectActions from '@app/store/actions/project.actions';
import * as logActions from '@app/store/actions/log.actions';
import { of } from 'rxjs';

@Injectable()
export class AdminUserEffects {
  constructor(
    private actions$: Actions,
    private adminUserService: AdminUserService,
    private authService: AuthService,
    private afs: AngularFirestore
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUserActions.AdminUserActionTypes.LOAD),
      map((action: adminUserActions.Load) => action),
      switchMap(() =>
        this.adminUserService.getAll().pipe(
          mergeMap((actions: DocumentChangeAction<AdminUser>[]) => {
            if (actions.length === 0) {
              return [{ type: 'added', payload: null }];
            }

            return actions;
          }),
          map((action) => {
            const type = `[ADMIN USER] API ${action.type.toUpperCase()}`;

            if (!action.payload) {
              return { type, payload: { id: this.afs.createId() } };
            }

            const data: AdminUser = action.payload.doc.data();
            this.authService.isAdmin$.next(!!data.ids.find((id) => id === this.authService.id));

            return {
              type,
              payload: {
                ...data,
                isLoaded: true,
                id: action.payload.doc.id
              }
            };
          }),
          catchError(() => of(new adminUserActions.ApiError()))
        )
      )
    )
  );

  afterLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUserActions.AdminUserActionTypes.ADDED),
      map((action: adminUserActions.Added) => action),
      switchMap(() => [new projectActions.LoadAll(), new logActions.Load()])
    )
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUserActions.AdminUserActionTypes.CLEAR),
      map((action: adminUserActions.Clear) => action),
      tap(() => this.authService.isAdmin$.next(false)),
      switchMap(() => [new projectActions.Clear(), new logActions.Clear()])
    )
  );
}
