import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { Collection } from '@shared/models/collection';
import { Role } from '@pageProjects/models/role';
import { EMPTY, from, iif, Observable, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private afs: AngularFirestore) {}

  add(role: Partial<Role>): Observable<{
    roleId: string;
  }> {
    return from(
      this.getCollection(role.project)
        .add({ ...role } as Role)
        .catch((e) => e)
        .then((docRef: DocumentReference<Role>) => ({ roleId: docRef.id }))
    );
  }

  update(role: Partial<Role>): Observable<any> {
    const updatedRoleData = { ...role };
    delete updatedRoleData.id;

    return from(
      this.getCollection(role.project)
        .doc<Role>(`${role.id}`)
        .update({
          ...updatedRoleData
        })
        .catch((e) => e)
        .then(() => 'role updated')
    );
  }

  delete(roleId: string, projectId: string): Observable<string[]> {
    return from(
      this.getCollection(projectId)
        .doc(roleId)
        .delete()
        .catch((e) => e)
        .then(() => [roleId, projectId])
    );
  }

  getAll(projectId: string): Observable<DocumentChangeAction<Role>[]> {
    return this.getCollectionFilterByProject(projectId).stateChanges();
  }

  removeProjectRoles(projectId: string): Observable<any[] | Observable<never>> {
    return this.getCollectionFilterByProject(projectId)
      .get()
      .pipe(
        switchMap((roles) => iif(() => roles.empty, of(EMPTY), this.removeRoles(roles, projectId)))
      );
  }

  updateRoleRateByCurrency(projectId: string): Observable<boolean> {
    return this.getCollection(projectId)
      .get()
      .pipe(
        switchMap((roles) =>
          iif(
            () => roles.empty,
            of(EMPTY),
            this.updateRoles(roles, { finalRate: {}, rates: {}, project: projectId })
          )
        )
      );
  }

  updateRoleDiscoveryRateByCurrency(projectId: string): Observable<boolean> {
    return this.getCollection(projectId)
      .get()
      .pipe(
        switchMap((roles) =>
          iif(
            () => roles.empty,
            of(EMPTY),
            this.updateRoles(roles, { discoveryRates: {}, project: projectId })
          )
        )
      );
  }

  addBatch(roles: Role[]): Observable<boolean> {
    const batch = this.afs.firestore.batch();

    roles.forEach((role) => {
      const ref = this.getCollection(role.project).doc().ref;
      batch.set(ref, role);
    });

    return from(
      batch
        .commit()
        .then(() => true)
        .catch(() => false)
    );
  }

  private getCollection(projectId: string): AngularFirestoreCollection<Role> {
    if (!projectId) {
      throw new Error('project Id not defined!');
    }
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Role>(Collection.ROLES);
  }

  private updateRoles(roles: QuerySnapshot<Role>, roleUpdate: Partial<Role>): Observable<any> {
    const updateRequests: Observable<string>[] = [];
    roles.docs.forEach((role) => {
      updateRequests.push(this.update({ id: role.id, ...roleUpdate }));
    });
    return zip(...updateRequests);
  }

  private getCollectionFilterByProject(projectId: string): AngularFirestoreCollection<Role> {
    return this.getCollection(projectId);
  }

  private removeRoles(roles: QuerySnapshot<Role>, projectId: string): Observable<string[][]> {
    const roleDeleteRequests: Observable<string[]>[] = [];
    roles.docs.forEach((role) => {
      roleDeleteRequests.push(this.delete(role.id, projectId));
    });
    return zip(...roleDeleteRequests);
  }
}
