import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromConfig from '@app/store/reducers/phase-support-role-config.reducer';
import * as fromStore from '@app/store/selectors/index';
import { PhaseSupportRoleConfig } from '@pageProjects/models/phase-support-role-config';

export const selectConfigState =
  createFeatureSelector<fromConfig.PhaseSupportRoleConfigState>('phaseSupportRoleConfig');

export const selectAllConfigs = createSelector(selectConfigState, fromConfig.selectAll);

export const selectConfigForPhase = (phase: string, supportOnly: boolean = false) =>
  createSelector(
    selectAllConfigs,
    fromStore.selectSortedRoles,
    fromStore.selectProject,
    (configs, roles, project) => {
      const phaseConfigs: { [key: string]: PhaseSupportRoleConfig } = {};

      configs.filter((config) => config.phase === phase).forEach((c) => (phaseConfigs[c.role] = c));

      roles
        .filter((role) => (supportOnly ? role.support === supportOnly : true))
        .forEach((role) => {
          if (!phaseConfigs.hasOwnProperty(role.id)) {
            phaseConfigs[role.id] = {
              isUniform: false,
              phase,
              project: project?.id,
              role: role.id
            };
          }
        });

      return phaseConfigs;
    }
  );

export const selectIsUniformForRole = (phase: string, role: string) =>
  createSelector(selectConfigForPhase(phase), (configs) => {
    const uniform: boolean = configs[role].isUniform;
    return uniform;
  });

export const selectRoleConfigForCopy = (fromPhaseId: string, toPhaseId: string) =>
  createSelector(
    selectConfigForPhase(fromPhaseId, true),
    selectConfigForPhase(toPhaseId, true),
    (fromRoleConfig, toRoleCOnfig) => {
      const mappedConfig = Object.entries(toRoleCOnfig).reduce(
        (acc, [role, config]) => ({
          ...acc,
          [role]: { ...config, phase: fromPhaseId }
        }),
        {} as { [key: string]: PhaseSupportRoleConfig }
      );
      return {
        fromConfig: fromRoleConfig,
        toConfig: mappedConfig
      };
    }
  );
