import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTaskFiles from '@app/store/reducers/task-files.reducer';

export const selectTaskFilesState =
  createFeatureSelector<fromTaskFiles.TaskFilesState>('taskFiles');

export const selectAllTaskFiles = createSelector(selectTaskFilesState, fromTaskFiles.selectAll);

export const selectTaskFiles = (taskId: string) =>
  createSelector(selectAllTaskFiles, (taskFiles) =>
    taskFiles.filter((taskFile) => taskFile.task === taskId)
  );

export const selectUnhandledFiles = createSelector(
  selectTaskFilesState,
  (state) => state.unhandledFiles
);

export const selectTaskFileByDownloadUrl = (downloadUrl: string) =>
  createSelector(selectAllTaskFiles, (taskFiles) =>
    taskFiles.filter((taskFile) => taskFile.downloadUrl === downloadUrl)
  );
