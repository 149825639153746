import { EstimateGroup } from '@app/store/selectors/calculation-helpers/estimate-group';
import { AreaFteGroup } from '@app/store/selectors/calculation-helpers/area-fte-group';
import { PhaseSupportRoleConfig } from '@pageProjects/models/phase-support-role-config';
import { Role } from '@pageProjects/models/role';
import { Fte } from '@pageProjects/models/fte';
import { TechnicalArea } from '@pageProjects/models/technical-area';
import { EstimateForCalculation } from './estimateForCalculation';
import { PhaseAreaEfforts } from './phase-area-efforts';
import { Phase } from '@pageProjects/models/phase';
import { TaskType } from '../../../pages/projects/models/task';
export type CalculationStatus = 'valid' | 'invalid_fte_setup';

export class CalculationLogic {
  private estimates: EstimateForCalculation[];
  private estimatesForVariant: EstimateForCalculation[];
  private ftes: Fte[];
  private techAreaMap: Map<string, TechnicalArea>;
  private finalCertainty: number;
  private firstPhase: string;

  constructor(
    public readonly orderedPhases: Phase[],
    private estimatesOrigin: EstimateForCalculation[],
    private readonly roles: Role[],
    private ftesOrigin: Fte[],
    private readonly overhead: number,
    public readonly startDate: Date,
    private readonly sprint0: number,
    private readonly chargability: number,
    private readonly phaseRoleConfigs: PhaseSupportRoleConfig[],
    public techAreas?: TechnicalArea[]
  ) {
    const techAreaHideMap: Map<string, boolean> = new Map<string, boolean>();
    this.techAreaMap = new Map<string, TechnicalArea>();
    if (techAreas) {
      techAreas.forEach((ta) => {
        techAreaHideMap.set(ta.id, ta.hideArea);
        this.techAreaMap.set(ta.id, ta);
      });
      this.ftes = ftesOrigin.filter((fte) => !techAreaHideMap.get(fte.area));
      this.estimates = estimatesOrigin.filter((e) => !techAreaHideMap.get(e.technicalArea));
    } else {
      this.ftes = ftesOrigin;
      this.estimates = estimatesOrigin;
    }

    this.firstPhase =
      orderedPhases.find((p) => p.enabled ?? true)?.id ??
      (orderedPhases.length > 0 ? orderedPhases[0].id : undefined);
  }

  doNotHideTechAreas() {
    this.ftes = this.ftesOrigin;
    this.estimates = this.estimatesOrigin;
  }

  setFinalCertainty(finalCertainty: number) {
    this.finalCertainty = finalCertainty;
  }

  getGroupedForAllPhases(
    variant: string,
    excludeEmpty?: boolean,
    excludeMitigationTasks?: boolean
  ) {
    if (variant) {
      this.estimatesForVariant = this.estimates.filter(
        (e) => e.variants.findIndex((v) => v === variant) > -1
      );
    } else {
      this.estimatesForVariant = this.estimates;
    }

    const phaseMap = new Map<string, PhaseAreaEfforts>();
    let date = this.startDate;
    this.orderedPhases.forEach((phase) => {
      const grouped = this.getGrouped(phase.id, date, excludeEmpty, excludeMitigationTasks);
      if (grouped) {
        phaseMap.set(phase.id, grouped);
        const phaseEndDate = grouped.getMaxAreaEffort()?.duration.getEndDate();
        if ((phase.enabled ?? true) && phaseEndDate !== undefined) {
          date = phaseEndDate;
        }
      }
    });
    return phaseMap;
  }

  fteSumForPhase(phaseId: string) {
    const pFtes = this.ftes.filter((fte) => fte.phase === phaseId);
    const phaseConfigs: { [key: string]: PhaseSupportRoleConfig } = {};
    this.phaseRoleConfigs
      .filter((config) => config.phase === phaseId)
      .forEach((c) => (phaseConfigs[c.role] = c));
    this.roles.forEach((role) => {
      if (!phaseConfigs.hasOwnProperty(role.id)) {
        phaseConfigs[role.id] = {
          isUniform: false,
          phase: phaseId,
          project: undefined,
          role: role.id
        };
      }
    });
    return pFtes
      .filter((fte) => phaseConfigs[fte.role]?.isUniform === (fte.area === null))
      .map((fte) => fte.fte)
      .reduce((a, b) => a + b, 0);
  }

  private getGrouped(
    phaseId: string,
    startDate: Date,
    excludeEmpty?: boolean,
    excludeMitigationTasks?: boolean
  ): PhaseAreaEfforts {
    const pFtes = this.ftes.filter((fte) => fte.phase === phaseId);
    const prc = this.phaseRoleConfigs.filter((config) => config.phase === phaseId);
    const currentSprint0 = this.firstPhase === phaseId ? this.sprint0 : 0;
    const estimatesForPhase = this.estimatesForVariant.filter(
      (e) =>
        e.phase === phaseId && (excludeMitigationTasks ? e.taskType !== TaskType.Mitigation : true)
    );
    if (excludeEmpty && (pFtes.length === 0 || estimatesForPhase.length === 0)) {
      return null;
    }

    const areaEfforts = estimatesForPhase
      .reduce(this.groupingFunction.bind(this), [])
      .map((eg: EstimateGroup) => eg.reduced(this.techAreaMap?.get(eg.area)))
      .map((raw, _i, all) => {
        const allCount = all.filter((r) => !r.areaObject?.hideCost).length;

        return new AreaFteGroup(
          raw.area,
          raw.areaObject,
          raw.workdaysByTask,
          raw.workdaysByType,
          raw.workdays,
          this.roles,
          this.overhead,
          pFtes,
          prc,
          allCount,
          this.chargability,
          currentSprint0,
          startDate
        );
      })
      .map((afg: AreaFteGroup) => afg.reduced());

    return areaEfforts ? new PhaseAreaEfforts(areaEfforts) : undefined;
  }

  private groupingFunction(acc: EstimateGroup[], current: EstimateForCalculation) {
    let group = acc.find((e) => e.area === current.technicalArea);

    if (!group) {
      group = new EstimateGroup(current.technicalArea, this.finalCertainty);
      acc.push(group);
    }

    group.add(current);

    return acc;
  }
}
