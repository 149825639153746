import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTask from '@app/store/reducers/task.reducer';
import { selectAllPhasess } from '@app/store/selectors/phase.selectors';
import { TaskType } from '@pageProjects/models/task';

export const selectTasksState = createFeatureSelector<fromTask.TaskState>('tasks');

export const selectAllTasks = createSelector(selectTasksState, fromTask.selectAll);

export const selectTaskCount = createSelector(selectAllTasks, (tasks) => tasks.length);

export const selectTaskById = (taskId: string) =>
  createSelector(selectAllTasks, selectAllPhasess, (tasks, allPhases) => {
    const task = tasks.find((t) => t.id === taskId);
    const phaseName = allPhases.find((phase) => phase.id === task.phase)?.name;
    return { ...task, phaseName };
  });

export const selectTasksByPhaseId = (phaseId: string | 'all', sort = false) =>
  createSelector(selectAllTasks, (tasks) => {
    let filteredTasks = tasks
      .filter((task) => (phaseId === 'all' ? task.phase !== null : task.phase === phaseId))
      .map((task) => task);
    if (sort) {
      filteredTasks = filteredTasks.sort((a, b) => a.order - b.order);
    }
    return filteredTasks;
  });

export const selectTasksByPhaseIdAndVariant = (phaseId: string | 'all', variant?: string) =>
  createSelector(selectTasksByPhaseId(phaseId), (tasks) => {
    if (variant) {
      return tasks.filter((task) => task.variants.findIndex((v) => v === variant) > -1);
    }
    return tasks;
  });

export const selectTasksByVariant = (variant?: string) =>
  createSelector(selectAllTasks, (tasks) =>
    variant ? tasks.filter((task) => task.variants.findIndex((v) => v === variant) > -1) : tasks
  );

export const selectMaxTaskOrderByPhase = (phaseId: string) =>
  createSelector(selectTasksByPhaseId(phaseId), (tasks) => {
    const maxOrder = Math.max(...tasks.map((task) => task.order));
    return isFinite(maxOrder) ? maxOrder : 0;
  });

export const selectOrphanedTasks = createSelector(selectAllTasks, (tasks) =>
  tasks.filter((task) => !task.phase)
);

export const selectPhaseTasksWithOrderHigherThan = (phaseId: string, order: number) =>
  createSelector(selectTasksByPhaseId(phaseId), (tasks) =>
    tasks.filter((task) => task.order > order)
  );

export const selectTaskAreas = createSelector(selectAllTasks, (tasks) => {
  const areasArray = tasks
    .map((task) => task.area)
    .flat()
    .filter((area) => area !== null)
    .filter((area) => area !== '')
    .filter((area) => area !== undefined);
  const areasSet = new Set(areasArray);
  return [...areasSet];
});

export const selectAvailableTaskAreasForTask = (taskId: string) =>
  createSelector(selectTaskAreas, selectTaskById(taskId), (areas, task) => {
    if (!task) {
      return [];
    }
    return areas.filter((area) => area !== task.area);
  });

export const selectAllTasksWithPhase = createSelector(selectAllTasks, (tasks) =>
  tasks.filter((task) => !!task.phase)
);

export const selectTaskVariants = createSelector(selectAllTasks, (tasks) => {
  const variantsArray = tasks.map((task) => task.variants).flat();
  const variantsSet = new Set(variantsArray);
  return [...variantsSet].sort();
});

export const selectAvailableTaskVariantsForTask = (taskId: string) =>
  createSelector(selectTaskVariants, selectTaskById(taskId), (variants, task) => {
    if (!task) {
      return [];
    }
    const taskVariants = task.variants;
    return variants.filter(
      (variant) =>
        taskVariants.find((tv) => variant.trim().toLowerCase() === tv.trim().toLowerCase()) ===
        undefined
    );
  });

export const selectCreateTaskId = createSelector(
  selectTasksState,
  (taskState) => taskState.createdTaskId
);

export const selectMitigationTasks = createSelector(selectAllTasks, (tasks) =>
  tasks.filter((task) => task.type === TaskType.Mitigation)
);
