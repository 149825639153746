/**
 * export const logAdapter: EntityAdapter<Log> = createEntityAdapter<Log>();
 *
 * export interface LogState extends EntityState<Log> {
 * }
 *
 * export const logInitialState: LogState = logAdapter.getInitialState({});
 */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Fte } from '@pageProjects/models/fte';
import { FteActions, FteActionTypes } from '@app/store/actions/fte.actions';

export const fteAdapter: EntityAdapter<Fte> = createEntityAdapter<Fte>();

export interface FteState extends EntityState<Fte> {}

export const fteInitialState: FteState = fteAdapter.getInitialState({});

export const fteReducer = (state: FteState = fteInitialState, action: FteActions): FteState => {
  switch (action.type) {
    case FteActionTypes.ADDED: {
      return fteAdapter.addMany(action.payload, state);
    }
    case FteActionTypes.MODIFIED:
      return fteAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        state
      );
    case FteActionTypes.CLEAR:
      return fteAdapter.removeAll(state);
    case FteActionTypes.REMOVED:
      return fteAdapter.removeMany(
        action.payload.map((a) => a.id),
        state
      );
    default:
      return state;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = fteAdapter.getSelectors();
