import { Action } from '@ngrx/store';
import { AdminUser } from '@pageProjects/models/admin-user';

export enum AdminUserActionTypes {
  LOAD = '[ADMIN USER] LOAD',
  ADDED = '[ADMIN USER] API ADDED',
  API_ERROR = '[ADMIN USER] API ERROR',
  CLEAR = '[ADMIN USER] CLEAR'
}

export class Load implements Action {
  readonly type = AdminUserActionTypes.LOAD;
}

export class Added implements Action {
  readonly type = AdminUserActionTypes.ADDED;

  constructor(public payload: AdminUser) {}
}

export class Clear implements Action {
  readonly type = AdminUserActionTypes.CLEAR;
}

export class ApiError implements Action {
  readonly type = AdminUserActionTypes.API_ERROR;
}

export type AdminUserActions = Load | Added | Clear | ApiError;
