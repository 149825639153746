import { NbAccessChecker, NbAclService, NbRoleProvider } from '@nebular/security';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class AccessChecker extends NbAccessChecker {
  constructor(roleProvider: NbRoleProvider, acl: NbAclService, private authService: AuthService) {
    super(roleProvider, acl);
  }

  isGranted(permission: string, resource: string): Observable<boolean> {
    if (this.authService.isAdmin$.value) {
      return of(true);
    }

    return super.isGranted(permission, resource);
  }
}
