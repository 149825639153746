import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromEstimate from '@app/store/reducers/estimate.reducer';
import * as fromStore from '@app/store/selectors/index';
import {
  Estimate,
  standardDeviation,
  validateEstimate,
  weightedAverage
} from '@pageProjects/models/estimate';
import { selectFirstPhaseId } from '@app/store';
import {
  EstimateForCalculation,
  EstimateForCalculationWithDetails
} from './calculation-helpers/estimateForCalculation';
import { TaskType } from '@pageProjects/models/task';

export interface PhaseEstimates {
  estimates: Estimate[];
  isFirst: boolean;
}

export const selectEstimateState = createFeatureSelector<fromEstimate.EstimateState>('estimates');

const selectRawEstimates = createSelector(selectEstimateState, fromEstimate.selectAll);

export const selectAllEstimates = createSelector(
  selectRawEstimates,
  fromStore.selectProjectWeights,
  (estimates, weights) =>
    estimates.map((estimate) => {
      const enritched: Estimate = {
        ...estimate,
        average: weightedAverage(estimate, weights),
        deviation: standardDeviation(estimate, weights),
        isEstimated: validateEstimate(estimate)
      };
      return enritched;
    })
);

export const selectAllEstimatesForCalculation = createSelector(
  selectRawEstimates,
  fromStore.selectProjectWeights,
  fromStore.selectAllTasks,
  (estimates, weights, tasks) =>
    estimates
      .map((estimate) => {
        const task = tasks.find((t) => t.id === estimate.task);

        return { estimate, task };
      })
      .filter((item) => item.task && item.task?.phase)
      .map((item) => {
        const enriched: EstimateForCalculation = {
          phase: item.task.phase,
          variants: item.task.variants,
          technicalArea: item.estimate.technicalArea,
          average: weightedAverage(item.estimate, weights),
          deviation: standardDeviation(item.estimate, weights),
          isEstimated: validateEstimate(item.estimate),
          taskType: item.task.type || TaskType.Regular,
          taskId: item.task.id
        };
        return enriched;
      })
);

export const selectRiskEstimatesForCalculation = createSelector(
  selectRawEstimates,
  fromStore.selectProjectWeights,
  fromStore.selectAllTasks,
  (estimates, weights, tasks) =>
    estimates
      .map((estimate) => {
        const task = tasks.find((t) => t.id === estimate.task);

        return { estimate, task };
      })
      .filter((item) => item.task && item.task?.phase && item.task.riskId)
      .map((item) => {
        const enriched: EstimateForCalculation = {
          phase: item.task.phase,
          variants: item.task.variants,
          technicalArea: item.estimate.technicalArea,
          average: weightedAverage(item.estimate, weights),
          deviation: standardDeviation(item.estimate, weights),
          isEstimated: validateEstimate(item.estimate),
          taskType: item.task.type || TaskType.Regular,
          taskId: item.task.id
        };
        return enriched;
      })
);

export const selectAllEstimatesForCalculationWithDetails = createSelector(
  selectRawEstimates,
  fromStore.selectAllTasks,
  (estimates, tasks) =>
    estimates
      .map((estimate) => {
        const task = tasks.find((t) => t.id === estimate.task);

        return { estimate, task };
      })
      .filter((item) => item.task && item.task?.phase)
      .map((item) => {
        const enriched: EstimateForCalculationWithDetails = {
          phase: item.task.phase,
          optimistic: item.estimate.optimistic,
          realistic: item.estimate.realistic,
          pessimistic: item.estimate.pessimistic,
          isEstimated: validateEstimate(item.estimate),
          taskType: item.task.type || TaskType.Regular,
          taskId: item.task.id
        };
        return enriched;
      })
);

export const selectEstimatesForTask = (taskId: string) =>
  createSelector(selectAllEstimates, fromStore.selectProject, (estimates, project) => {
    if (!project) {
      return;
    }

    return estimates.filter((current) => current.task === taskId);
  });

export const selectEstimatesForArea = (technicalArea: string) =>
  createSelector(selectAllEstimates, (estimates) =>
    estimates.filter((estimate) => estimate.technicalArea === technicalArea)
  );

export const selectEstimateById = (id: string) =>
  createSelector(selectAllEstimates, (estimates) => estimates.find((e) => e.id === id));

export const selectEstimatesForPhase = (phaseId: string, variant?: string) =>
  createSelector(
    selectAllEstimates,
    fromStore.selectTasksByPhaseIdAndVariant(phaseId, variant),
    (estimates, tasks) => {
      const taskIds = tasks.map((task) => task.id);
      return estimates.filter((estimate) => taskIds.findIndex((id) => id === estimate.task) > -1);
    }
  );

export const selectEstimatesForVariant = (variant?: string) =>
  createSelector(
    selectAllEstimates,
    fromStore.selectTasksByPhaseIdAndVariant('all', variant),
    fromStore.selectEnabledPhasess,
    (estimates, tasks, phases) => {
      const taskIds = tasks
        .filter((task) => phases.find((p) => p.id === task.phase))
        .map((task) => task.id);
      return estimates.filter((estimate) => taskIds.findIndex((id) => id === estimate.task) > -1);
    }
  );

export const selectEstimatesForVariantAndVisibleAreas = (variant?: string) =>
  createSelector(
    selectAllEstimates,
    fromStore.selectTasksByPhaseIdAndVariant('all', variant),
    fromStore.selectEnabledPhasess,
    fromStore.selectVisibleTechnicalAreas,
    (estimates, tasks, phases, areas) => {
      const taskIds = tasks
        .filter((task) => phases.find((phase) => phase.id === task.phase))
        .map((task) => task.id);

      return estimates.filter(
        (estimate) =>
          estimate.isEstimated &&
          areas.findIndex((area) => area.id === estimate.technicalArea) > -1 &&
          taskIds.findIndex((taskId) => taskId === estimate.task) > -1
      );
    }
  );

export const selectEstimatesByPhase = (variant?: string) =>
  createSelector(
    selectAllEstimates,
    fromStore.selectTasksByVariant(variant),
    selectFirstPhaseId,
    (estimates, tasks, firstPhase) => {
      const estimateMap: Map<string, PhaseEstimates> = new Map<string, PhaseEstimates>();

      estimates.forEach((estimate) => {
        const phaseId = tasks.find((task) => task.id === estimate.task)?.phase;
        if (!phaseId) {
          return;
        }

        if (!estimateMap.has(phaseId)) {
          estimateMap.set(phaseId, {
            isFirst: firstPhase === phaseId,
            estimates: [estimate]
          });
        } else {
          estimateMap.get(phaseId).estimates.push(estimate);
        }
      });

      return estimateMap;
    }
  );

export const selectAverageDeviationForAllTasks = createSelector(
  selectAllEstimates,
  (estimates) =>
    estimates.map((estimate) => estimate.deviation).reduce((p, c) => p + c, 0) / estimates.length
);

export const selectEstimateForTask = (taskId: string, technicalArea: string) =>
  createSelector(selectEstimatesForTask(taskId), fromStore.selectProject, (estimates, project) => {
    if (!project) {
      return;
    }
    const estimate = estimates.find((current) => current.technicalArea === technicalArea);
    const empty: Estimate = {
      optimistic: null,
      pessimistic: null,
      realistic: null,
      project: project.id,
      task: taskId,
      technicalArea,
      initialized: false,
      average: 0,
      deviation: 0,
      isEstimated: false
    };

    if (estimate) {
      const result = { ...estimate, initialized: true };

      if (result.optimistic === undefined) {
        result.optimistic = null;
      }

      if (result.realistic === undefined) {
        result.realistic = null;
      }

      if (result.pessimistic === undefined) {
        result.pessimistic = null;
      }

      return result;
    }

    return empty;
  });
