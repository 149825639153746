import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GeneralAssumption } from '@pageProjects/models/general-assumption';
import {
  GeneralAssumptionActions,
  GeneralAssumptionActionTypes
} from '@app/store/actions/general-assumption.actions';

export const generalAssumptionAdapter: EntityAdapter<GeneralAssumption> =
  createEntityAdapter<GeneralAssumption>();

export interface GeneralAssumptionState extends EntityState<GeneralAssumption> {}

export const generalAssumptionInitialState: GeneralAssumptionState =
  generalAssumptionAdapter.getInitialState({});

export const generalAssumptionReducer = (
  state: GeneralAssumptionState = generalAssumptionInitialState,
  action: GeneralAssumptionActions
): GeneralAssumptionState => {
  switch (action.type) {
    case GeneralAssumptionActionTypes.ADDED:
      return generalAssumptionAdapter.addMany(action.payload, state);
    case GeneralAssumptionActionTypes.MODIFIED:
      return generalAssumptionAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        state
      );
    case GeneralAssumptionActionTypes.CLEAR:
      return generalAssumptionAdapter.removeAll(state);
    default:
      return state;
  }
};

export const { selectAll } = generalAssumptionAdapter.getSelectors();
