import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Task } from '@pageProjects/models/task';
import { TaskActions, TaskActionTypes } from '@app/store/actions/task.actions';

export const taskAdapter: EntityAdapter<Task> = createEntityAdapter<Task>();

export interface TaskState extends EntityState<Task> {
  createdTaskId: string | null;
}

export const taskInitialState: TaskState = taskAdapter.getInitialState({
  createdTaskId: null
});

export const tasksReducer = (
  taskState: TaskState = taskInitialState,
  action: TaskActions
): TaskState => {
  switch (action.type) {
    case TaskActionTypes.ADDED:
      return taskAdapter.addMany(action.payload, taskState);
    case TaskActionTypes.MODIFIED:
      return taskAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        taskState
      );
    case TaskActionTypes.REMOVED:
      return taskAdapter.removeMany(
        action.payload.map((a) => a.id),
        taskState
      );
    case TaskActionTypes.CLEAR: {
      return taskAdapter.removeAll(taskState);
    }
    case TaskActionTypes.SET_CREATED_TASK_ID:
      return {
        ...taskState,
        createdTaskId: action.createdTaskId
      };
    case TaskActionTypes.CLEAR_CREATED_TASK_ID:
      return {
        ...taskState,
        createdTaskId: null
      };
    default:
      return taskState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = taskAdapter.getSelectors();
