import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BusinessLine } from '@shared/models/business-line';
import { BusinessLinesActionTypes, BussinesLinesActions } from '../actions/business-lines.actions';

export const businessLinesAdapter: EntityAdapter<BusinessLine> =
  createEntityAdapter<BusinessLine>();
export interface BusinessLinesState extends EntityState<BusinessLine> {}

export const businessLinesInitialState: BusinessLinesState = businessLinesAdapter.getInitialState(
  {}
);

export const businessLinesReducer = (
  businessLinesState: BusinessLinesState = businessLinesInitialState,
  action: BussinesLinesActions
): BusinessLinesState => {
  switch (action.type) {
    case BusinessLinesActionTypes.ADDED:
      return businessLinesAdapter.addMany(action.payload, businessLinesState);
    case BusinessLinesActionTypes.MODIFIED:
      return businessLinesAdapter.updateMany(
        action.payload.map((a) => ({
          id: a.id,
          changes: a
        })),
        businessLinesState
      );
    case BusinessLinesActionTypes.REMOVED:
      return businessLinesAdapter.removeMany(
        action.payload.map((a) => a.id),
        businessLinesState
      );
    case BusinessLinesActionTypes.CLEAR:
      return businessLinesAdapter.removeAll(businessLinesState);
    default:
      return businessLinesState;
  }
};

export const { selectAll } = businessLinesAdapter.getSelectors();
