export const commonEnvironment = {
  strategyName: 'fp-ad',
  firebase: {
    apiKey: 'AIzaSyCue1YRGLi1RUHuatL0q4VWZ2lkZdW5NKs',
    authDomain: 'fpert-986bd.firebaseapp.com',
    projectId: 'fpert-986bd',
    storageBucket: 'fpert-986bd.appspot.com',
    messagingSenderId: '727208754782',
    appId: '1:727208754782:web:2f00b8382b56a56ce17ecf',
    measurementId: 'G-9PVQVHEFH4'
  },
  azureTenantId: 'd70890a4-3ec5-4da3-b812-61e6b3ff4ae7',
  debounceTime: 300,
  redirectUrl: 'redirect_url',
  loginPath: '/login',
  projectsPath: '/projects'
};
