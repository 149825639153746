import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBusinessLines from '@app/store/reducers/business-lines.reducer';
import { BusinessLine } from '@shared/models/business-line';

export const selectBusinessLinesState =
  createFeatureSelector<fromBusinessLines.BusinessLinesState>('businessLines');

const selectAll = createSelector(selectBusinessLinesState, fromBusinessLines.selectAll);

export const selectAllBusinessLines = createSelector(selectAll, (lines) => lines);
