import { CurrencyEffects } from '@app/store/effects/currency.effects';
import { PhaseEffects } from '@app/store/effects/phases.effects';
import { ProjectEffects } from '@app/store/effects/project.effects';
import { RoleEffects } from '@app/store/effects/role.effects';
import { TaskEffects } from '@app/store/effects/task.effects';
import { TechnicalAreaEffects } from '@app/store/effects/technical-area.effects';
import { LogEffects } from '@app/store/effects/log.effects';
import { EstimateEffects } from '@app/store/effects/estimate.effects';
import { UserInterfaceEffects } from '@app/store/effects/user-interface.effects';
import { PhaseSupportRoleConfigEffects } from '@app/store/effects/phase-support-role-config.effects';
import { FteEffects } from '@app/store/effects/fte.effects';
import { GeneralAssumptionEffects } from '@app/store/effects/general-assumption.effects';
import { LeaderChecklistEffects } from '@app/store/effects/leader-checklist.effects';
import { AdminUserEffects } from '@app/store/effects/admin-user.effects';
import { RolesAndRatesEffects } from '@app/store/effects/roles-and-rates.effects';
import { EstimateHistoryEffects } from '@app/store/effects/estimate-history.effects';
import { BusinessLinesEffects } from '@app/store/effects/business-lines.effects';
import { UserEffects } from '@app/store/effects/user.effects';
import { TaskFilesEffects } from '@app/store/effects/task-files.effects';
import { TaskCommentEffects } from '@app/store/effects/task-comment.effects';
import { RiskEffects } from '@app/store/effects/risk.effects';

export const effects = [
  ProjectEffects,
  PhaseEffects,
  TaskEffects,
  TaskFilesEffects,
  TaskCommentEffects,
  TechnicalAreaEffects,
  RoleEffects,
  CurrencyEffects,
  LogEffects,
  EstimateEffects,
  EstimateHistoryEffects,
  UserInterfaceEffects,
  PhaseSupportRoleConfigEffects,
  FteEffects,
  GeneralAssumptionEffects,
  LeaderChecklistEffects,
  AdminUserEffects,
  RolesAndRatesEffects,
  BusinessLinesEffects,
  UserEffects,
  RiskEffects
];
