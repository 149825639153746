import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomCurrency } from '@pageProjects/models/custom-currency';
import { CurrencyActions, CurrencyActionTypes } from '@app/store/actions/currency.actions';
import { Currency } from '@pageProjects/models/currency';

export const currencyAdapter: EntityAdapter<CustomCurrency> = createEntityAdapter<CustomCurrency>();

export interface CurrencyState extends EntityState<CustomCurrency> {}

export const currencyInitialState: CurrencyState = currencyAdapter.getInitialState({});

export const nbpCurrencyAdapter: EntityAdapter<Currency> = createEntityAdapter<Currency>();

export interface NbpCurrencyState extends EntityState<Currency> {}

export const nbpCurrencyInitialState: NbpCurrencyState = nbpCurrencyAdapter.getInitialState({});

export const nbpCurrenciesReducer = (
  nbpCurrencyState: NbpCurrencyState = nbpCurrencyInitialState,
  action: CurrencyActions
): NbpCurrencyState => {
  switch (action.type) {
    case CurrencyActionTypes.LOAD_NBP:
      return nbpCurrencyAdapter.addMany(action.payload, nbpCurrencyState);
    default:
      return nbpCurrencyState;
  }
};

export const currenciesReducer = (
  currencyState: CurrencyState = currencyInitialState,
  action: CurrencyActions
): CurrencyState => {
  switch (action.type) {
    case CurrencyActionTypes.ADDED:
      return currencyAdapter.addOne(action.payload, currencyState);
    case CurrencyActionTypes.MODIFIED:
      return currencyAdapter.updateOne(
        { id: action.payload.id, changes: action.payload },
        currencyState
      );
    case CurrencyActionTypes.REMOVED:
      return currencyAdapter.removeOne(action.payload.id, currencyState);
    case CurrencyActionTypes.CLEAR: {
      return currencyAdapter.removeAll(currencyState);
    }
    default:
      return currencyState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = currencyAdapter.getSelectors();

export const { selectAll: selectAllNbp } = nbpCurrencyAdapter.getSelectors();
