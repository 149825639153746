import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTaskComment from '@app/store/reducers/task-comment.reducer';
import { selectAllUsers } from '@app/store';
import { TaskCommentVM } from '@pageProjects/models/task-comment';
import { ProjectUtils } from '@pageProjects/tools/utils';

export const selectTaskCommentsState =
  createFeatureSelector<fromTaskComment.TaskCommentState>('taskComments');

export const selectAllTaskComments = createSelector(
  selectTaskCommentsState,
  fromTaskComment.selectAll
);

export const selectTaskCommentsByTaskId = (taskId: string) =>
  createSelector(selectAllTaskComments, selectAllUsers, (taskComments, users) => {
    const comments = taskComments.filter(
      (taskComment) => taskComment.taskId === taskId
    ) as TaskCommentVM[];

    return comments
      .map((c) => ({
        ...c,
        fullName: users.find((u) => u.id === c.userId)?.name || ''
      }))
      .sort((a, b) => ProjectUtils.sortByProperty(a, b, 'created', true));
  });

export const selectTaskCommentsWithUsers = createSelector(
  selectAllTaskComments,
  selectAllUsers,
  (taskComments, users) =>
    taskComments
      .map((taskComment) => ({
        ...taskComment,
        fullName: users.find((u) => u.id === taskComment.userId)?.name || ''
      }))
      .sort((a, b) => ProjectUtils.sortByProperty(a, b, 'created', true))
);
