export enum PhasesCollectionFieldEnum {
  FTES = 'ftes',
  NAME = 'name',
  ORDER = 'order',
  PROJECT = 'project'
}

export enum TasksCollectionFieldEnum {
  AREA = 'area',
  DESCRIPTION = 'description',
  ESTIMATE = 'estimate',
  NAME = 'name',
  ORDER = 'order',
  PHASE = 'phase',
  PROJECT = 'project',
  VARIANTS = 'variants'
}

export enum TechnicalAreasCollectionFieldEnum {
  NAME = 'name',
  PROJECT = 'project'
}

export enum RolesCollectionFieldEnum {
  BUSINESSLINE = 'businessLine',
  COST = 'cost',
  LEVELSPLIT = 'levelSplit',
  NAME = 'name',
  OVERHEAD = 'overhead',
  PROJECT = 'project',
  RATES = 'rates',
  SUPPORT = 'support'
}

export enum CurrenciesCollectionFieldEnum {
  CURRENCY = 'currency',
  PROJECT = 'project',
  RATE = 'rate'
}
