import { PayloadAction } from '@core/models/payload-action';
import { Action } from '@ngrx/store';
import { Fte } from '@pageProjects/models/fte';

export enum FteActionTypes {
  LOAD = '[FTE] LOAD FTES',
  ADDED = '[FTE API] ADDED',
  MODIFIED = '[FTE API] MODIFIED',
  REMOVED = '[FTE API] REMOVED',
  API_ERROR = '[FTE API] ERROR',
  SAVED = '[FTE] Saved',
  SAVED_SUCCESS = '[FTE] Saved SUCCESS',
  SAVED_FAIL = '[FTE] Saved FAIL',
  DELETED = '[FTE] DELETED',
  DELETED_SUCCESS = '[FTE] DELETED SUCCESS',
  DELETED_FAIL = '[FTE] DELETED FAIL',
  UPDATED = '[FTE] UPDATED',
  UPDATED_SUCCESS = '[FTE] UPDATED SUCCESS',
  UPDATED_FAIL = '[FTE] UPDATED FAIL',
  CLEAR = '[FTE] CLEAR',
  COPY = '[FTE] COPY FTES',
  COPY_SUCCESS = '[FTE] COPY SUCCESS',
  COPY_FAIL = '[FTE] COPY FAIL',
  DELETE_FOR_ROLE = '[FTE] DELETE FOR ROLE',
  DELETE_FOR_ROLE_SUCCESS = '[FTE] DELETE FOR ROLE SUCCESS',
  DELETE_FOR_ROLE_FAIL = '[FTE] DELETE FOR ROLE FAIL',
  DELETE_FOR_AREA = '[FTE] DELETE FOR AREA',
  DELETE_FOR_AREA_SUCCESS = '[FTE] DELETE FOR AREA SUCCESS',
  DELETE_FOR_AREA_FAIL = '[FTE] DELETE FOR AREA FAIL'
}

export class Load implements Action {
  readonly type = FteActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = FteActionTypes.ADDED;

  constructor(public payload: Fte[]) {}
}

export class Modified implements Action {
  readonly type = FteActionTypes.MODIFIED;

  constructor(public payload: Fte[]) {}
}

export class Removed implements Action {
  readonly type = FteActionTypes.REMOVED;

  constructor(public payload: Fte[]) {}
}

export class ApiError implements Action {
  readonly type = FteActionTypes.API_ERROR;
}

export class Saved implements PayloadAction {
  readonly type = FteActionTypes.SAVED;
  payload: Partial<Fte>;

  constructor(payload: Partial<Fte>) {
    this.payload = payload;
  }
}

export class SavedSuccess implements Action {
  readonly type = FteActionTypes.SAVED_SUCCESS;
}

export class SavedFail implements Action {
  readonly type = FteActionTypes.SAVED_FAIL;
}

export class Deleted implements Action {
  readonly type = FteActionTypes.DELETED;

  constructor(public id: string) {}
}

export class DeletedFail implements Action {
  readonly type = FteActionTypes.DELETED_FAIL;
}

export class DeletedSuccess implements Action {
  readonly type = FteActionTypes.DELETED_SUCCESS;
}

export class Updated implements PayloadAction {
  readonly type = FteActionTypes.UPDATED;

  constructor(public payload: Partial<Fte>) {}
}

export class UpdatedSuccess implements Action {
  readonly type = FteActionTypes.UPDATED_SUCCESS;
}

export class UpdatedFail implements Action {
  readonly type = FteActionTypes.UPDATED_FAIL;
}

export class Clear implements Action {
  readonly type = FteActionTypes.CLEAR;
}

export class DeleteForRole implements Action {
  readonly type = FteActionTypes.DELETE_FOR_ROLE;
  constructor(public projectId: string, public roleId: string) {}
}

export class DeleteForRoleSuccess implements Action {
  readonly type = FteActionTypes.DELETE_FOR_ROLE_SUCCESS;
}

export class DeleteForRoleFail implements Action {
  readonly type = FteActionTypes.DELETE_FOR_ROLE_FAIL;
}

export class DeleteForArea implements Action {
  readonly type = FteActionTypes.DELETE_FOR_AREA;
  constructor(public projectId: string, public areaId: string) {}
}

export class DeleteForAreaSuccess implements Action {
  readonly type = FteActionTypes.DELETE_FOR_AREA_SUCCESS;
}

export class DeleteForAreaFail implements Action {
  readonly type = FteActionTypes.DELETE_FOR_AREA_FAIL;
}

export class Copy implements Action {
  readonly type = FteActionTypes.COPY;

  constructor(
    public projectId: string,
    public sourcePhase: string,
    public targetPhase: string,
    public setCopying: () => void
  ) {}
}

export class CopySuccess implements Action {
  readonly type = FteActionTypes.COPY_SUCCESS;
}

export class CopyFail implements Action {
  readonly type = FteActionTypes.COPY_FAIL;
}

export type FteActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Saved
  | SavedSuccess
  | SavedFail
  | Deleted
  | DeletedFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Clear
  | DeletedSuccess
  | DeleteForRole
  | DeleteForRoleSuccess
  | DeleteForRoleFail
  | Copy
  | CopySuccess
  | CopyFail;
