import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GeneralAssumptionService } from '@core/services/general-assumption.service';
import * as generalAssumptionActions from '@app/store/actions/general-assumption.actions';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class GeneralAssumptionEffects {
  constructor(
    private actions$: Actions,
    private generalAssumptionService: GeneralAssumptionService
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generalAssumptionActions.GeneralAssumptionActionTypes.LOAD),
      map((action: generalAssumptionActions.Load) => action),
      switchMap((data) =>
        this.generalAssumptionService
          .getAll(data.projectId)
          .pipe(handleDocumentChanges('[GENERAL ASSUMPTION] API'))
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generalAssumptionActions.GeneralAssumptionActionTypes.SAVE),
      map((action: generalAssumptionActions.Save) => action),
      switchMap((data) =>
        this.generalAssumptionService.save(data.payload).pipe(
          map(() => new generalAssumptionActions.SaveSuccess()),
          catchError(() => of(new generalAssumptionActions.SaveFail()))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generalAssumptionActions.GeneralAssumptionActionTypes.UPDATE),
      map((action: generalAssumptionActions.Update) => action),
      switchMap((data) =>
        this.generalAssumptionService.update(data.payload).pipe(
          map(() => new generalAssumptionActions.UpdateSuccess()),
          catchError(() => of(new generalAssumptionActions.UpdateFail()))
        )
      )
    )
  );
}
