import { PayloadAction } from '@core/models/payload-action';
import { Action } from '@ngrx/store';
import { Estimate } from '@pageProjects/models/estimate';
import { Weights } from '@pageProjects/models/weights';

export enum EstimateActionTypes {
  LOAD = '[ESTIMATE] LOAD CURRENCIES',
  ADDED = '[ESTIMATE API] ADDED',
  MODIFIED = '[ESTIMATE API] MODIFIED',
  REMOVED = '[ESTIMATE API] REMOVED',
  API_ERROR = '[ESTIMATE API] ERROR',
  CREATED = '[ESTIMATE] CREATED',
  CREATED_SUCCESS = '[ESTIMATE] CREATED SUCCESS',
  CREATED_FAIL = '[ESTIMATE] CREATED FAIL',
  DELETE_FOR_AREA = '[ESTIMATE] DELETE FOR AREA',
  DELETE_FOR_AREA_SUCCESS = '[ESTIMATE] DELETE FOR AREA SUCCESS',
  DELETE_FOR_AREA_FAIL = '[ESTIMATE] DELETE FOR AREA FAIL',
  UPDATED = '[ESTIMATE] UPDATED',
  UPDATED_SUCCESS = '[ESTIMATE] UPDATED SUCCESS',
  UPDATED_FAIL = '[ESTIMATE] UPDATED FAIL',
  REDISTRIBUTE = 'REDISTRIBUTE',
  REDISTRIBUTE_SUCCESS = 'REDISTRIBUTE_SUCCESS',
  REDISTRIBUTE_FAIL = 'REDISTRIBUTE_FAIL',
  MOVE_TO_TECHNICAL_AREA = 'MOVE_TO_TECHNICAL_AREA',
  MOVE_TO_TECHNICAL_AREA_SUCCESS = 'MOVE_TO_TECHNICAL_AREA_SUCCESS',
  MOVE_TO_TECHNICAL_AREA_FAIL = 'MOVE_TO_TECHNICAL_AREA_FAIL',
  CLEAR = '[ESTIMATE] CLEAR'
}

export class Load implements Action {
  readonly type = EstimateActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = EstimateActionTypes.ADDED;

  constructor(public payload: Estimate[]) {}
}

export class Modified implements Action {
  readonly type = EstimateActionTypes.MODIFIED;

  constructor(public payload: Estimate[]) {}
}

export class Removed implements Action {
  readonly type = EstimateActionTypes.REMOVED;

  constructor(public payload: Estimate[]) {}
}

export class ApiError implements Action {
  readonly type = EstimateActionTypes.API_ERROR;
}

export class Created implements PayloadAction {
  readonly type = EstimateActionTypes.CREATED;

  constructor(public payload: Estimate) {}
}

export class CreatedSuccess implements Action {
  readonly type = EstimateActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = EstimateActionTypes.CREATED_FAIL;

  constructor(public error) {}
}

export class DeleteForArea implements Action {
  readonly type = EstimateActionTypes.DELETE_FOR_AREA;
  constructor(public projectId: string, public areaId: string) {}
}

export class DeleteForAreaSuccess implements Action {
  readonly type = EstimateActionTypes.DELETE_FOR_AREA_SUCCESS;
}

export class DeleteForAreaFail implements Action {
  readonly type = EstimateActionTypes.DELETE_FOR_AREA_FAIL;
}

export class Updated implements PayloadAction {
  readonly type = EstimateActionTypes.UPDATED;

  constructor(
    public payload: number,
    public taskId: string,
    public field: keyof Weights,
    public technicalArea: string,
    public email: string
  ) {}
}

export class UpdatedSuccess implements Action {
  readonly type = EstimateActionTypes.UPDATED_SUCCESS;

  constructor(public id: string) {}
}

export class UpdatedFail implements Action {
  readonly type = EstimateActionTypes.UPDATED_FAIL;
}

export class Redistribute implements Action {
  readonly type = EstimateActionTypes.REDISTRIBUTE;
  constructor(public projectId: string, public areaId: string, public otherAreasIds: string[]) {}
}

export class RedistributeSuccess implements Action {
  readonly type = EstimateActionTypes.REDISTRIBUTE_SUCCESS;
}

export class RedistributeFail implements Action {
  readonly type = EstimateActionTypes.REDISTRIBUTE_FAIL;
}

export class MoveToTechnicalArea implements Action {
  readonly type = EstimateActionTypes.MOVE_TO_TECHNICAL_AREA;
  constructor(public projectId: string, public areaId: string, public toAreaId: string) {}
}

export class MoveToTechnicalAreaSuccess implements Action {
  readonly type = EstimateActionTypes.MOVE_TO_TECHNICAL_AREA_SUCCESS;
}

export class MoveToTechnicalAreaFail implements Action {
  readonly type = EstimateActionTypes.MOVE_TO_TECHNICAL_AREA_FAIL;
}

export class Clear implements Action {
  readonly type = EstimateActionTypes.CLEAR;
}

export type EstimateActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Created
  | CreatedSuccess
  | CreatedFail
  | DeleteForArea
  | DeleteForAreaSuccess
  | DeleteForAreaFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Redistribute
  | RedistributeSuccess
  | RedistributeFail
  | MoveToTechnicalArea
  | MoveToTechnicalAreaSuccess
  | MoveToTechnicalAreaFail
  | Clear;

export type DeleteTechnicalArea = DeleteForArea | Redistribute | MoveToTechnicalArea;
