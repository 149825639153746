import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from '@shared/sidebar/sidebar.service';
import { SidebarHostDirective } from '@shared/sidebar/sidebar-host.directive';

@Component({
  selector: 'fp-sidebar-container',
  template: '<ng-template fpSidebarHost></ng-template>'
})
export class SidebarContainerComponent implements OnInit {
  @ViewChild(SidebarHostDirective, { static: true })
  sidebarHost: SidebarHostDirective;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    const viewContainerRef = this.sidebarHost.viewContainerRef;
    this.sidebarService.sidebarUpdated().subscribe((request) => {
      viewContainerRef.clear();

      if (request) {
        const componentRef = viewContainerRef.createComponent(request.sidebar);
        Object.assign(componentRef.instance, { ...request.input });
      }
    });
  }
}
