import { AreaEffort } from './area-fte-group';
import { Cost, Workdays } from './cost';
import { InfiniteWorkdaysError } from './area-fte-effort';

export class PhaseAreaEfforts {
  private readonly maxArea: AreaEffort | undefined;
  constructor(public readonly areaEfforts: AreaEffort[]) {
    this.maxArea = this.calculateMaxArea();
  }

  getMaxAreaEffort() {
    return this.maxArea;
  }

  private calculateMaxArea() {
    if (this.areaEfforts.length === 0) {
      return undefined;
    }

    const max = this.areaEfforts.reduce((prev, cur) =>
      prev.duration.getInWorkingDays() > cur.duration.getInWorkingDays() ? prev : cur
    );
    return max;
  }

  getFpCost(overhead: number = 0): number {
    if (!this.maxArea) {
      return 0;
    }
    return this.areaEfforts.reduce((p, c) => {
      const cost = c.fpCost(this.maxArea.duration, overhead);
      return p + cost;
    }, 0);
  }

  getDuration(): number {
    if (!this.maxArea) {
      return 0;
    }
    return this.maxArea.duration.getInMonths();
  }

  getEndDate(): Date {
    if (!this.maxArea) {
      return undefined;
    }
    return this.maxArea.duration.getEndDate();
  }

  getDurationInDays(): number {
    if (!this.maxArea) {
      return 0;
    }
    return this.maxArea.duration.getInWorkingFullDays();
  }

  reduceCost(multiplier: number, rate: number, currency: string, filterArea?: string): Cost {
    const cost: Cost = {
      currency,
      status: 'valid',
      value: 0,
      realValue: 0,
      efficiency: 1,
      waste: 0
    };
    try {
      if (!this.areaEfforts.every((workday) => workday.coveredByFtes)) {
        throw new InfiniteWorkdaysError();
      }

      const result = this.getReducedCost(filterArea, multiplier);
      const realResult = this.getRealReducedCost(filterArea, multiplier);

      const efficiency = Math.round((result / realResult) * 100);
      cost.efficiency = isNaN(efficiency) ? 0 : efficiency;

      cost.value = result / rate;
      cost.realValue = realResult / rate;
    } catch (e) {
      if (e instanceof InfiniteWorkdaysError) {
        cost.status = 'invalid_fte_setup';
      }
    }

    cost.waste = cost.realValue - cost.value;
    return cost;
  }

  getWorkdays(): Workdays {
    const days: Workdays = {
      value: 0,
      status: 'valid'
    };
    try {
      if (!this.areaEfforts.every((workday) => workday.coveredByFtes)) {
        throw new InfiniteWorkdaysError();
      }
      days.value = Math.ceil(this.areaEfforts.reduce((acc, ae) => acc + ae.totalWorkdays(), 0));
    } catch (e) {
      if (e instanceof InfiniteWorkdaysError) {
        days.status = 'invalid_fte_setup';
      }
    }
    return days;
  }

  private getReducedCost(filterArea?: string, multiplier?: number): number {
    return this.areaEfforts
      .filter((a) => (filterArea ? a.area === filterArea : true))
      .reduce((acc, c) => acc + c.cost(multiplier), 0);
  }

  private getRealReducedCost(filterArea?: string, multiplier?: number): number {
    if (!this.maxArea) {
      return 0;
    }

    return this.areaEfforts
      .filter((a) => (filterArea ? a.area === filterArea : true))
      .reduce((acc, c) => acc + c.realCost(this.maxArea.duration, multiplier), 0);
  }
}
