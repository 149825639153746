import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGeneralAssumptions from '@app/store/reducers/general-assumption.reducer';

export const selectGeneralAssumptionsState =
  createFeatureSelector<fromGeneralAssumptions.GeneralAssumptionState>('generalAssumptions');

export const selectAllGeneralAssumptions = createSelector(
  selectGeneralAssumptionsState,
  fromGeneralAssumptions.selectAll
);
