import { Action } from '@ngrx/store';
import { PayloadAction } from '@core/models/payload-action';
import { Risk } from '@pageProjects/models/risk';

export enum RiskActionTypes {
  LOAD = '[RISK] LOAD',
  ADDED = '[RISK] API ADDED',
  MODIFIED = '[RISK] API MODIFIED',
  REMOVED = '[RISK] API REMOVED',
  API_ERROR = '[RISK] API ERROR',
  SAVE = '[RISK] SAVE',
  SAVE_SUCCESS = '[RISK] SAVE SUCCESS',
  SAVE_FAIL = '[RISK] SAVE FAIL',
  UPDATE = '[RISK] UPDATE',
  UPDATE_SUCCESS = '[RISK] UPDATE SUCCESS',
  UPDATE_FAIL = '[RISK] UPDATE FAIL',
  DELETE = '[RISK] DELETE',
  DELETE_SUCCESS = '[RISK] DELETE SUCCESS',
  DELETE_FAIL = '[RISK] DELETE FAIL',
  CLEAR = '[RISK] CLEAR'
}

export class Load implements Action {
  readonly type = RiskActionTypes.LOAD;
}

export class Added implements Action {
  readonly type = RiskActionTypes.ADDED;

  constructor(public payload: Risk[]) {}
}

export class Modified implements Action {
  readonly type = RiskActionTypes.MODIFIED;

  constructor(public payload: Partial<Risk>[]) {}
}

export class Removed implements Action {
  readonly type = RiskActionTypes.REMOVED;
  constructor(public payload: Risk[]) {}
}

export class ApiError implements Action {
  readonly type = RiskActionTypes.API_ERROR;
}

export class Save implements PayloadAction {
  readonly type = RiskActionTypes.SAVE;
  payload: Partial<Risk>;

  constructor(payload: Partial<Risk>) {
    this.payload = payload;
  }
}

export class SaveSuccess implements Action {
  readonly type = RiskActionTypes.SAVE_SUCCESS;
}

export class SaveFail implements Action {
  readonly type = RiskActionTypes.SAVE_FAIL;
}

export class Update implements PayloadAction {
  readonly type = RiskActionTypes.UPDATE;
  payload: Partial<Risk>;

  constructor(payload: Partial<Risk>) {
    this.payload = payload;
  }
}

export class UpdateSuccess implements Action {
  readonly type = RiskActionTypes.UPDATE_SUCCESS;
}

export class UpdateFail implements Action {
  readonly type = RiskActionTypes.UPDATE_FAIL;
}

export class Delete implements Action {
  readonly type = RiskActionTypes.DELETE;

  constructor(public id: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = RiskActionTypes.DELETE_SUCCESS;
}

export class DeleteFail implements Action {
  readonly type = RiskActionTypes.DELETE_FAIL;
}

export class Clear implements Action {
  readonly type = RiskActionTypes.CLEAR;
}

export type RiskActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Save
  | SaveSuccess
  | SaveFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Clear;
