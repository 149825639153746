import { PayloadAction } from '@core/models/payload-action';
import { Action } from '@ngrx/store';
import { PhaseSupportRoleConfig } from '@pageProjects/models/phase-support-role-config';

export enum PhaseSupportRoleConfigActionTypes {
  LOAD = '[PHASE SUPPORT ROLE CONFIG] LOAD PHASE SUPPORT ROLE CONFIG',
  ADDED = '[PHASE SUPPORT ROLE CONFIG API] ADDED',
  MODIFIED = '[PHASE SUPPORT ROLE CONFIG API] MODIFIED',
  REMOVED = '[PHASE SUPPORT ROLE CONFIG API] REMOVED',
  API_ERROR = '[PHASE SUPPORT ROLE CONFIG API] ERROR',
  SAVED = '[PHASE SUPPORT ROLE CONFIG] SAVED',
  SAVED_SUCCESS = '[PHASE SUPPORT ROLE CONFIG] SAVED SUCCESS',
  SAVED_FAIL = '[PHASE SUPPORT ROLE CONFIG] SAVED FAIL',
  DELETED = '[PHASE SUPPORT ROLE CONFIG] DELETED',
  DELETED_FAIL = '[PHASE SUPPORT ROLE CONFIG] DELETED FAIL',
  DELETED_SUCCESS = '[PHASE SUPPORT ROLE CONFIG] DELETED SUCCESS',
  CLEAR = '[PHASE SUPPORT ROLE CONFIG] CLEAR',
  CONFIG_CHANGED = '[PHASE SUPPORT ROLE CONFIG] CONFIG CHANGED',
  COPY_BATCH = '[PHASE SUPPORT ROLE CONFIG] COPY BATCH',
  COPY_BATCH_SUCCESS = '[PHASE SUPPORT ROLE CONFIG] COPY BATCH SUCCESS',
  COPY_BATCH_FAIL = '[PHASE SUPPORT ROLE CONFIG] COPY BATCH FAIL'
}

export class Load implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.ADDED;

  constructor(public payload: PhaseSupportRoleConfig[]) {}
}

export class Modified implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.MODIFIED;

  constructor(public payload: PhaseSupportRoleConfig[]) {}
}

export class Removed implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.REMOVED;

  constructor(public payload: PhaseSupportRoleConfig[]) {}
}

export class ApiError implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.API_ERROR;
}

export class Saved implements PayloadAction {
  readonly type = PhaseSupportRoleConfigActionTypes.SAVED;

  constructor(public payload: PhaseSupportRoleConfig) {}
}

export class SavedSuccess implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.SAVED_SUCCESS;
}

export class SavedFail implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.SAVED_FAIL;
}

export class DeletedSuccess implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.DELETED_SUCCESS;
}

export class Deleted implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.DELETED;

  constructor(public config: PhaseSupportRoleConfig) {}
}

export class DeletedFail implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.DELETED_FAIL;
}

export class Clear implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.CLEAR;
}

export class ConfigChanged implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.CONFIG_CHANGED;

  constructor(public phase: string, public role: string, public value: boolean) {}
}

export class Copy implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.COPY_BATCH;

  constructor(public fromPhaseId: string, public toPhaseId: string) {}
}

export class CopySuccess implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.COPY_BATCH_SUCCESS;
}

export class CopyFail implements Action {
  readonly type = PhaseSupportRoleConfigActionTypes.COPY_BATCH_FAIL;
}

export type PhaseSupportRoleConfigActions =
  | Load
  | Added
  | Modified
  | Removed
  | ApiError
  | Deleted
  | DeletedFail
  | Clear
  | Saved
  | SavedSuccess
  | SavedFail
  | ConfigChanged
  | Copy
  | CopySuccess
  | CopyFail;
