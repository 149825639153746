import { ActionReducerMap } from '@ngrx/store';
import {
  currenciesReducer,
  CurrencyState,
  nbpCurrenciesReducer,
  NbpCurrencyState
} from '@app/store/reducers/currency.reducer';
import { phasesReducer, PhaseState } from '@app/store/reducers/phase.reducer';
import { projectsReducer, ProjectState } from '@app/store/reducers/project.reducer';
import { rolesReducer, RoleState } from '@app/store/reducers/role.reducer';
import { tasksReducer, TaskState } from '@app/store/reducers/task.reducer';
import {
  technicalAreaReducer,
  TechnicalAreaState
} from '@app/store/reducers/technical-area.reducer';
import {
  userInterfaceReducer,
  UserInterfaceState
} from '@app/store/reducers/user-interface.reducer';
import { logReducer, LogState } from '@app/store/reducers/log.reducer';
import { estimateReducer, EstimateState } from '@app/store/reducers/estimate.reducer';
import { clipboardReducer, ClipboardState } from '@app/store/reducers/clipboard.reducer';
import {
  phaseSupportRoleConfigReducer,
  PhaseSupportRoleConfigState
} from '@app/store/reducers/phase-support-role-config.reducer';
import { fteReducer, FteState } from '@app/store/reducers/fte.reducer';
import {
  generalAssumptionReducer,
  GeneralAssumptionState
} from '@app/store/reducers/general-assumption.reducer';
import {
  leaderChecklistReducer,
  LeaderChecklistState
} from '@app/store/reducers/leader-checklist.reducer';
import {
  estimateHistoryReducer,
  EstimateHistoryState
} from '@app/store/reducers/estimate-history.reducer';
import { adminUserReducer, AdminUserState } from '@app/store/reducers/admin-user.reducer';
import {
  rolesAndRatesReducer,
  RolesAndRatesState
} from '@app/store/reducers/rates-and-roles.reducer';
import {
  businessLinesReducer,
  BusinessLinesState
} from '@app/store/reducers/business-lines.reducer';
import { userReducer, UserState } from '@app/store/reducers/user.reducers';
import { taskFilesReducer, TaskFilesState } from '@app/store/reducers/task-files.reducer';
import { taskCommentsReducer, TaskCommentState } from '@app/store/reducers/task-comment.reducer';
import { riskReducer, RiskState } from '@app/store/reducers/risk.reducer';

export interface ProjectModuleState {
  projects: ProjectState;
  phases: PhaseState;
  tasks: TaskState;
  taskFiles: TaskFilesState;
  taskComments: TaskCommentState;
  technicalAreas: TechnicalAreaState;
  roles: RoleState;
  currencies: CurrencyState;
  nbpCurrencies: NbpCurrencyState;
  userInterface: UserInterfaceState;
  logs: LogState;
  estimates: EstimateState;
  estimateHistory: EstimateHistoryState;
  clipboard: ClipboardState;
  phaseSupportRoleConfig: PhaseSupportRoleConfigState;
  ftes: FteState;
  generalAssumptions: GeneralAssumptionState;
  leaderChecklist: LeaderChecklistState;
  adminUsers: AdminUserState;
  rolesAndRates: RolesAndRatesState;
  businessLines: BusinessLinesState;
  users: UserState;
  risks: RiskState;
}

export const reducers: ActionReducerMap<ProjectModuleState> = {
  projects: projectsReducer,
  phases: phasesReducer,
  tasks: tasksReducer,
  taskFiles: taskFilesReducer,
  taskComments: taskCommentsReducer,
  technicalAreas: technicalAreaReducer,
  roles: rolesReducer,
  currencies: currenciesReducer,
  nbpCurrencies: nbpCurrenciesReducer,
  userInterface: userInterfaceReducer,
  logs: logReducer,
  estimates: estimateReducer,
  estimateHistory: estimateHistoryReducer,
  clipboard: clipboardReducer,
  phaseSupportRoleConfig: phaseSupportRoleConfigReducer,
  ftes: fteReducer,
  generalAssumptions: generalAssumptionReducer,
  leaderChecklist: leaderChecklistReducer,
  adminUsers: adminUserReducer,
  rolesAndRates: rolesAndRatesReducer,
  businessLines: businessLinesReducer,
  users: userReducer,
  risks: riskReducer
};
