import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '@pageUser/model/user';
import { UserActions, UserActionTypes } from '@app/store/actions/user.actions';

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>();

export interface UserState extends EntityState<User> {}

export const userInitialState: UserState = userAdapter.getInitialState({});

export const userReducer = (
  userState: UserState = userInitialState,
  action: UserActions
): UserState => {
  switch (action.type) {
    case UserActionTypes.ADDED:
      return userAdapter.addMany(action.payload, userState);
    case UserActionTypes.CLEAR: {
      return userAdapter.removeAll(userState);
    }
    default:
      return userState;
  }
};

export const { selectAll, selectEntities, selectIds, selectTotal } = userAdapter.getSelectors();
