export class TechnicalArea {
  id?: string;
  project: string;
  name: string;
  hideArea?: boolean;
  hideCost?: boolean;
  created?: Date;

  constructor(obj?: TechnicalArea) {
    this.id = (obj && obj.id) || null;
    this.project = (obj && obj.project) || null;
    this.name = (obj && obj.name) || null;
    this.hideArea = (obj && obj.hideArea) || false;
    this.hideCost = (obj && obj.hideCost) || false;
    this.created = (obj && obj.created) || null;
  }
}

export interface TechnicalAreaDelete {
  areaId: string;
  option: TechnicalAreaDeleteOptions;
  moveToAreaId?: string;
  otherAreasIds: string[];
}

export enum TechnicalAreaDeleteOptions {
  DeleteAll = 'Delete technical area and estimates',
  RedistributeEstimates = 'Redistribute estimates to remaining technical areas evenly',
  MoveEstimatesToArea = 'Move estimates to selected technical area'
}

export type TechnicalAreaHideCalculationFactory = (area: TechnicalArea) => boolean;

export const getCostTechnicalAreaHideCalculationFactory: TechnicalAreaHideCalculationFactory = (
  area: TechnicalArea
): boolean => area.hideArea || area.hideCost;
export const getDurationTechnicalAreaHideCalculationFactory: TechnicalAreaHideCalculationFactory = (
  area: TechnicalArea
): boolean => area.hideArea;
