import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminUser from '@app/store/reducers/admin-user.reducer';
import { AdminUser } from '@pageProjects/models/admin-user';

export const selectAdminUserState =
  createFeatureSelector<fromAdminUser.AdminUserState>('adminUsers');

export const selectAllAdminUsers = createSelector(selectAdminUserState, fromAdminUser.selectAll);

export const selectAdminUsers = createSelector(
  selectAllAdminUsers,
  (state) => state[0] ?? ({} as AdminUser)
);
