import { Action } from '@ngrx/store';
import { PayloadAction } from '@core/models/payload-action';
import { LeaderChecklist } from '@pageProjects/models/leader-checklist';

export enum LeaderChecklistActionTypes {
  LOAD = '[LEADER CHECKLIST] LOAD',
  ADDED = '[LEADER CHECKLIST] API ADDED',
  MODIFIED = '[LEADER CHECKLIST] API MODIFIED',
  SAVE = '[LEADER CHECKLIST] SAVE',
  SAVE_SUCCESS = '[LEADER CHECKLIST] SAVE SUCCESS',
  SAVE_FAIL = '[LEADER CHECKLIST] SAVE FAIL',
  UPDATE = '[LEADER CHECKLIST] UPDATE',
  UPDATE_SUCCESS = '[LEADER CHECKLIST] UPDATE SUCCESS',
  UPDATE_FAIL = '[LEADER CHECKLIST] UPDATE FAIL',
  CLEAR = '[LEADER CHECKLIST] CLEAR'
}

export class Load implements Action {
  readonly type = LeaderChecklistActionTypes.LOAD;

  constructor(public projectId: string) {}
}

export class Added implements Action {
  readonly type = LeaderChecklistActionTypes.ADDED;

  constructor(public payload: LeaderChecklist[]) {}
}

export class Modified implements Action {
  readonly type = LeaderChecklistActionTypes.MODIFIED;

  constructor(public payload: Partial<LeaderChecklist>[]) {}
}

export class Save implements PayloadAction {
  readonly type = LeaderChecklistActionTypes.SAVE;
  payload: Partial<LeaderChecklist>;

  constructor(payload: Partial<LeaderChecklist>) {
    this.payload = payload;
  }
}

export class SaveSuccess implements Action {
  readonly type = LeaderChecklistActionTypes.SAVE_SUCCESS;
}

export class SaveFail implements Action {
  readonly type = LeaderChecklistActionTypes.SAVE_FAIL;
}

export class Update implements PayloadAction {
  readonly type = LeaderChecklistActionTypes.UPDATE;
  payload: Partial<LeaderChecklist>;

  constructor(payload: Partial<LeaderChecklist>) {
    this.payload = payload;
  }
}

export class UpdateSuccess implements Action {
  readonly type = LeaderChecklistActionTypes.UPDATE_SUCCESS;
}

export class UpdateFail implements Action {
  readonly type = LeaderChecklistActionTypes.UPDATE_FAIL;
}

export class Clear implements Action {
  readonly type = LeaderChecklistActionTypes.CLEAR;
}

export type LeaderChecklistActions =
  | Load
  | Added
  | Save
  | SaveSuccess
  | SaveFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Modified
  | Clear;
