import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as logActions from '@app/store/actions/log.actions';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '@core/services/log.service';
import { combineLatest, of } from 'rxjs';
import * as projectActions from '@app/store/actions/project.actions';
import * as phaseActions from '@app/store/actions/phases.actions';
import * as taskActions from '@app/store/actions/task.actions';
import * as currencyActions from '@app/store/actions/currency.actions';
import * as roleActions from '@app/store/actions/role.actions';
import * as taActions from '@app/store/actions/technical-area.actions';
import { Log } from '@pageProjects/models/log';
import { PayloadAction } from '@core/models/payload-action';
import { ProjectModuleState } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store/selectors';
import { NbAuthService } from '@nebular/auth';
import { handleDocumentChanges } from '@shared/operators';

@Injectable()
export class LogEffects {
  constructor(
    private actions$: Actions,
    private logService: LogService,
    private store: Store<ProjectModuleState>,
    private auth: NbAuthService
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logActions.LogActionTypes.LOAD),
      map((action: logActions.Load) => action),
      switchMap(() =>
        this.store.select(fromStore.areProjectsLoaded).pipe(
          filter((areProjectsLoaded) => areProjectsLoaded),
          withLatestFrom(this.store.select(fromStore.selectAllProjects)),
          switchMap(([, projects]) => this.logService.getAll(projects)),
          handleDocumentChanges('[LOG API]')
        )
      )
    )
  );

  created$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logActions.LogActionTypes.CREATED),
      map((action: logActions.Created) => action),
      switchMap((action) =>
        this.logService.log(action.log).pipe(
          map(() => new logActions.CreatedSuccess()),
          catchError(() => of(new logActions.CreatedFail()))
        )
      )
    )
  );

  eventHappend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        projectActions.ProjectActionTypes.UPDATED,
        taskActions.TaskActionTypes.UPDATED,
        phaseActions.PhaseActionTypes.UPDATED,
        taActions.TechnicalAreaActionTypes.UPDATED,
        roleActions.RoleActionTypes.UPDATED,
        currencyActions.CurrencyActionTypes.UPDATED
      ),
      map((action: PayloadAction) => action),
      switchMap((action) =>
        combineLatest(this.store.select(fromStore.selectProject), this.auth.getToken()).pipe(
          filter(([project]) => project !== null),
          mergeMap(([project, token]) => {
            const log: Log = {
              change: action.payload,
              project: project.id,
              time: new Date(),
              type: action.type,
              user: token.getPayload()?.email
            };
            return [new logActions.Created(log)];
          })
        )
      )
    )
  );
}
