import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { Collection } from '@shared/models/collection';
import { Currency } from '@pageProjects/models/currency';
import { CustomCurrency } from '@pageProjects/models/custom-currency';
import { BehaviorSubject, EMPTY, from, iif, Observable, of, throwError, zip } from 'rxjs';
import { delay, map, repeatWhen, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  constructor(private http: HttpClient, private afs: AngularFirestore) {
    http
      .get('https://openexchangerates.org/api/currencies.json')
      .pipe(map((response) => response as { [key: string]: string }))
      .subscribe((mapping) => {
        this.mapping = mapping;
      });
  }

  static primary = ['EUR', 'USD', 'GBP'];

  private mapping: { [key: string]: string } = {};
  private innerCurrencies$ = new BehaviorSubject<Currency[]>([]);
  private currenciesLoading = false;

  private getCollection(projectId: string): AngularFirestoreCollection<CustomCurrency> {
    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<CustomCurrency>(Collection.CURRENCIES);
  }

  private sortCurrencies(a: Currency, b: Currency): number {
    const aIsPrimary = CurrencyService.primary.find((symbol) => symbol === a.code) !== undefined;
    const bIsPrimary = CurrencyService.primary.find((symbol) => symbol === b.code) !== undefined;
    if (aIsPrimary && !bIsPrimary) {
      return -1;
    } else if (aIsPrimary && bIsPrimary) {
      return a.code.localeCompare(b.code);
    } else if (!aIsPrimary && bIsPrimary) {
      return 1;
    } else {
      return a.code.localeCompare(b.code);
    }
  }

  currencies(): Observable<Currency[]> {
    const options = {
      headers: {
        Accept: 'application/json '
      }
    };

    let retryAttempt = 0;
    const backoffExponent = 1.2;
    const pollingIntervalMilliseconds = 1650.0;

    if (!this.innerCurrencies$.value.length && !this.currenciesLoading) {
      this.currenciesLoading = true;
      return of({}).pipe(
        switchMap(() =>
          iif(
            () => retryAttempt < 5,
            this.http.get(
              environment.stubCurrencies
                ? '/assets/NBPCurrenciesStub.json'
                : 'https://api.nbp.pl/api/exchangerates/tables/a/',
              options
            ),
            throwError('Cannot get currencies')
          )
        ),
        map((response) => response[0].rates),
        map((data) => data.map((item) => item as Currency)),
        map((currencies) =>
          currencies.map((currency: Currency) => {
            currency.primary =
              CurrencyService.primary.find((symbol) => symbol === currency.code) !== undefined;
            return currency;
          })
        ),
        map((currencies) => currencies.sort(this.sortCurrencies)),
        map((currencies) => {
          for (const currentCurrency of currencies) {
            if (this.mapping[currentCurrency.code] !== undefined) {
              currentCurrency.currency = this.mapping[currentCurrency.code];
            }
          }
          return currencies as Currency[];
        }),
        tap((currencies) => {
          this.innerCurrencies$.next(currencies);
          this.currenciesLoading = false;
        }),
        retryWhen((obs) =>
          obs.pipe(
            tap(() => {
              retryAttempt += 1;
            }),
            delay(pollingIntervalMilliseconds * backoffExponent ** (retryAttempt - 1))
          )
        ),
        take(1)
      );
    }

    return this.innerCurrencies$;
  }

  add(currency: Partial<CustomCurrency>): Observable<{
    currencyId: string;
  }> {
    return from(
      this.getCollection(currency.project)
        .add({ ...currency } as CustomCurrency)
        .catch((e) => e)
        .then((docRef: DocumentReference<CustomCurrency>) => ({ currencyId: docRef.id }))
    );
  }

  update(currency: Partial<CustomCurrency>): Observable<any> {
    const updatedCurrencyData = { ...currency };
    delete updatedCurrencyData.id;

    return from(
      this.getCollection(currency.project)
        .doc<CustomCurrency>(`${currency.id}`)
        .update({
          ...updatedCurrencyData
        })
        .catch((e) => e)
        .then(() => 'currency updated')
    );
  }

  delete(projectId: string, currencyId: string): Observable<any> {
    return from(
      this.getCollection(projectId)
        .doc(currencyId)
        .delete()
        .catch((e) => e)
        .then(() => currencyId)
    );
  }

  getAll(projectId: string): Observable<DocumentChangeAction<CustomCurrency>[]> {
    return this.getCollectionFilterByProject(projectId).stateChanges();
  }

  removeProjectCurrencies(projectId: string): Observable<any[] | Observable<never>> {
    return this.getCollectionFilterByProject(projectId)
      .get()
      .pipe(
        switchMap((currencies) =>
          iif(() => currencies.empty, of(EMPTY), this.removeCurrencies(currencies))
        )
      );
  }

  private getCollectionFilterByProject(
    projectId: string
  ): AngularFirestoreCollection<CustomCurrency> {
    return this.getCollection(projectId);
  }

  private removeCurrencies(currencies: QuerySnapshot<CustomCurrency>): Observable<string[][]> {
    const currencyDeleteRequests: Observable<string[]>[] = [];
    currencies.docs.forEach((currency) => {
      currencyDeleteRequests.push(this.delete(currency.data().project, currency.id));
    });
    return zip(...currencyDeleteRequests);
  }
}
