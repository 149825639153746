import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RolesAndRatesService } from '@core/services/roles-and-rates.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as rolesAndRatesActions from '@app/store/actions/roles-and-rates.actions';
import { RolesAndRates } from '@shared/models/roles-and-rates';
import { of } from 'rxjs';
import { RoleUtils } from '@core/common/role-utils';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class RolesAndRatesEffects {
  constructor(private actions$: Actions, private rolesAndRatesService: RolesAndRatesService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesAndRatesActions.RolesAndRatesActionTypes.LOAD),
      map((action: rolesAndRatesActions.Load) => action),
      switchMap(() =>
        this.rolesAndRatesService.getAll().pipe(handleDocumentChanges('[ROLES AND RATES] API'))
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesAndRatesActions.RolesAndRatesActionTypes.SAVE),
      map((action: rolesAndRatesActions.Save) => action),
      switchMap((data) =>
        this.rolesAndRatesService.save(data.payload).pipe(
          map(() => new rolesAndRatesActions.SaveSuccess()),
          catchError(() => of(new rolesAndRatesActions.SaveFail()))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesAndRatesActions.RolesAndRatesActionTypes.UPDATE),
      map((action: rolesAndRatesActions.Update) => action),
      switchMap((data) =>
        this.rolesAndRatesService.update(data.payload).pipe(
          map(() => new rolesAndRatesActions.UpdateSuccess()),
          catchError(() => of(new rolesAndRatesActions.UpdateFail()))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesAndRatesActions.RolesAndRatesActionTypes.DELETE),
      map((action: rolesAndRatesActions.Delete) => action),
      switchMap((data) =>
        this.rolesAndRatesService.delete(data.payload).pipe(
          map(() => new rolesAndRatesActions.DeleteSuccess()),
          catchError(() => of(new rolesAndRatesActions.DeleteFail()))
        )
      )
    )
  );
}
