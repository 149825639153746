import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbFirebaseAuthModule } from '@nebular/firebase-auth';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbBadgeModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbSidebarModule,
  NbTabsetModule,
  NbTagModule,
  NbThemeModule,
  NbToastrModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule,
  NbSpinnerModule
} from '@nebular/theme';

@NgModule({
  imports: [
    NbToastrModule.forRoot(),
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbButtonModule,
    NbTabsetModule,
    NbMenuModule.forRoot(),
    NbActionsModule,
    NbInputModule,
    NbCardModule,
    NbIconModule,
    NbFormFieldModule,
    NbTagModule,
    NbRouteTabsetModule,
    NbListModule,
    NbAlertModule,
    NbDialogModule.forRoot(),
    NbCheckboxModule,
    NbAutocompleteModule,
    NbFirebaseAuthModule,
    NbPopoverModule,
    NbProgressBarModule,
    NbTooltipModule,
    NbSelectModule,
    NbContextMenuModule,
    NbButtonGroupModule,
    NbUserModule,
    NbRadioModule,
    NbAccordionModule,
    NbBadgeModule,
    NbToggleModule,
    NbSpinnerModule
  ],
  exports: [
    NbToastrModule,
    NbThemeModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule,
    NbButtonModule,
    NbTabsetModule,
    NbMenuModule,
    NbActionsModule,
    NbInputModule,
    NbCardModule,
    NbIconModule,
    NbFormFieldModule,
    NbTagModule,
    NbRouteTabsetModule,
    NbListModule,
    NbAlertModule,
    NbDialogModule,
    NbCheckboxModule,
    NbAutocompleteModule,
    NbFirebaseAuthModule,
    NbPopoverModule,
    NbProgressBarModule,
    NbTooltipModule,
    NbSelectModule,
    NbContextMenuModule,
    NbButtonGroupModule,
    NbUserModule,
    NbRadioModule,
    NbAccordionModule,
    NbBadgeModule,
    NbToggleModule,
    NbSpinnerModule
  ]
})
export class NebularModule {}
