import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';
import { HeaderHeightService } from '@core/services/header-height.service';
import { commonEnvironment } from '@env/environment.common';
import { NbRoleProvider } from '@nebular/security';
import { Store } from '@ngrx/store';
import { ProjectModuleState } from '@app/store/reducers';
import * as rolesAndRatesActions from '@app/store/actions/roles-and-rates.actions';
import * as businessLinesActions from '@app/store/actions/business-lines.actions';
import * as adminUserActions from '@app/store/actions/admin-user.actions';
import * as projectActions from '@app/store/actions/project.actions';
import { SidebarService } from '@shared/sidebar/sidebar.service';
import { AuthenticatedUser } from '@core/models/authenticated-user';

@Component({
  selector: 'fp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  user$: Observable<AuthenticatedUser>;
  isRootRoute$: Observable<boolean>;
  role$: Observable<string>;

  @ViewChild('layoutHeader', { read: ElementRef }) layoutHeader: ElementRef;

  constructor(
    private authService: AuthService,
    private router: Router,
    private roleProvider: NbRoleProvider,
    private store: Store<ProjectModuleState>,
    private sidebarService: SidebarService,
    private headerHeightService: HeaderHeightService
  ) {}

  ngOnInit(): void {
    this.user$ = this.authService.user$.pipe(
      tap((user) => {
        if (user) {
          this.store.dispatch(new adminUserActions.Load());
          this.store.dispatch(new rolesAndRatesActions.Load());
          this.store.dispatch(new businessLinesActions.Load());
        } else {
          this.store.dispatch(new adminUserActions.Clear());
          this.store.dispatch(new rolesAndRatesActions.Clear());
          this.store.dispatch(new businessLinesActions.Clear());
          this.sidebarService.clear();
        }
      })
    );
    this.isRootRoute$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(
        (event: NavigationEnd) =>
          event.url !== commonEnvironment.projectsPath &&
          !event.url.includes(commonEnvironment.loginPath) &&
          event.url !== '/'
      )
    );

    this.role$ = this.roleProvider.getRole().pipe(
      map((role) => {
        let name = 'Current role: ';

        if (typeof role === 'string') {
          name += role;
        }

        return name;
      })
    );
  }

  ngAfterViewInit(): void {
    this.headerHeightService.height.next(
      this.layoutHeader.nativeElement.getBoundingClientRect().height
    );
  }

  logout(): void {
    this.store.dispatch(new projectActions.ClearAll());
    this.store.dispatch(new adminUserActions.Clear());
    this.store.dispatch(new rolesAndRatesActions.Clear());
    this.store.dispatch(new businessLinesActions.Clear());
    this.authService.logout();
  }

  back(): void {
    this.router.navigate([commonEnvironment.projectsPath]);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new projectActions.ClearAll());
    this.store.dispatch(new adminUserActions.Clear());
    this.store.dispatch(new rolesAndRatesActions.Clear());
    this.store.dispatch(new businessLinesActions.Clear());
  }
}
