import { LevelSplit } from '@core/common/role-utils';

export interface TechnicalAreaDiscounts {
  [key: string]: { [key: string]: number } | number;
}

export interface FinalRate extends TechnicalAreaDiscounts {}

export class Role {
  id?: string;
  project: string;
  name: string;
  levelSplit: boolean;
  levelSplitValue: LevelSplit;
  overhead: boolean;
  support: boolean;
  businessLine: string = null;
  rates?: { [key: string]: number } = {};
  discoveryRates?: { [key: string]: number } = {};
  cost?: { [key: string]: number } = {};
  discounts?: { [key: string]: number } = {};
  finalRate?: FinalRate;
  includeInDiscovery: boolean;
  technicalAreaDiscountSplit?: { [key: string]: boolean };
  technicalAreaDiscounts?: TechnicalAreaDiscounts = {};

  constructor(obj?: Partial<Role>) {
    if (obj && obj.id) {
      this.id = obj.id;
    }
    this.project = (obj && obj.project) || null;
    this.name = (obj && obj.name) || null;
    this.levelSplit = (obj && obj.levelSplit) || false;
    this.levelSplitValue = (obj && obj.levelSplitValue) || LevelSplit.NotDefined;
    this.overhead = (obj && obj.overhead) || false;
    this.support = (obj && obj.support) || false;
    this.businessLine = (obj && obj.businessLine) || null;
    this.rates = (obj && obj.rates) || {};
    this.discoveryRates = (obj && obj.discoveryRates) || {};
    this.cost = (obj && obj.cost) || {};
    this.discounts = (obj && obj.discounts) || {};
    this.finalRate = (obj && obj.finalRate) || {};
    this.includeInDiscovery = (obj && obj.includeInDiscovery) || false;
    this.technicalAreaDiscountSplit = (obj && obj.technicalAreaDiscountSplit) || {};
    this.technicalAreaDiscounts = (obj && obj.technicalAreaDiscounts) || {};
  }
}
