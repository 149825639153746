import { Injectable } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, zip } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { PhaseService } from '@core/services/phase.service';
import { TaskService } from '@core/services/task.service';
import { FteService } from '@core/services/fte.service';
import * as phaseActions from '@app/store/actions/phases.actions';
import { ProjectModuleState } from '@app/store/reducers';
import { Phase } from '@pageProjects/models/phase';
import * as fromStore from '@app/store/selectors';
import { Reordered } from '@app/store/actions/phases.actions';
import { distinctUntilChangedJson, handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class PhaseEffects {
  constructor(
    private actions$: Actions,
    private phaseService: PhaseService,
    private taskService: TaskService,
    private fteService: FteService,
    private store: Store<ProjectModuleState>
  ) {}

  loadPhases$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(phaseActions.PhaseActionTypes.LOAD),
      map((action: phaseActions.Load) => action),
      switchMap((loadAction) =>
        this.phaseService.getAll(loadAction.projectId).pipe(handleDocumentChanges('[PHASE API]'))
      )
    )
  );

  created$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(phaseActions.PhaseActionTypes.CREATED),
      map((action: phaseActions.Created) => action),
      switchMap((data) =>
        this.phaseService.add(data.payload).pipe(
          map((res) => {
            if (res && res.id) {
              return new phaseActions.SetCreatedPhaseId(res.id);
            }
          }),
          catchError(() => of(new phaseActions.CreatedFail()))
        )
      )
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(phaseActions.PhaseActionTypes.DELETED),
      map((action: phaseActions.Deleted) => action),
      switchMap((data) =>
        zip(
          this.phaseService.delete(data.projectId, data.phaseId),
          this.fteService.deleteFtesByPhase(data.projectId, data.phaseId)
        ).pipe(
          map(
            () =>
              new phaseActions.DeletedSuccessRemovePhaseAssociation(data.projectId, data.phaseId)
          ),
          catchError(() => of(new phaseActions.DeletedFail()))
        )
      )
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(phaseActions.PhaseActionTypes.UPDATED),
      map((action: phaseActions.Updated) => action),
      switchMap((data) =>
        this.phaseService.update(data.payload).pipe(
          map(() => new phaseActions.UpdatedSuccess()),
          catchError(() => of(new phaseActions.UpdatedFail()))
        )
      )
    )
  );

  removePhaseAssociation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(phaseActions.PhaseActionTypes.DELETED_SUCCESS_REMOVE_PHASE_ASSOCIATION),
      map((action: phaseActions.DeletedSuccessRemovePhaseAssociation) => action),
      switchMap((data) =>
        this.taskService.removePhaseAssociation(data.projectId, data.phaseId).pipe(
          map(() => new phaseActions.RemovePhaseAssociationSuccess()),
          catchError(() => of(new phaseActions.RemovePhaseAssociationFail()))
        )
      )
    )
  );

  reorder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(phaseActions.PhaseActionTypes.REORDERED),
      map((action: phaseActions.Reordered) => action),
      switchMap((action: Reordered) => this.getPhases(action)),
      map(([action, phases]) => {
        moveItemInArray(phases, action.previousIndex, action.newIndex);

        const phaseReorderPayloads = phases.map((task, index) => {
          const payload: Partial<Phase> = {
            id: task.id,
            order: index
          };

          return payload;
        });

        return new phaseActions.BatchUpdated(phaseReorderPayloads);
      })
    )
  );

  updateBatch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(phaseActions.PhaseActionTypes.BATCH_UPDATED),
      map((action: phaseActions.BatchUpdated) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      switchMap(([action, project]) =>
        this.phaseService.updateBatch(project.id, action.payload).pipe(
          map(() => new phaseActions.UpdatedSuccess()),
          catchError(() => of(new phaseActions.UpdatedFail()))
        )
      )
    )
  );

  private getPhases(action: Reordered): Observable<[Reordered, Phase[]]> {
    return this.store.select(fromStore.selectAllPhasess).pipe(
      first(),
      map((result) => [action, result])
    );
  }
}
