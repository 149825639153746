import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference,
  CollectionReference
} from '@angular/fire/compat/firestore';
import { Collection } from '@shared/models/collection';
import { Project } from '@pageProjects/models/project';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ProjectUtils } from '@pageProjects/tools/utils';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private userToken: NbAuthToken;

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private nbAuthService: NbAuthService,
    private authService: AuthService
  ) {
    nbAuthService.onTokenChange().subscribe((token) => {
      this.userToken = token;
    });
  }

  projectCollection(): AngularFirestoreCollection<Project> {
    return this.afs.collection<Project>(Collection.PROJECTS);
  }

  projectAllCollection() {
    let queryFn = (ref: CollectionReference<Project>) => ref.where('visible', '==', true);

    if (!this.authService.isAdmin$.value) {
      queryFn = (ref: CollectionReference<Project>) =>
        ref
          .where('visible', '==', true)
          .where('listViewers', 'array-contains', this.userToken.getPayload()?.email);
    }

    return this.afs.collection<Project>(Collection.PROJECTS, queryFn);
  }

  add(project: Partial<Project>): Observable<{ projectId: string; project: Partial<Project> }> {
    let afsPromise: Promise<void | DocumentReference<Project>>;

    if (project.id) {
      afsPromise = this.projectCollection()
        .doc(project.id)
        .set(project as Project);
    } else {
      afsPromise = this.projectCollection().add(project as Project);
    }

    return from(
      afsPromise
        .catch((e) => e)
        .then((docRef?: DocumentReference<Project>) => ({
          projectId: docRef ? docRef?.id : project.id,
          project
        }))
    );
  }

  update(project: Partial<Project>): Observable<any> {
    const updatedProjectData = { ...project };
    delete updatedProjectData.id;

    return from(
      this.projectCollection()
        .doc<Project>(`${project.id}`)
        .update({
          ...updatedProjectData
        })
        .catch((e) => e)
        .then((r) => r)
    );
  }

  delete(projectId: string): Observable<any> {
    return from(
      this.projectCollection()
        .doc(projectId)
        .delete()
        .catch((e) => e)
        .then(() => projectId)
    );
  }

  getAllProjects(): Observable<DocumentChangeAction<Project>[]> {
    return this.projectAllCollection().stateChanges();
  }

  copy(projectId: string): Observable<{ data: string; newProjectId: string }> {
    const callable = this.fns.httpsCallable('fpert/copyProject');
    return callable({ projectId });
  }

  sendMailInvitation(projectId: string, email: string, role: string): Observable<string> {
    const callable = this.fns.httpsCallable('fpert/sendMailInvitation');
    return callable({ projectId, email, role });
  }

  removeProjectCopies(projectId: string, projects: Project[]): Observable<boolean> {
    if (projects.length === 0) {
      return of(false);
    }

    const removeInBatches = async (batches: Partial<Project>[][]): Promise<void> => {
      for await (const projectBatch of batches) {
        const batch = this.afs.firestore.batch();
        projectBatch.forEach((project) => {
          const collection = this.projectCollection();
          const ref = collection.doc(project.id).ref;
          batch.update(ref, { visible: false });
        });

        await batch.commit();
      }
    };

    return from(
      removeInBatches(ProjectUtils.chunkArray(projects, 20))
        .then(() => true)
        .catch((e) => e)
    );
  }
}
