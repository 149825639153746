import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { CurrencyService } from '@core/services/currency.service';
import { PhaseService } from '@core/services/phase.service';
import { ProjectService } from '@core/services/project.service';
import { RoleService } from '@core/services/role.service';
import { TaskService } from '@core/services/task.service';
import { TechnicalAreaService } from '@core/services/technical-area.service';
import * as projectActions from '@app/store/actions/project.actions';
import { ProjectActionTypes } from '@app/store/actions/project.actions';
import * as phaseActions from '@app/store/actions/phases.actions';
import * as taActions from '@app/store/actions/technical-area.actions';
import { combineLatest, iif, Observable, of, throwError, zip } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  flatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { TechnicalArea } from '@pageProjects/models/technical-area';
import { ProjectModuleState } from '@app/store/reducers';
import * as fromStore from '@app/store/selectors';
import { Phase } from '@pageProjects/models/phase';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import * as roleActions from '@app/store/actions/role.actions';
import * as taskActions from '@app/store/actions/task.actions';
import * as estimateActions from '@app/store/actions/estimate.actions';
import * as psrcActions from '@app/store/actions/phase-support-role-config.actions';
import * as fteActions from '@app/store/actions/fte.actions';
import * as currencyActions from '@app/store/actions/currency.actions';
import * as estimateHistoryActions from '@app/store/actions/estimate-history.actions';
import * as businessLinesActions from '@app/store/actions/business-lines.actions';
import * as userActions from '@app/store/actions/user.actions';
import * as fromTaskFilesActions from '@app/store/actions/task-files.actions';
import * as taskCommentsActions from '@app/store/actions/task-comment.actions';
import * as riskActions from '@app/store/actions/risk.actions';
import * as technicalAreaActions from '@app/store/actions/technical-area.actions';
import * as generalAssumptionActions from '@app/store/actions/general-assumption.actions';
import * as leaderChecklistActions from '@app/store/actions/leader-checklist.actions';
import { handleDocumentChanges } from '../../shared/operators';

@Injectable()
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    private projectService: ProjectService,
    private taskService: TaskService,
    private phaseService: PhaseService,
    private roleService: RoleService,
    private technicalAreaService: TechnicalAreaService,
    private currencyService: CurrencyService,
    private store: Store<ProjectModuleState>,
    private router: Router,
    private toastService: NbToastrService
  ) {}

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.LOAD_ALL),
      map((action: projectActions.LoadAll) => action),
      tap(() => {
        this.store.dispatch(new projectActions.SetLoadAllState(false));
      }),
      switchMap(() =>
        this.projectService.getAllProjects().pipe(
          tap((p) => {
            if (p.length === 0) {
              this.store.dispatch(new projectActions.SetLoadAllState(true));
            }
          }),
          handleDocumentChanges('[PROJECT API]')
        )
      )
    )
  );

  afterProjectLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.ADDED),
      delay(100),
      withLatestFrom(this.store.select(fromStore.areProjectsLoaded)),
      switchMap(([, areModulesLoaded]) => {
        const modules: Action[] = [];
        if (!areModulesLoaded) {
          modules.push(new projectActions.SetLoadAllState(true));
        }

        return modules;
      })
    )
  );

  afterSetProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.SET_PROJECT),
      map((a: projectActions.SetProject) => a),
      withLatestFrom(this.store.select(fromStore.areProjectsLoaded)),
      switchMap(([action, areModulesLoaded]) =>
        areModulesLoaded ? this.getLoadAllModulesActions(action.projectId) : []
      )
    )
  );

  private getLoadAllModulesActions(projectId: string) {
    return [
      new currencyActions.Load(projectId),
      new phaseActions.Load(projectId),
      new taskActions.Load(projectId),
      new taActions.Load(projectId),
      new roleActions.Load(projectId),
      new estimateActions.Load(projectId),
      new psrcActions.Load(projectId),
      new fteActions.Load(projectId),
      new estimateHistoryActions.Load(projectId),
      new userActions.Loaded(),
      new fromTaskFilesActions.Load(),
      new taskCommentsActions.Load(),
      new riskActions.Load()
    ];
  }

  created$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.CREATED),
      map((action: projectActions.Created) => action),
      switchMap((action) =>
        this.projectService.add(action.payload).pipe(
          map((result) => ({
            project: result.project,
            action
          })),
          map(
            (result) =>
              new projectActions.CreatedSuccess(result.project, result.action.technicalAreas)
          ),
          catchError((error) => of(new projectActions.CreatedFail(error)))
        )
      )
    )
  );

  createdFail$ = createEffect(
    () => this.actions$.pipe(ofType(projectActions.ProjectActionTypes.CREATED_FAIL)),
    { dispatch: false }
  );

  createdAndActivated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.CREATED_SUCCESS),
      map((action: projectActions.CreatedSuccess) => action),
      switchMap((data) => {
        const phase: Partial<Phase> = {
          project: data.payload.id,
          name: 'Initial phase',
          order: 0
        };
        const phaseAction: phaseActions.Created = new phaseActions.Created(phase);
        const technicalAreasActions: taActions.Created[] = data.technicalAreas
          .map((area, i) => {
            const created = new Date();
            created.setSeconds(created.getSeconds() + (i + 1));
            return new TechnicalArea({ name: area, project: data.payload.id, created });
          })
          .map((area) => new taActions.Created(area));
        return [...technicalAreasActions, phaseAction];
      }),
      catchError((err) => throwError(err))
    )
  );

  redirectAfterCreation$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectActions.ProjectActionTypes.CREATED_SUCCESS),
        tap((action: projectActions.CreatedSuccess) => {
          this.router.navigate(['projects', action.payload.id, 'settings', 'general']);
        })
      ),
    { dispatch: false }
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.DELETED),
      map((action: projectActions.Deleted) => action),
      switchMap((data) =>
        this.projectService.delete(data.projectId).pipe(
          map(
            (deletedProjectId) =>
              new projectActions.DeletedSuccessRemoveProjectData(deletedProjectId)
          ),
          catchError(() => of(new projectActions.DeletedFail()))
        )
      )
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.UPDATED),
      map((action: projectActions.Updated) => action),
      switchMap((data) =>
        this.projectService.update(data.payload).pipe(
          map(() => new projectActions.UpdatedSuccess()),
          catchError(() => of(new projectActions.UpdatedFail()))
        )
      )
    )
  );

  updateCopies$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectActions.ProjectActionTypes.UPDATED),
        map((action: projectActions.Updated) => action),
        filter((data) => data.payload.visible === false),
        mergeMap((data) =>
          of(data).pipe(
            withLatestFrom(this.store.select(fromStore.selectCopiesByParentId(data.payload.id)))
          )
        ),
        switchMap(([data, projects]) =>
          this.projectService.removeProjectCopies(data.payload.id, projects)
        )
      ),
    { dispatch: false }
  );

  userAdded$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.USER_ADDED),
      map((action: projectActions.UserAdded) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      map(([action, project]) => this.getUpdateActionsForRoles(action, project))
    )
  );

  sendMailToInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.USER_ADDED_SEND_MAIL),
      map((action: projectActions.UserAddedSendMail) => ({
        action,
        toastr: this.toastService.info('Invitation e-mail is being sent', 'Invitation', {
          duration: 0
        })
      })),
      switchMap(({ action, toastr }) =>
        this.projectService.sendMailInvitation(action.projectId, action.email, action.role).pipe(
          map((res) => {
            toastr.close();
            this.toastService.success('Email status: ' + res, 'Invitation');
            return new projectActions.UserAddedSendMailSuccess();
          }),
          catchError(() => {
            toastr.close();
            this.toastService.danger('Email send failed', 'Invitation');
            return of(new projectActions.UserAddedSendMailFail());
          })
        )
      )
    )
  );

  userRemoved$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.USER_REMOVED),
      map((action: projectActions.UserRemoved) => action),
      withLatestFrom(this.store.select(fromStore.selectProject)),
      map(([action, project]) => {
        const roleCollection = project[action.role] || [];
        const filteredCollection = roleCollection.filter((email) => email !== action.email);
        const filteredListViewers = project.listViewers.filter((email) => email !== action.email);
        const payload = {
          id: action.projectId,
          listViewers: filteredListViewers
        };
        payload[action.role] = filteredCollection;
        return new projectActions.Updated(payload);
      })
    )
  );

  removeProjectData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.DELETED_SUCCESS_REMOVE_PROJECT_DATA),
      map((action: projectActions.DeletedSuccessRemoveProjectData) => action),
      switchMap((data) =>
        zip(
          this.phaseService.removeProjectPhases(data.projectId),
          this.taskService.removeProjectTasks(data.projectId),
          this.roleService.removeProjectRoles(data.projectId),
          this.technicalAreaService.removeProjectTechnicalAreas(data.projectId),
          this.currencyService.removeProjectCurrencies(data.projectId)
        ).pipe(
          map(() => new projectActions.RemoveProjectDataSuccess()),
          catchError(() => of(new projectActions.RemoveProjectDataFail()))
        )
      )
    )
  );

  copy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.COPY),
      map((action: projectActions.Copy) => action),
      map((action) => ({
        toastr: this.toastService.info('Project is being copied', 'Project copy', { duration: 0 }),
        action
      })),
      mergeMap(({ action, toastr }) =>
        combineLatest([this.projectService.copy(action.projectId), of(toastr)])
      ),
      map(([, toastr]) => {
        toastr.close();
        this.toastService.success('Project has been copied.', 'Project copied');
        return new projectActions.CopySuccess();
      }),
      catchError(() => of(new projectActions.CopyFail()))
    )
  );

  clearAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.ProjectActionTypes.CLEAR_ALL),
      switchMap(() => [
        new currencyActions.Clear(),
        new phaseActions.Clear(),
        new taskActions.Clear(),
        new technicalAreaActions.Clear(),
        new roleActions.Clear(),
        new projectActions.ClearCurrentProject(),
        new estimateActions.Clear(),
        new fteActions.Clear(),
        new psrcActions.Clear(),
        new generalAssumptionActions.Clear(),
        new leaderChecklistActions.Clear(),
        new userActions.Clear(),
        new fromTaskFilesActions.Clear(),
        new taskCommentsActions.Clear(),
        new riskActions.Clear()
      ])
    )
  );

  private getUpdateActionsForRoles(action, project): projectActions.Updated {
    const possibleFields = ['owners', 'contributors', 'viewers'];
    const id = action.projectId;
    const field = action.role;
    const user = action.email;
    const allUsers: Set<string> = new Set();
    const payload: { id: string; listViewers: string[] } = { id, listViewers: [] };

    const currentRoles: Set<string> = new Set(project[field] || []);
    currentRoles.add(user);
    payload[field] = [...currentRoles];

    currentRoles.forEach((name) => allUsers.add(name));

    possibleFields
      .filter((possibleField) => possibleField !== field)
      .forEach((otherField) => {
        const roleCollection = project[otherField] || [];
        payload[otherField] = roleCollection.filter((roleUser) => roleUser !== user);
        roleCollection.forEach((name) => allUsers.add(name));
      });

    payload.listViewers = [...allUsers];

    return new projectActions.Updated(payload);
  }
}
