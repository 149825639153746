import { Weights } from '@pageProjects/models/weights';
import { addMonths, startOfMonth } from 'date-fns';

export class Project {
  constructor(obj?: Partial<Project>) {
    this.id = (obj && obj.id) || null;
    this.parentId = (obj && obj.parentId) || null;
    this.name = (obj && obj.name) || null;
    this.chargability = (obj && obj.chargability) || null;
    this.certainty = (obj && obj.certainty) || null;
    this.variantsEnabled = (obj && obj.variantsEnabled) || null;
    this.created = (obj && obj.created) || null;
    this.sharepointLink = (obj && obj.sharepointLink) || null;
    this.client = (obj && obj.client) || null;
    this.currency = (obj && obj.currency) || null;
    this.rateCurrency = (obj && obj.rateCurrency) || null;
    this.discoveryRateCurrency = (obj && obj.discoveryRateCurrency) || null;
    this.sprint0Length = (obj && obj.sprint0Length) || null;
    this.meetingOverhead = (obj && obj.meetingOverhead) || null;
    this.authorId = (obj && obj.authorId) || null;
    this.visible = (obj && obj.visible) || null;
    this.owners = (obj && obj.owners) || [];
    this.contributors = (obj && obj.contributors) || [];
    this.viewers = (obj && obj.viewers) || [];
    this.listViewers = (obj && obj.listViewers) || [];
    this.isCopying = (obj && obj.isCopying) || false;
    this.monthlyOverheadCost = (obj && obj.monthlyOverheadCost) || 0;
    this.startDate = (obj && obj.startDate) || startOfMonth(addMonths(this.created, 1));
    this.discoveryEnabled = (obj && obj.discoveryEnabled) || null;
  }

  id?: string;
  parentId?: string;
  certainty?: number;
  name: string;
  weights?: Weights = { optimistic: 1, realistic: 3, pessimistic: 2 };
  chargability?: number;
  variantsEnabled?: boolean;
  created?: Date;
  sharepointLink?: string;
  client?: string;
  currency?: string;
  rateCurrency?: string;
  discoveryRateCurrency?: string;
  sprint0Length?: number;
  monthlyOverheadCost?: number;
  meetingOverhead?: number;
  authorId?: string;
  visible?: boolean;
  owners: string[];
  contributors: string[];
  viewers: string[];
  listViewers: string[];
  isCopying?: boolean;
  startDate?: Date;
  discoveryEnabled?: boolean;
}

export type ProjectWithParent = {
  project: Project | null;
  parent: Project | null;
};

export type ProjectWithChildren = {
  project: Project | null;
  children: Project[] | null;
};
