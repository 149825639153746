import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference,
  QueryFn,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { TasksCollectionFieldEnum } from '@pageProjects/models/collection';
import { Collection } from '@shared/models/collection';
import { Task } from '@pageProjects/models/task';
import { EMPTY, from, iif, Observable, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import firebase from 'firebase/compat';
import { ProjectUtils } from '@pageProjects/tools/utils';
import WhereFilterOp = firebase.firestore.WhereFilterOp;

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  constructor(private readonly afs: AngularFirestore) {}

  add(task: Partial<Task>): Observable<{ id: string }> {
    return from(
      this.getCollection(task.project)
        .add({ ...task } as Task)
        .catch((e) => e)
        .then((docRef: DocumentReference<Task>) => ({ id: docRef.id }))
        .catch((e) => e)
    );
  }

  updateBatch(projectId: string, tasks: Partial<Task>[]): Observable<boolean> {
    if (tasks.length === 0) {
      return of(false);
    }

    const updateInBatches = async (batches: Partial<Task>[][]): Promise<void> => {
      for await (const tasksBatch of batches) {
        const batch = this.afs.firestore.batch();
        tasksBatch.forEach((task) => {
          const ref = this.getCollection(projectId).doc(task.id).ref;
          batch.update(ref, task);
        });

        await batch.commit();
      }
    };

    return from(
      updateInBatches(ProjectUtils.chunkArray(tasks, 500))
        .then(() => true)
        .catch((e) => e)
    );
  }

  update(task: Partial<Task>): Observable<string> {
    const updatedTaskData = { ...task };
    delete updatedTaskData.id;
    return from(
      this.getCollection(task.project)
        .doc<Task>(`${task.id}`)
        .update({
          ...updatedTaskData
        })
        .catch((e) => e)
        .then(() => 'task updated')
    );
  }

  delete(projectId: string, taskId: string): Observable<string> {
    return from(
      this.getCollection(projectId)
        .doc(taskId)
        .delete()
        .catch((e) => e)
        .then(() => taskId)
    );
  }

  getAll(projectId: string): Observable<DocumentChangeAction<Task>[]> {
    return this.getCollectionFilterByProject(projectId).stateChanges();
  }

  removePhaseAssociation(
    projectId: string,
    phaseId: string
  ): Observable<any[] | Observable<never>> {
    // use server-side transactions?? todo think about it
    return this.getCollectionFilterByPhase(projectId, phaseId)
      .get()
      .pipe(
        switchMap((tasks) =>
          iif(() => tasks.empty, of(EMPTY), this.removePhaseAssociationInTasks(projectId, tasks))
        )
      );
  }

  removeProjectTasks(projectId: string): Observable<any[] | Observable<never>> {
    return this.getCollectionFilterByProject(projectId)
      .get()
      .pipe(switchMap((tasks) => iif(() => tasks.empty, of(EMPTY), this.removeTasks(tasks))));
  }

  private getCollection(
    projectId: string,
    queryWhere?: [string, WhereFilterOp, string]
  ): AngularFirestoreCollection<Task> {
    const queryFn: QueryFn = (ref) => {
      let query = ref.orderBy('order', 'asc');
      if (queryWhere) {
        query = query.where(...queryWhere);
      }
      return query;
    };

    return this.afs
      .collection(Collection.PROJECTS)
      .doc(projectId)
      .collection<Task>(Collection.TASKS, queryFn);
  }

  private getCollectionFilterByProject(projectId: string): AngularFirestoreCollection<Task> {
    return this.getCollection(projectId);
  }

  private getCollectionFilterByPhase(
    projectId: string,
    phaseId: string
  ): AngularFirestoreCollection<Task> {
    return this.getCollectionFilterBy(projectId, phaseId, TasksCollectionFieldEnum.PHASE);
  }

  private getCollectionFilterBy(
    projectId: string,
    filedId: string,
    filedName: TasksCollectionFieldEnum
  ): AngularFirestoreCollection<Task> {
    return this.getCollection(projectId, [filedName, '==', filedId]);
  }

  private removePhaseAssociationInTasks(
    project: string,
    tasks: QuerySnapshot<Task>
  ): Observable<string[]> {
    const tasksUpdateRequests: Observable<string>[] = [];
    tasks.docs.forEach((task) => {
      tasksUpdateRequests.push(this.update({ id: task.id, phase: null, project }));
    });

    return zip(...tasksUpdateRequests);
  }

  private removeTasks(tasks: QuerySnapshot<Task>): Observable<string[]> {
    const taskDeleteRequests: Observable<string>[] = [];
    tasks.docs.forEach((task) => {
      taskDeleteRequests.push(this.delete(task.data().project, task.id));
    });
    return zip(...taskDeleteRequests);
  }
}
