import { Action } from '@ngrx/store';
import { Log } from '@pageProjects/models/log';

export enum LogActionTypes {
  LOAD = '[LOG] LOADED LOGS',
  CREATED = '[LOG] CREATED LOGS',
  ADDED = '[LOG API] ADDED',
  API_ERROR = '[LOG API] ERROR',
  CLEAR = '[LOG API] CLEAR',
  CREATED_SUCCESS = '[LOG] CREATED SUCCESS',
  CREATED_FAIL = '[LOG] CREATED FAIL'
}

export class Load implements Action {
  readonly type = LogActionTypes.LOAD;

  constructor() {}
}

export class Added implements Action {
  readonly type = LogActionTypes.ADDED;

  constructor(public payload: Log[]) {}
}

export class Clear implements Action {
  readonly type = LogActionTypes.CLEAR;
}

export class Created implements Action {
  readonly type = LogActionTypes.CREATED;

  constructor(public log: Log) {}
}

export class CreatedSuccess implements Action {
  readonly type = LogActionTypes.CREATED_SUCCESS;
}

export class CreatedFail implements Action {
  readonly type = LogActionTypes.CREATED_FAIL;
}

export class ApiError implements Action {
  readonly type = LogActionTypes.API_ERROR;
}

export type LogActions = Load | Created | CreatedSuccess | CreatedFail | Added | ApiError | Clear;
