import { DocumentChangeAction } from '@angular/fire/compat/firestore';
import { MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';

const sort = (o: any): any => {
  if (null === o || undefined === o || typeof o !== 'object') {
    return o;
  }
  if (Array.isArray(o)) {
    return o.map((item) => sort(item));
  }
  const keys = Object.keys(o).sort();
  const result = {};
  keys.forEach((k) => (result[k] = sort(o[k])));
  return result;
};

/*
Reason for this distincts is that Firebase throws 2 update event on updating,
first one contains flag that update is pending (so application can show loading indicator).
But we are not using this flag and double event causing that view is repainted 2 times, it can cause side effects especcially during e2e tests.
distinctUntilChangedJson is simple one, to compare action by action.
distinctUntilChangedForActions is more complex, because it takes the array of actions and checks only UPDATED events. So it should be faster and safer
(in theory if there will be 2 the same added events one by one, it means that user logout and relogin, because added event is fired only on start).
distinctUntilChangedJson can interpret incorrectly 2 the same added events and ignore second one.
*/

export const distinctUntilChangedJson = <T>(): MonoTypeOperatorFunction<T> =>
  distinctUntilChanged((a, b) => JSON.stringify(sort(a)) === JSON.stringify(sort(b)));
export const distinctUntilChangedForActions = (): MonoTypeOperatorFunction<Action[]> =>
  distinctUntilChanged((a, b) => {
    if (
      !a ||
      !b ||
      a.length !== b.length ||
      a.length === 0 ||
      !a[0].type?.endsWith('ADDED') ||
      !b[0].type?.endsWith('ADDED')
    ) {
      return false;
    }
    return JSON.stringify(sort(a)) === JSON.stringify(sort(b));
  });

export const handleDocumentChanges =
  (actionTypePrefix: string) =>
  <T>(source: Observable<DocumentChangeAction<T>[]>): Observable<Action> =>
    source.pipe(
      map((result) => {
        const actionGroups: DocumentChangeAction<T>[][] = [];
        for (const ca of result) {
          if (
            actionGroups.length > 0 &&
            actionGroups[actionGroups.length - 1][0].type === ca.type
          ) {
            actionGroups[actionGroups.length - 1].push(ca);
          } else {
            actionGroups.push([ca]);
          }
        }
        return actionGroups.map(
          (group) =>
            ({
              type: `${actionTypePrefix} ${group[0].type}`.toUpperCase(),
              payload: group.map((action) => ({
                ...action.payload.doc.data(),
                id: action.payload.doc.id
              }))
            } as Action)
        );
      }),
      distinctUntilChangedForActions(),
      switchMap((a) => a),
      catchError(() =>
        of({
          type: `${actionTypePrefix} ERROR`
        } as Action)
      )
    );
